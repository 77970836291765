import React from 'react';

function MyKfhLogo({ ...rest }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 14" width="50" height="14" {...rest}>
      <path
        fill="currentColor"
        d="M6.674 10.65 10.5 1.015V8.77c0 .984-.431 1.504-1.035 1.504v.246h4.344v-.246c-.619 0-1.237-.52-1.237-1.504V1.855c0-.882.489-1.272 1.107-1.272V.337H10.5L7.624 7.67 4.157.337H.532v.246c.36.028 1.079.028 1.626.998v5.317c-.009.375-.135 3.375-2.158 3.375v.246h4.358v-.246c-1.857 0-1.951-2.987-1.956-3.372V1.97l4.028 8.68h.244Zm9.796 3.124c.273-.217.705-.622 1.079-1.606l2.2-5.829c.921-2.227 1.583-2.256 1.885-2.256v-.246h-2.82v.246c1.67-.015.72 2.198.72 2.198l-.763 1.953-1.524-3.269s-.374-.882.374-.882v-.246h-3.395v.246c.216 0 .676 0 .906.564 0 0 .722 1.554 1.48 3.223l.207.456c.379.84.752 1.673 1.032 2.323l-.547 1.49c-.23.608-.575.709-.633.535a.91.91 0 0 0-.489-.593.998.998 0 0 0-1.323.507 1.02 1.02 0 0 0 .503 1.33c.346.16.849.073 1.108-.144Zm8.5-3.255V7.53l1.089-1.335 2.865 4.325h2.646l-3.928-5.81L31.524 0h-2.65l-3.765 4.638h-.138V0h-2.212v10.52h2.212Zm9.92 0V6.174h4.255V4.34H34.89V1.834h4.715V0H32.68v10.52h2.211Zm8.413 0V6.174h4.49v4.345H50V0h-2.207v4.34h-4.49V0h-2.211v10.52h2.211Z"
      />
    </svg>
  );
}

export default MyKfhLogo;
