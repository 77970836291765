import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Email: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      d="M8.88889 7.11111C7.96889 7.11111 7.11566 7.42695 6.4401 7.95139L14.8446 15.1241C15.5157 15.6975 16.4843 15.6975 17.1554 15.1241L25.5599 7.95139C24.8843 7.42695 24.0311 7.11111 23.1111 7.11111H8.88889ZM5.31076 9.32466C5.03965 9.86243 4.88889 10.4711 4.88889 11.1111V20.8889C4.88889 23.0933 6.68444 24.8889 8.88889 24.8889H23.1111C25.3156 24.8889 27.1111 23.0933 27.1111 20.8889V11.1111C27.1111 10.4711 26.9603 9.86243 26.6892 9.32466L18.3064 16.4757C17.6398 17.049 16.8178 17.3333 16 17.3333C15.1822 17.3333 14.3602 17.049 13.6936 16.4757L5.31076 9.32466Z"
      fill="currentColor"
    />
  </Icon>
);
