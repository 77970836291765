import React, { FC, forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../Button';
import { BaseFormProps } from './Base';
import { FormField, FormFieldProps } from './FormField';

interface Options {
  name: string;
  value: string;
}

export interface ButtonSelectProps extends BaseFormProps, FormFieldProps {
  options: Options[];
  onChange: (...event: any[]) => void;
  value?: string | number;
}

const StyledSelection = styled.ul`
  ${({ theme: { space } }) => css`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: ${space.sm};
    margin-top: ${space.sm};
    justify-content: flex-start;
    margin: 0;
    li {
      margin: 0;
    }
  `}
`;

export const ButtonSelect: FC<ButtonSelectProps> = forwardRef(
  ({ label, id, valid, invalid, options, required, onChange, value, hideLabel, ...rest }, ref) => {
    const [inputValue, setInputValue] = useState<string | number>(value || '');

    // update field value on change
    const handleOnChange = (newValue: string | number) => {
      setInputValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    };
    return (
      <FormField label={label} htmlFor={id} required={required} hideLabel={hideLabel} {...rest}>
        <StyledSelection role="radiogroup">
          {options?.length &&
            options?.map((item) => (
              <li key={item.value}>
                <Button
                  type="button"
                  variant="pill"
                  onClick={() => handleOnChange(item.value)}
                  selected={inputValue === item.value}
                  role="radio"
                  aria-checked={inputValue === item.value}
                >
                  {item.name}
                </Button>
              </li>
            ))}
        </StyledSelection>
      </FormField>
    );
  }
);
