import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Phone: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g id="Buy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-5">
        <rect id="Icon-background" fill="#FFFFFF" x="0" y="0" width="32" height="32" />
        <path
          d="M27.4007143,21.955 L26.3292857,26.5978571 C26.2172171,27.083927 25.7845361,27.4285714 25.2857143,27.4285714 C13.8571429,27.4285714 4.57142857,18.1635714 4.57142857,6.71428571 C4.58888287,6.22166022 4.9259768,5.7981914 5.40214286,5.67071429 L10.045,4.59928571 C10.1241978,4.58253805 10.2047847,4.57321196 10.2857143,4.57142857 C10.7076592,4.59272592 11.0848088,4.8413329 11.2707143,5.22071429 L13.4135714,10.2207143 C13.4660558,10.3555256 13.4952802,10.4982666 13.5,10.6428571 C13.4825573,10.96003 13.3415233,11.2577398 13.1071429,11.4721429 L10.4007143,13.6864286 C12.0405821,17.1620848 14.8379152,19.9594179 18.3135714,21.5992857 L20.5278571,18.8928571 C20.7422602,18.6584767 21.03997,18.5174427 21.3571429,18.5 C21.5017415,18.5046507 21.644494,18.5338776 21.7792857,18.5864286 L26.7792857,20.7292857 C27.1588769,20.9149527 27.4075759,21.292242 27.4285714,21.7142857 C27.427144,21.7952393 27.417814,21.8758601 27.4007143,21.955 Z"
          id="Path"
          fill="currentColor"
        />
      </g>
    </g>
  </Icon>
);
