import { GatsbyBrowser, Script, ScriptStrategy } from 'gatsby';
import React from 'react';
import { BaseLayout } from './src/layouts/Base';

interface SharedWindow extends Window {
  provideGtmPlugin: any;
  dataLayer: any;
  experiments: any;
}

declare const window: SharedWindow;
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => (
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  <BaseLayout {...props}>
    {element}

    {/* // GTM */}
    <Script
      strategy={ScriptStrategy.postHydrate}
      forward={[`dataLayer.push`, `fbq`]}
      dangerouslySetInnerHTML={{
        __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-539ZP3');
          `,
      }}
    />
    {/* // IpMeta script tag added to handle bot traffic */}
    <Script
      strategy={ScriptStrategy.postHydrate}
      src="https://ipmeta.io/plugin.js"
      onLoad={() => {
        const data = window.provideGtmPlugin({
          gtmEventKey: 'ipmeta_loaded',
          apiKey: 'd3c12a5c478369e0ede8bda5ee9decb41ebf16fcda6f595bcba5ef8d90a0e60c',
        });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
      }}
    />

    {/* // OneTrust Cookies Consent Notice */}
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="text/javascript"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      charSet="UTF-8"
      data-domain-script="1d7cceeb-316e-4d61-aaa0-7859b17af404"
    />
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="text/javascript"
      dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
    />
    <Script
      strategy={ScriptStrategy.postHydrate}
      dangerouslySetInnerHTML={{
        __html: `
        (function(i,n,f,t,y,x,z) {
          y=i._its=function(){return y.queue.push(arguments)};y.version='2.0';y.queue=[];z=n.createElement(f);z.async=!0;z.src=t;x=n.getElementsByTagName(f)[0];x.parentNode.insertBefore(z,x)})(window, document,'script','https://script.infinity-tracking.com/infinitytrack.js?i=3023');
        window._its('init', '3023');
        window._its('track');
        `,
      }}
    />
    <Script
      strategy={ScriptStrategy.idle}
      type="text/javascript"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
    />
  </BaseLayout>
);
