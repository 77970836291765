export const getFormFields = (formData: object): object => {
  const formFields = [];

  for (const key of Object.keys(formData)) {
    const keyName = key
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    formFields.push({ name: keyName, value: formData[key] });
  }
  return formFields;
};
