import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ArrowUp: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="m5.608 18.37-1.22-1.238a1.348 1.348 0 0 1 0-1.892l10.68-10.847a1.3 1.3 0 0 1 1.864 0l10.68 10.842a1.348 1.348 0 0 1 0 1.891l-1.22 1.239a1.307 1.307 0 0 1-1.885-.022l-6.305-6.718V27.66c0 .742-.589 1.339-1.32 1.339h-1.759c-.73 0-1.32-.597-1.32-1.34V11.626l-6.31 6.723a1.298 1.298 0 0 1-1.885.023Z"
      />
    </g>
  </Icon>
);
