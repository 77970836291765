import React, { FC } from 'react';
import styled, { css } from 'styled-components';

export interface OverlayProps {}

export const StyledOverlay = styled.div`
  ${({ theme: { layers } }) => css`
    background: rgba(17, 17, 17, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: ${layers.overlay};
  `};
`;

export const Overlay: FC<React.PropsWithChildren<OverlayProps>> = ({ ...rest }) => <StyledOverlay {...rest} />;
