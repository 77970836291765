import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Dollar: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        d="m20.256 14.676-6.328-1.852a1.728 1.728 0 0 1 .486-3.387h3.885c.714 0 1.418.217 2.004.616a.913.913 0 0 0 1.142-.117l2.04-1.993c.415-.404.357-1.078-.106-1.435A8.285 8.285 0 0 0 18.31 4.75V1.937A.94.94 0 0 0 17.373 1h-1.875a.94.94 0 0 0-.938.938V4.75h-.146c-3.733 0-6.733 3.205-6.387 7.008.246 2.701 2.309 4.898 4.91 5.66l6.006 1.758a1.728 1.728 0 0 1-.486 3.387h-3.885a3.577 3.577 0 0 1-2.004-.616.913.913 0 0 0-1.142.117l-2.04 1.993c-.415.404-.357 1.078.106 1.435a8.285 8.285 0 0 0 5.068 1.758v2.813a.94.94 0 0 0 .938.937h1.875a.94.94 0 0 0 .937-.938v-2.824c2.73-.052 5.291-1.675 6.194-4.26 1.26-3.609-.856-7.312-4.248-8.302Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </Icon>
);
