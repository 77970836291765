import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Facebook: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M28.571 0H3.43A3.429 3.429 0 0 0 0 3.429V28.57A3.429 3.429 0 0 0 3.429 32h9.803V21.12h-4.5V16h4.5v-3.903c0-4.44 2.643-6.891 6.69-6.891 1.94 0 3.967.345 3.967.345V9.91h-2.234c-2.2 0-2.887 1.365-2.887 2.766V16h4.913l-.786 5.12h-4.127V32h9.803A3.429 3.429 0 0 0 32 28.571V3.43A3.429 3.429 0 0 0 28.571 0Z"
      />
    </g>
  </Icon>
);
