import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const CaretRight: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M12 24.023V7.94c0-1.114 1.346-1.671 2.134-.884l8.041 8.041a1.25 1.25 0 0 1 0 1.768l-8.041 8.042c-.788.787-2.134.23-2.134-.884Z"
      />
    </g>
  </Icon>
);
