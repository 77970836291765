import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const CaretLeft: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M21.541 8.253v16.082c0 1.114-1.346 1.672-2.134.884l-8.04-8.041a1.25 1.25 0 0 1 0-1.768l8.04-8.041c.788-.788 2.134-.23 2.134.884Z"
      />
    </g>
  </Icon>
);
