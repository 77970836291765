import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Avatar: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M16 0C7.161 0 0 7.161 0 16s7.161 16 16 16 16-7.161 16-16S24.839 0 16 0Zm0 6.194a5.678 5.678 0 1 1 0 11.355 5.678 5.678 0 0 1 0-11.355Zm0 22.193c-3.787 0-7.18-1.716-9.452-4.4 1.213-2.284 3.587-3.858 6.355-3.858.155 0 .31.026.458.071.839.271 1.716.445 2.639.445.923 0 1.806-.174 2.639-.445a1.58 1.58 0 0 1 .458-.071c2.768 0 5.142 1.574 6.355 3.858-2.271 2.684-5.665 4.4-9.452 4.4Z"
      />
    </g>
  </Icon>
);
