import React, { FC, useContext, useRef } from 'react';
import { graphql, navigate } from 'gatsby';
import { Kfh_UmbracoButtonLinkValuationType } from '../../../types/graphqlTypes';
import { Button } from '../../Button';
import { RequestAValuationBlade } from '../../properties/RequestAValuationBlade';
import { UiStateContext } from '../../../context/UiStateContext';
import { IconLink } from '../../IconLink';
import { ExperimentsContext } from '../../../context/ExperimentsContext';
import { CareersContact } from '../../forms/CareersContact';

interface LinkValuationProps extends Kfh_UmbracoButtonLinkValuationType {
  arrowLink?: boolean;
}

export const LinkValuation: FC<LinkValuationProps> = ({ arrowLink, label, valuationType, ...rest }) => {
  const { setActiveBlade, setActiveBladeTriggerEl } = useContext(UiStateContext);

  const requestAValuationTriggerEl = useRef() as React.MutableRefObject<HTMLButtonElement>;

  const handleOpenValuation = () => {
    setActiveBlade('propertyRequestAValuation');
    setActiveBladeTriggerEl(requestAValuationTriggerEl);
  };

  return (
    <>
      {arrowLink ? (
        <IconLink
          onClick={handleOpenValuation}
          ref={requestAValuationTriggerEl}
          {...rest}
          label={label || 'Request a valuation'}
          arrowRight
          eventClickBladeId="propertyRequestAValuation"
        />
      ) : (
        <Button
          onClick={handleOpenValuation}
          ref={requestAValuationTriggerEl}
          {...rest}
          label={label || 'Request a valuation'}
          arrowRight
          eventClickBladeId="propertyRequestAValuation"
        />
      )}
      <RequestAValuationBlade enquiryType={valuationType} />
      <CareersContact />
    </>
  );
};

export const LinkValuationQuery = graphql`
  fragment LinkValuation on KFH_UmbracoButtonLinkValuationType {
    label
    valuationType
  }
`;
