import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Alert: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-2 0h32v32H-2z" />
      <path
        fill="currentColor"
        d="M14 32a4 4 0 0 0 3.998-4h-7.996A4 4 0 0 0 14 32Zm13.462-9.357c-1.208-1.297-3.467-3.25-3.467-9.643 0-4.856-3.405-8.744-7.996-9.697V2A2 2 0 1 0 12 2v1.303C7.41 4.256 4.004 8.143 4.004 13c0 6.394-2.26 8.346-3.467 9.643A1.953 1.953 0 0 0 0 24c.007 1.025.811 2 2.006 2h23.988c1.195 0 2-.975 2.006-2a1.952 1.952 0 0 0-.538-1.357Z"
      />
    </g>
  </Icon>
);
