import React, { useContext, useRef, FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMykfh_Reset_PasswordMutation } from '../../types/graphqlHelpers';
import Blade, { BladeBody, BladeContainer, BladeFooter } from '../Blade';
import { Input } from '../forms/Input';
import { Spacer } from '../Spacer';
import { Typography } from '../Typography';
import { UiStateContext } from '../../context/UiStateContext';

interface ResetPasswordBladeProps {
  token: string;
}

interface FormValues {
  password: string;
  confirmPassword: string;
}

const ToastMessage = () => (
  <div>
    <Typography variant="titleSmall">Password reset</Typography>
    <Typography variant="bodyLarge">Please use your new password to log in.</Typography>
  </div>
);

export const ResetPasswordBlade: FC<ResetPasswordBladeProps> = ({ token }) => {
  const [mykfhResetPasswordMutation, { data: response, loading, error }] = useMykfh_Reset_PasswordMutation();
  const { setActiveBlade } = useContext(UiStateContext);

  const formMethods = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const { register, handleSubmit, formState, watch } = formMethods;
  const { errors, dirtyFields, touchedFields } = formState;

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { password, confirmPassword } = data;

    mykfhResetPasswordMutation({
      variables: {
        myKfhResetPassword: {
          resetPasswordToken: token.trim(),
          password,
          confirmPassword,
        },
      },
      update: () => {
        setActiveBlade(null);
        toast.success(<ToastMessage />);
      },
    });
  };

  return (
    <Blade id="myKfhResetPassword" width="narrow" customBlade>
      <BladeContainer as="form" onSubmit={handleSubmit(onSubmit)} method="post" noValidate>
        <BladeBody>
          <Spacer>
            <Typography variant="displaySmall">Reset password</Typography>
            <Input
              id="password"
              type="password"
              label="Password"
              {...register('password', {
                required: 'You must specify a password',
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters',
                },
              })}
              invalid={!!errors.password}
              errorMessage={errors.password?.message}
              valid={touchedFields.password && dirtyFields.password && !errors.password}
              helpText="Your password must be at least 8 characters long, include at least one capital letter, one special character and one number, and cannot include sequential numbers (e.g. '123456789'). "
              required
            />
            <Input
              id="confirmPassword"
              type="password"
              label="Confirm Password"
              {...register('confirmPassword', {
                required: {
                  value: true,
                  message: 'Please confirm new password',
                },

                validate: (value) => value === password.current || 'The passwords do not match',
              })}
              invalid={!!errors.confirmPassword}
              errorMessage={errors.confirmPassword?.message}
              valid={touchedFields.confirmPassword && dirtyFields.confirmPassword && !errors.confirmPassword}
              required
            />
          </Spacer>
        </BladeBody>

        <BladeFooter
          primaryButton={{
            label: 'Submit request',
            disableClickTracking: true,
            onClick: handleSubmit(onSubmit),
          }}
        />
      </BladeContainer>
    </Blade>
  );
};
