import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Sort: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M15.312 22h-3V3a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v19h-3c-.887 0-1.335 1.078-.705 1.707l5 6a1 1 0 0 0 1.413 0l5-6c.627-.628.181-1.707-.708-1.707ZM28.018 8.293l-5-6a1 1 0 0 0-1.414 0l-5 6c-.626.628-.18 1.707.708 1.707h3v19a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V10h3c.887 0 1.335-1.078.706-1.707Z"
      />
    </g>
  </Icon>
);
