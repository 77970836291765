const layers = {
  subZero: -1,
  base: 0,
  heroBackgroundImage: 1,
  searchIcon: 1,
  searchCaret: 1,
  searchLabel: 1,
  heroGradient: 2,
  heroContainer: 3,
  heroComponent: 4,
  imageCardOverlay: 5,
  imageCardBody: 6,
  videoTourGalleryOverlay: 7,
  galleryOverlay: 8,
  propertyCardSaveButton: 9,
  branchSearch: 10,
  toggleButton: 11,
  searchLocation: 14,
  search: 13,
  overlay: 14,
  headerDropdown: 15,
  headerMyKFHDropdown: 16,
  stickyBar: 17,
  bladeBackgroundOverlay: 18,
  blade: 19,
  bladeHeader: 20,
  bladeOverlay: 21,
  bladeFooter: 22,
  videoOverlay: 23,
};

export default layers;

export type ThemeLayers = typeof layers;
