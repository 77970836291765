import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Download: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M18 8.5V0H5.5C4.669 0 4 .669 4 1.5v29c0 .831.669 1.5 1.5 1.5h21c.831 0 1.5-.669 1.5-1.5V10h-8.5c-.825 0-1.5-.675-1.5-1.5Zm4.778 13.21-6.026 5.981a1.066 1.066 0 0 1-1.503 0L9.223 21.71c-.634-.63-.19-1.71.703-1.71H14v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5h4.074c.892 0 1.337 1.08.704 1.71Zm4.785-15.148L21.442.438A1.499 1.499 0 0 0 20.382 0H20v8h8v-.381c0-.394-.156-.775-.438-1.056Z"
      />
    </g>
  </Icon>
);
