import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Branch: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-2 0h32v32H-2z" />
      <path
        fill="#D8183A"
        d="M2 28a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2v24a2 2 0 0 1-2 2h-9l-2.147 3.507a1 1 0 0 1-1.706 0L11 28H2Z"
      />
      <path
        fill="#FFF"
        d="M18.347 8v3.389h3.558V8H23v8h-1.095v-3.7h-3.558V16h-1.096V8zM9.488 8l-3.355 3.516V8H5v8h1.133v-3.908L9.698 16h1.461l-3.95-4.265L10.856 8zm6.808 0v.876h-3.052v2.559h2.924v.9h-2.924V16h-1.129V8z"
      />
      <path fill="#3AA75B" d="M18.5 20H23v-1h-4.5z" />
      <path fill="#F3A72A" d="M5 20h4.5v-1H5z" />
      <path fill="#00A2E8" d="M14 20h4.5v-1H14z" />
      <path fill="#674C99" d="M9.5 20H14v-1H9.5z" />
    </g>
  </Icon>
);
