import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const WhatsApp: FC<IconProps> = (props) => (
  <Icon {...props}>
    <defs>
      <path
        d="M0.597887466,13.8778708 C0.597012466,16.2385714 1.21651243,18.5435211 2.39426236,20.5749432 L2.39426236,20.5749432 L0.485012473,27.5159257 L7.61976207,25.6526273 C9.58588696,26.7197337 11.7987618,27.282469 14.0510117,27.2833401 L14.0510117,27.2833401 L14.0571367,27.2833401 C14.0571367,27.2833401 14.0562617,27.2833401 14.0571367,27.2833401 L14.0571367,27.2833401 C21.4745113,27.2833401 27.5120109,21.2744423 27.5155114,13.8891952 L27.5155114,13.8891952 C27.5163859,10.3098155 26.117261,6.94472825 23.5762612,4.41329061 L23.5762612,4.41329061 C21.0361363,1.88098186 17.6568865,0.486339172 14.0562617,0.484596957 L14.0562617,0.484596957 C6.63801212,0.484596957 0.601387466,6.49262359 0.597887466,13.8778708"
        id="path-slqiiujwp2-1"
      />
      <filter
        x="-22.2%"
        y="-22.2%"
        width="144.4%"
        height="144.4%"
        filterUnits="objectBoundingBox"
        id="filter-slqiiujwp2-3"
      >
        <feOffset dx={0} dy={0} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.16 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
      <linearGradient
        x1="49.9996658%"
        y1="99.9994241%"
        x2="49.9996658%"
        y2="0.000296477716%"
        id="linearGradient-slqiiujwp2-4"
      >
        <stop stopColor="#1FAF38" offset="0%" />
        <stop stopColor="#60D669" offset="100%" />
      </linearGradient>
      <path
        d="M0.597887466,13.8778708 C0.597012466,16.2385714 1.21651243,18.5435211 2.39426236,20.5749432 L2.39426236,20.5749432 L0.485012473,27.5159257 L7.61976207,25.6526273 C9.58588696,26.7197337 11.7987618,27.282469 14.0510117,27.2833401 L14.0510117,27.2833401 L14.0571367,27.2833401 C14.0571367,27.2833401 14.0562617,27.2833401 14.0571367,27.2833401 L14.0571367,27.2833401 C21.4745113,27.2833401 27.5120109,21.2744423 27.5155114,13.8891952 L27.5155114,13.8891952 C27.5163859,10.3098155 26.117261,6.94472825 23.5762612,4.41329061 L23.5762612,4.41329061 C21.0361363,1.88098186 17.6568865,0.486339172 14.0562617,0.484596957 L14.0562617,0.484596957 C6.63801212,0.484596957 0.601387466,6.49262359 0.597887466,13.8778708"
        id="path-slqiiujwp2-5"
      />
      <filter
        x="-25.9%"
        y="-18.5%"
        width="151.8%"
        height="151.8%"
        filterUnits="objectBoundingBox"
        id="filter-slqiiujwp2-6"
      >
        <feOffset dx={0} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.0666666667   0 0 0 0 0.0666666667   0 0 0 0 0.0666666667  0 0 0 0.24 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="M14.0588867,0.000261332172 C6.37463714,0.000261332172 0.121012493,6.22432256 0.117512493,13.8743863 L0.117512493,13.8743863 C0.115762493,16.3195844 0.758012457,18.7064182 1.97863739,20.8101422 L1.97863739,20.8101422 L0.000262499985,28.0002613 L7.39138708,26.0707588 C9.42751197,27.1761939 11.7200118,27.7589646 14.0536367,27.7607068 L14.0536367,27.7607068 L14.0588867,27.7607068 C21.7422613,27.7607068 27.9967609,21.5349034 28.0002625,13.8848396 L28.0002625,13.8848396 C28.0011359,10.1774072 26.552136,6.69123604 23.9201361,4.06833214 L23.9201361,4.06833214 C21.2881363,1.44629935 17.7881365,0.00113243941 14.0588867,0.000261332172 Z M4.51788724,20.4477616 L4.24226226,20.0122079 C3.08288733,18.1759139 2.46951236,16.0538966 2.47125863,13.8752574 L2.47125863,13.8752574 C2.47388736,7.51617459 7.67138707,2.34353981 14.0632617,2.34353981 L14.0632617,2.34353981 C17.1581365,2.34441091 20.0675114,3.5456678 22.2558862,5.72517811 L22.2558862,5.72517811 C24.4433861,7.90468842 25.647386,10.8028622 25.645638,13.8848396 L25.645638,13.8848396 C25.643886,20.2421803 20.4455113,25.4174284 14.0588867,25.4174284 L14.0588867,25.4174284 L14.0536367,25.4174284 C11.9746368,25.4156862 9.93501194,24.8599197 8.15613704,23.8093644 L8.15613704,23.8093644 L7.73351206,23.5593566 L3.34713731,24.7031204 L4.51788724,20.4477616 Z"
        id="path-slqiiujwp2-7"
      />
      <linearGradient x1="49.9999993%" y1="100%" x2="49.9999993%" y2="-0.00155959307%" id="linearGradient-slqiiujwp2-9">
        <stop stopColor="#F9F9F9" offset="0%" />
        <stop stopColor="#FFFFFF" offset="100%" />
      </linearGradient>
    </defs>
    <g id="KFH-Symbols" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Logo/WhatsApp">
        <rect id="Icon-background" x={0} y={0} width={32} height={32} />
        <g id="WhatsApp" transform="translate(1.999700, 1.999700)">
          <path
            d="M7.73324956,23.5590953 L8.15674954,23.8091031 C9.93562444,24.8596584 11.9743743,25.4154248 14.0542492,25.4162959 L14.0586242,25.4162959 C20.4452488,25.4162959 25.6436235,20.24279 25.646249,13.8845783 C25.6471235,10.8026009 24.4431236,7.9052982 22.2556237,5.72578789 C20.0672489,3.54540646 17.158749,2.34502069 14.0629992,2.34327847 C7.67112457,2.34327847 2.47362486,7.51591326 2.47099892,13.8749961 C2.47012486,16.0536353 3.08262483,18.1756526 4.24287476,20.0119466 L4.51849974,20.4483713 L3.34774981,24.7037302 L7.73324956,23.5590953 Z M0,28 L1.97837489,20.810752 C0.758624957,18.7061569 0.116374993,16.319323 0.117249993,13.874125 C0.120749993,6.22406123 6.37437464,0 14.0586242,0 C17.787874,0.00174221448 21.2887488,1.44603802 23.9207486,4.06894192 C26.5527485,6.69184581 28.0017484,10.178017 28,13.8854494 C27.9973734,21.5346421 21.7419988,27.7604455 14.0586242,27.7604455 C14.0586242,27.7604455 14.0594992,27.7604455 14.0586242,27.7604455 L14.0524992,27.7604455 C11.7197493,27.7587033 9.42724947,27.1768037 7.39112458,26.0713686 L0,28 Z"
            id="Fill-1"
            fill="#FFFFFF"
          />
          <mask id="mask-slqiiujwp2-2" fill="white">
            <use xlinkHref="#path-slqiiujwp2-1" />
          </mask>
          <g id="Clip-5" fill="black" fillOpacity={1}>
            <use filter="url(#filter-slqiiujwp2-3)" xlinkHref="#path-slqiiujwp2-1" />
          </g>
          <g id="Fill-4" mask="url(#mask-slqiiujwp2-2)">
            <use fill="black" fillOpacity={1} filter="url(#filter-slqiiujwp2-6)" xlinkHref="#path-slqiiujwp2-5" />
            <use fill="url(#linearGradient-slqiiujwp2-4)" fillRule="evenodd" xlinkHref="#path-slqiiujwp2-5" />
          </g>
          <mask id="mask-slqiiujwp2-8" fill="white">
            <use xlinkHref="#path-slqiiujwp2-7" />
          </mask>
          <g id="Clip-8" />
          <path
            d="M14.0588867,0.000261332172 C6.37463714,0.000261332172 0.121012493,6.22432256 0.117512493,13.8743863 L0.117512493,13.8743863 C0.115762493,16.3195844 0.758012457,18.7064182 1.97863739,20.8101422 L1.97863739,20.8101422 L0.000262499985,28.0002613 L7.39138708,26.0707588 C9.42751197,27.1761939 11.7200118,27.7589646 14.0536367,27.7607068 L14.0536367,27.7607068 L14.0588867,27.7607068 C21.7422613,27.7607068 27.9967609,21.5349034 28.0002625,13.8848396 L28.0002625,13.8848396 C28.0011359,10.1774072 26.552136,6.69123604 23.9201361,4.06833214 L23.9201361,4.06833214 C21.2881363,1.44629935 17.7881365,0.00113243941 14.0588867,0.000261332172 Z M4.51788724,20.4477616 L4.24226226,20.0122079 C3.08288733,18.1759139 2.46951236,16.0538966 2.47125863,13.8752574 L2.47125863,13.8752574 C2.47388736,7.51617459 7.67138707,2.34353981 14.0632617,2.34353981 L14.0632617,2.34353981 C17.1581365,2.34441091 20.0675114,3.5456678 22.2558862,5.72517811 L22.2558862,5.72517811 C24.4433861,7.90468842 25.647386,10.8028622 25.645638,13.8848396 L25.645638,13.8848396 C25.643886,20.2421803 20.4455113,25.4174284 14.0588867,25.4174284 L14.0588867,25.4174284 L14.0536367,25.4174284 C11.9746368,25.4156862 9.93501194,24.8599197 8.15613704,23.8093644 L8.15613704,23.8093644 L7.73351206,23.5593566 L3.34713731,24.7031204 L4.51788724,20.4477616 Z"
            id="Fill-7"
            fill="url(#linearGradient-slqiiujwp2-9)"
            mask="url(#mask-slqiiujwp2-8)"
          />
          <path
            d="M10.5744619,8.07377034 C10.3137119,7.49622624 10.0389619,7.48490184 9.79046195,7.47444856 C9.58746196,7.46573749 9.35471197,7.4665844 9.12283698,7.4665844 C8.890962,7.4665844 8.51296202,7.55371932 8.19446204,7.90042 C7.87508705,8.24712068 6.97471211,9.08599695 6.97471211,10.792496 C6.97471211,12.4989951 8.22333704,14.1480011 8.39746203,14.3788445 C8.57158702,14.6105591 10.8072119,18.2239119 14.3474617,19.614199 C17.2900865,20.7692872 17.8894615,20.5393149 18.5282115,20.4818219 C19.1669614,20.4234577 20.5888363,19.6429456 20.8793363,18.8328159 C21.1698363,18.0235572 21.1698363,17.3292848 21.0823363,17.1838098 C20.9957113,17.039206 20.7629613,16.9529664 20.4147113,16.7796161 C20.0664614,16.6062657 18.3540865,15.7665184 18.0347115,15.6515322 C17.7153365,15.535675 17.4825865,15.4781819 17.2507115,15.8248826 C17.0179615,16.1715832 16.3512116,16.9529664 16.1482116,17.1838098 C15.9443366,17.4155244 15.7413366,17.445142 15.3930866,17.2709206 C15.0448366,17.0975702 13.9222117,16.7317052 12.5913368,15.5504838 C11.5562118,14.6305945 10.8570869,13.4955418 10.6540869,13.1488411 C10.4502119,12.8021404 10.6322119,12.6139813 10.8063369,12.441502 C10.9629619,12.2855738 11.1554619,12.0364372 11.3295869,11.8334692 C11.5028368,11.6313723 11.5614618,11.4867685 11.6769618,11.255054 C11.7933368,11.0242106 11.7355868,10.8212426 11.6480868,10.6478922 C11.5614618,10.4745419 10.8842119,8.75933174 10.5744619,8.07377034"
            id="Fill-10"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  </Icon>
);
