import { useEffect, useState, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { navigate } from 'gatsby';
import {
  useMyKfh_LoginMutation,
  Mykfh_Read_AccountDocument,
  My_Kfh_Read_Saved_Properties_IdsDocument,
} from '../types/graphqlHelpers';
import { isBrowser } from '../utils/isBrowser';
import { MY_KFH_ROUTES } from '../urls';
import { UserContext } from '../context/UserContext';
import { useDataLayerPush } from './useDataLayerPush';

export const AUTH_TOKEN = 'kfh-auth-token';
export const AUTH_TOKEN_EXPIRES = 'kfh-auth-token-expires';

interface AuthTokenProps {
  token: string;
  expires: string;
}

interface UseAuthTokenProps {
  token?: string | null;
  expires?: string | null;
  setAuthToken: ({ token, expires }: AuthTokenProps) => void;
  removeAuthToken: () => void;
}

// custom hook to handle authToken - we use composition to decouple the auth system and it's storage
export const useAuthToken = (): UseAuthTokenProps => {
  let token = '';
  let expires = '';
  if (isBrowser) {
    token = localStorage.getItem(AUTH_TOKEN) || '';
    expires = localStorage.getItem(AUTH_TOKEN_EXPIRES) || '';
  }
  const setAuthToken = ({ token: authToken, expires: expiresTime }: AuthTokenProps) => {
    if (isBrowser) {
      localStorage.setItem(AUTH_TOKEN, authToken);
      localStorage.setItem(AUTH_TOKEN_EXPIRES, expiresTime);
    }
  };
  const removeAuthToken = () => {
    if (isBrowser) {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(AUTH_TOKEN_EXPIRES);
    }
  };
  return { token, expires, setAuthToken, removeAuthToken };
};

export const useLogin = ({ disableRedirect }) => {
  const { setAuthToken } = useAuthToken();
  const [error, setError] = useState<string>();
  const [myKfhLoginMutation, { data, loading }] = useMyKfh_LoginMutation();
  const { dataLayerPush } = useDataLayerPush();
  const { fetchUser } = useContext(UserContext);

  const handleLogin = ({ email, password }: { email: string; password: string }) => {
    myKfhLoginMutation({
      variables: {
        myKfhLogin: {
          email,
          password,
        },
      },
      onCompleted: (loginData) => {
        dataLayerPush({
          event: 'formSubmit',
          formName: 'myKfhLogin',
          formValidation: 'success',
          formError: undefined,
        });
        if (!loading && loginData?.myKfh?.mykfhLogin?.accessToken && loginData?.myKfh?.mykfhLogin?.success) {
          setAuthToken({
            token: loginData.myKfh.mykfhLogin.accessToken,
            expires: loginData.myKfh.mykfhLogin.expiresIn,
          });
          setError(undefined);

          if (!disableRedirect) {
            navigate(MY_KFH_ROUTES.dashboard);
          } else {
            fetchUser();
          }
        } else if (!loading && loginData?.myKfh?.mykfhLogin?.errorDescription) {
          setError(loginData?.myKfh?.mykfhLogin?.errorDescription as string);
        }
      },
      // Refetch the user data after login
      // At this point the token is not in local storage but we can grab it from the result of the mutation
      refetchQueries: (result) => [
        {
          query: Mykfh_Read_AccountDocument,
          context: {
            headers: {
              'MYKFH-AUTHORIZATION': result.data.myKfh.mykfhLogin.accessToken,
            },
          },
        },
        {
          query: My_Kfh_Read_Saved_Properties_IdsDocument,
          context: {
            headers: {
              'MYKFH-AUTHORIZATION': result.data.myKfh.mykfhLogin.accessToken,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return {
    handleLogin,
    data,
    loading,
    error,
  };
};
export const useLogout = () => {
  const { removeAuthToken } = useAuthToken();
  const { handleClearUser } = useContext(UserContext);
  const apolloClient = useApolloClient();
  const { dataLayerPush } = useDataLayerPush();

  const logout = async () => {
    // we remove all information in the store
    await apolloClient.clearStore();
    // Remove the auth token
    removeAuthToken();
    // clear the user out of context
    handleClearUser();

    dataLayerPush({
      event: 'formSubmit',
      formName: 'myKfhLogout',
      formValidation: 'success',
      formError: undefined,
    });
  };
  return logout;
};
