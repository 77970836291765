/* eslint-disable arrow-body-style */
import React from 'react';
import { PageProps } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ToastContainer, Slide } from 'react-toastify';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GlobalSettingsContextProvider } from '../context/GlobalSettingsContext';
import { UiStateContextProvider } from '../context/UiStateContext';
import { UserContextProvider } from '../context/UserContext';
import { RecentlyViewedPropertiesProvider } from '../context/RecentlyViewedPropertiesContext';
import { ActivePropertyProvider } from '../context/ActivePropertyContext';
import GlobalStyles from '../styles/GlobalStyles';
import theme from '../styles/tokens';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { LoginBlade } from '../components/my-kfh/LoginBlade';
import { DataLayerWrapper } from '../components/DataLayerWrapper';
import 'swiper/css/bundle';
import { Main } from '../components/Main';
import { ExperimentsContextProvider } from '../context/ExperimentsContext';
import { ExperimentsWrapper } from '../components/ExperimentsWrapper';
import { BladeRenderer } from '../components/BladeRenderer';

export const BaseLayout = ({ location, data, children, serverData }: PageProps) => {
  return (
    <UserContextProvider>
      <GlobalSettingsContextProvider>
        <ExperimentsContextProvider>
          <UiStateContextProvider>
            <ActivePropertyProvider>
              <RecentlyViewedPropertiesProvider>
                <GoogleReCaptchaProvider
                  reCaptchaKey="6LcdI7QUAAAAAG1SWnwjwqY3TIplTZoBcB2eSR3Y"
                  scriptProps={{ defer: true }}
                  useRecaptchaNet
                >
                  <GlobalStyles />
                  <ThemeProvider theme={theme}>
                    <DataLayerWrapper data={data} serverData={serverData} location={location}>
                      <ExperimentsWrapper data={data}>
                        <Header />
                        <Main>{children}</Main>
                        <Footer />
                        <ToastContainer theme="colored" pauseOnHover transition={Slide} autoClose={2000} />
                        {location?.state?.loginBlade && <LoginBlade />}
                        <BladeRenderer />
                      </ExperimentsWrapper>
                    </DataLayerWrapper>
                  </ThemeProvider>
                </GoogleReCaptchaProvider>
              </RecentlyViewedPropertiesProvider>
            </ActivePropertyProvider>
          </UiStateContextProvider>
        </ExperimentsContextProvider>
      </GlobalSettingsContextProvider>
    </UserContextProvider>
  );
};
