import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const SliderOutlineRight: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <rect width="31" height="31" x=".5" y=".5" stroke="#D8183A" rx="2" />
      <path d="M10.667 10.667h10.667v10.667H10.667z" />
      <path
        fill="#D8183A"
        d="M14.667 18.674v-5.36c0-.372.448-.558.711-.295l2.68 2.68a.417.417 0 0 1 0 .59l-2.68 2.68a.417.417 0 0 1-.711-.295Z"
      />
    </g>
  </Icon>
);
