import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Print: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M28 12V4.828c0-.53-.21-1.039-.586-1.414L24.586.586A2 2 0 0 0 23.17 0H6a2 2 0 0 0-2 2v10a4 4 0 0 0-4 4v7a1 1 0 0 0 1 1h3v6a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2v-6h3a1 1 0 0 0 1-1v-7a4 4 0 0 0-4-4Zm-4 16H8v-6h16v6Zm0-14H8V4h12v3a1 1 0 0 0 1 1h3v6Zm3 4.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
      />
    </g>
  </Icon>
);
