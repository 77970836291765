import React, { createContext } from 'react';
import { useGlobalSettings } from '../hooks/useGlobalSettings';
import { Kfh_KfhSettingsType, Kfh_SocialSettingsType, SiteSiteMetadataFilterInput } from '../types/graphqlTypes';

interface GlobalSettingsContextProps {
  socialSettings: Kfh_SocialSettingsType;
  siteSettings: Kfh_KfhSettingsType;
  siteMeta: SiteSiteMetadataFilterInput;
}

export const GlobalSettingsContext = createContext({} as GlobalSettingsContextProps);
GlobalSettingsContext.displayName = 'GlobalSettingsContext';

export const GlobalSettingsContextProvider = ({ children }) => {
  const settings = useGlobalSettings();

  return <GlobalSettingsContext.Provider value={settings}>{children}</GlobalSettingsContext.Provider>;
};
