import { colors } from './colors';

const borders = {
  default: `solid 1px ${colors.grey3.default}`,
  defaultOpaque: 'solid 1px rgb(255 255 255 / 32%)',
  brand: `solid 1px ${colors.brand.default}`,
  brandDark: `solid 1px ${colors.brandDark.default}`,
  white: `solid 1px ${colors.white.default}`,
};

export default borders;

export type ThemeBorders = typeof borders;
export type ThemeBordersKeys = keyof typeof borders;
