import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { getResponsiveType } from '../utils';

export interface WhatsAppButtonProps {
  url: string;
}

const StyledWhatsAppButton = styled.a`
  ${({ theme: { space } }) => css`
    gap: ${space.sm};
    background: #16bf46;
    color: #fff;
    display: inline-flex;
    align-items: center;
    padding: ${space.sm} ${space.lg};
    border-radius: ${space.xl};
    ${getResponsiveType('titleSmall')}
    text-decoration: none;
    min-height: 48px;
  `};
`;

export const WhatsAppButton: FC<WhatsAppButtonProps> = ({ url, ...rest }) => (
  <StyledWhatsAppButton href={url} {...rest} target="_blank">
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
      <defs>
        <path
          id="a"
          d="M.598 13.878c-.001 2.36.619 4.666 1.796 6.697l-1.909 6.94 7.135-1.862a13.5 13.5 0 0 0 6.431 1.63h.006c7.418 0 13.455-6.009 13.459-13.394 0-3.58-1.399-6.944-3.94-9.476a13.4 13.4 0 0 0-9.52-3.928C6.638.485.601 6.493.598 13.878"
        />
        <path
          id="c"
          d="M14.059 0C6.375 0 .12 6.224.118 13.874a13.782 13.782 0 0 0 1.86 6.936L0 28l7.391-1.93a13.992 13.992 0 0 0 6.663 1.69h.005c7.683 0 13.938-6.225 13.941-13.875.001-3.708-1.448-7.194-4.08-9.817A13.882 13.882 0 0 0 14.06 0ZM4.518 20.448l-.276-.436a11.461 11.461 0 0 1-1.77-6.137c.002-6.359 5.2-11.531 11.591-11.531a11.54 11.54 0 0 1 8.193 3.381 11.434 11.434 0 0 1 3.39 8.16c-.002 6.357-5.2 11.532-11.587 11.532h-.005a11.623 11.623 0 0 1-5.898-1.608l-.422-.25-4.387 1.144 1.17-4.255Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M-2-2h32v32H-2z" />
        <g>
          <path
            fill="#FFF"
            d="m7.733 23.56.424.25a11.611 11.611 0 0 0 5.897 1.606h.005c6.386 0 11.585-5.173 11.587-11.531.001-3.082-1.203-5.98-3.39-8.16a11.538 11.538 0 0 0-8.193-3.382c-6.392 0-11.59 5.173-11.592 11.532 0 2.179.612 4.3 1.772 6.137l.275.436-1.17 4.256 4.385-1.145ZM0 28l1.978-7.19a13.806 13.806 0 0 1-1.86-6.936C.12 6.224 6.373 0 14.057 0c3.73.002 7.23 1.446 9.863 4.069A13.759 13.759 0 0 1 28 13.885c-.003 7.65-6.258 13.875-13.941 13.875h-.007a13.99 13.99 0 0 1-6.66-1.689L0 28Z"
          />
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            fill="#16BE45"
            d="M.598 13.878c-.001 2.36.619 4.666 1.796 6.697l-1.909 6.94 7.135-1.862a13.5 13.5 0 0 0 6.431 1.63h.006c7.418 0 13.455-6.009 13.459-13.394 0-3.58-1.399-6.944-3.94-9.476a13.4 13.4 0 0 0-9.52-3.928C6.638.485.601 6.493.598 13.878"
            mask="url(#b)"
          />
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            fill="#FFF"
            d="M14.059 0C6.375 0 .12 6.224.118 13.874a13.782 13.782 0 0 0 1.86 6.936L0 28l7.391-1.93a13.992 13.992 0 0 0 6.663 1.69h.005c7.683 0 13.938-6.225 13.941-13.875.001-3.708-1.448-7.194-4.08-9.817A13.882 13.882 0 0 0 14.06 0ZM4.518 20.448l-.276-.436a11.461 11.461 0 0 1-1.77-6.137c.002-6.359 5.2-11.531 11.591-11.531a11.54 11.54 0 0 1 8.193 3.381 11.434 11.434 0 0 1 3.39 8.16c-.002 6.357-5.2 11.532-11.587 11.532h-.005a11.623 11.623 0 0 1-5.898-1.608l-.422-.25-4.387 1.144 1.17-4.255Z"
            mask="url(#d)"
          />
          <path
            fill="#FFF"
            d="M10.574 8.074c-.26-.578-.535-.59-.784-.6-.203-.008-.435-.007-.667-.007-.232 0-.61.087-.929.433-.319.347-1.22 1.186-1.22 2.892 0 1.707 1.25 3.356 1.423 3.587.175.232 2.41 3.845 5.95 5.235 2.943 1.155 3.542.925 4.181.868.639-.059 2.06-.84 2.351-1.65.29-.808.29-1.503.203-1.648-.086-.145-.319-.231-.667-.404-.349-.174-2.06-1.013-2.38-1.128-.32-.116-.552-.174-.784.173-.233.347-.9 1.128-1.103 1.359-.204.232-.407.261-.755.087-.348-.173-1.47-.54-2.802-1.72-1.035-.92-1.734-2.055-1.937-2.402-.204-.347-.022-.535.152-.707.157-.156.35-.406.524-.609.173-.202.231-.346.347-.578.116-.23.059-.434-.029-.607-.087-.173-.764-1.889-1.074-2.574"
          />
        </g>
      </g>
    </svg>
    WhatsApp us
  </StyledWhatsAppButton>
);
