import React, { FC, Suspense, useEffect, useState } from 'react';
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { Input } from './Input';
import { Panel } from '../Panel';
import { Spacer } from '../Spacer';
import { FormSubmissionInput } from '../../types/graphqlTypes';
import { useCreate_Careers_ContactMutation } from '../../types/graphqlHelpers';
import { Textarea } from './Textarea';
import { Typography } from '../Typography';
import { FileUpload } from './FileUpload';
import { CaretDown } from '../icon/icons/CaretDown';
import { TickCircle } from '../icon/icons/TickCircle';
import { Select } from './Select';
import { TITLES } from '../../const';
import Blade, { BladeBody, BladeContainer, BladeFooter } from '../Blade';
import { useDataLayerPush } from '../../hooks/useDataLayerPush';
import { SmallPrint } from './SmallPrint';
import { getFormFields } from '../../utils/getFormFields';
import { isBrowser } from '../../utils/isBrowser';
import { Loader } from '../Loader';
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { RecaptchaAction } from './RecaptchaAction';
import { alertBadEmail, validateEmail } from '../../utils/emailBlackList';

const PhoneNumberInput = React.lazy(() => import('./PhoneNumberInput'));

export interface CareersContactProps {}

const StyledTitleWrap = styled.div`
  ${({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.md};
  `}
`;

const StyledLead = styled(Typography)`
  ${({ theme: { space } }) => css`
    max-width: 530px;
  `}
`;

const StyledSendCVButton = styled.button`
  ${({ theme: { colors, space, borders } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${space.md};
    background: none;
    width: 100%;
    border-top: ${borders.default};
    padding-top: ${space.lg};
    margin-top: ${space.lg};

    &:hover {
      color: ${colors.brand.default};
    }
  `}
`;

export const CareersContact: FC<CareersContactProps> = ({ ...rest }) => {
  const { dataLayerPush } = useDataLayerPush();
  const [files, setFiles] = useState([]);
  const [sendCV, setSendCV] = useState(false);

  // Get vacancy types for selct

  const { vacancyTypes } = useStaticQuery(graphql`
    query vacancyTypesQuery {
      vacancyTypes: allKfhVacancyTypeType {
        edges {
          node {
            name
          }
        }
      }
    }
  `);

  // Shape vacancy type data for select

  const VACANCY_TYPE_OPTIONS = [];
  vacancyTypes.edges.forEach(async ({ node }) => {
    const { name } = node;
    VACANCY_TYPE_OPTIONS.push({
      name,
      value: name,
    });
  });

  // Handle open/close

  const handleSendCVClick = () => {
    setSendCV(!sendCV);
  };

  // Handle form submission

  // Temp var to allow mock submisisons
  const [hasSent, setHasSent] = useState(false);

  // Mutation to submit the form
  const [CreateCareersContactMutation, { data, loading }] = useCreate_Careers_ContactMutation();

  const formMethods = useForm({
    mode: 'onSubmit',
  });

  const { register, handleSubmit, formState, control } = formMethods;
  const { errors, dirtyFields, touchedFields, isSubmitted } = formState;
  const { handleReCaptchaVerify, isHuman } = useRecaptcha({ formMethods, action: 'careersContact' });
  useEffect(() => {
    function checkSuccess() {
      if (!loading && data?.form?.createFormSubmission?.success) {
        setHasSent(true);
      }
    }
    checkSuccess();
  }, [data, dataLayerPush, loading]);

  // Function to handle collecting form data and  submitting the GQL mutation
  const onSubmit: SubmitHandler<FormSubmissionInput> = async (formData) => {
    alertBadEmail(formData.email);
    delete formData.captchaToken;
    const submitData = {
      type: 'TALENT',
      pageUrl: isBrowser ? window.location.href : '',
      fromName: `${formData.forename} ${formData.surname}`,
      fromEmail: formData.email,
      fields: getFormFields(formData),
      files,
    };

    dataLayerPush({
      event: 'formSubmit',
      formName: 'careersContact',
      formValidation: 'success',
      formError: undefined,
    });

    await CreateCareersContactMutation({
      variables: { formSubmission: submitData },
    });
  };

  return (
    <Blade id="careersContact" customBlade>
      <FormProvider {...formMethods}>
        {(!isSubmitted || !hasSent) && (
          <BladeContainer>
            <BladeBody>
              <Spacer>
                <Typography variant="displaySmall">Contact our talent acquisition team</Typography>

                <Panel as="form" onSubmit={handleSubmit(onSubmit)} method="post" noValidate border>
                  <Spacer spacing="half" r>
                    <Typography component="h3" variant="titleMedium">
                      Your details
                    </Typography>
                    <Typography variant="smallprint">*Please complete all required form fields</Typography>

                    <Select
                      id="title"
                      label="Title"
                      {...register('title', { required: true })}
                      required
                      options={TITLES}
                      constrained
                      invalid={!!errors.title}
                      valid={touchedFields.title && dirtyFields.title && !errors.title}
                    />
                    <Input
                      type="text"
                      invalid={!!errors.forename}
                      valid={touchedFields.forename && dirtyFields.forename && !errors.forename}
                      label="First name"
                      id="forename"
                      placeholder="e.g Sarah"
                      {...register('forename', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      label="Last name"
                      id="surname"
                      invalid={!!errors.surname}
                      placeholder="e.g Smith"
                      valid={touchedFields.surname && dirtyFields.surname && !errors.surname}
                      {...register('surname', { required: true })}
                      required
                    />
                    <Input
                      type="email"
                      label="Email"
                      id="email"
                      invalid={!!errors.email}
                      valid={touchedFields.email && dirtyFields.email && !errors.email}
                      required
                      placeholder="e.g sarah@email.com"
                      {...register('email', {
                        validate: validateEmail, // email spam check
                        required: true,
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                    <Controller
                      name="contactNumber"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Suspense fallback={<Loader size="small" />}>
                          <PhoneNumberInput
                            label="Phone"
                            id="PhoneNumber"
                            invalid={!!errors.contactNumber}
                            placeholder="Mobile or landline"
                            required
                            valid={touchedFields.contactNumber && dirtyFields.contactNumber && !errors.contactNumber}
                            {...field}
                          />
                        </Suspense>
                      )}
                    />
                    <Textarea
                      invalid={!!errors.message}
                      valid={touchedFields.message && dirtyFields.message && !errors.message}
                      label="Message"
                      id="message"
                      {...register('message', { required: true })}
                      required
                    />
                    <StyledSendCVButton type="button" onClick={handleSendCVClick}>
                      <span>
                        <Typography component="span" variant="titleSmall">
                          Send us your CV
                        </Typography>{' '}
                        (optional)
                      </span>
                      <CaretDown />
                    </StyledSendCVButton>
                    {sendCV && (
                      <Spacer spacing="half">
                        <p>
                          Our team are always on the look out for talented people to join KFH. Submit your CV to be
                          considered for future roles.
                        </p>
                        <Select
                          id="vacancyType"
                          label="Vacancy Type"
                          {...register('vacancyType', { required: true })}
                          options={VACANCY_TYPE_OPTIONS}
                          valid={touchedFields.vacancyType && dirtyFields.vacancyType && !errors.vacancyType}
                        />
                        <FileUpload id="cv" label="Attach CV document" setFiles={setFiles} />
                      </Spacer>
                    )}
                  </Spacer>
                </Panel>
                <RecaptchaAction show={!isHuman} handleReCaptchaVerify={handleReCaptchaVerify} />
                <SmallPrint />
              </Spacer>
            </BladeBody>
            <BladeFooter
              primaryButton={{
                label: 'Send enquiry',
                onClick: handleSubmit(onSubmit),
                disableClickTracking: true,
              }}
            />
          </BladeContainer>
        )}

        {isSubmitted && hasSent && (
          <BladeContainer>
            <BladeBody>
              <Panel border>
                <Spacer>
                  <StyledTitleWrap>
                    <TickCircle size="extraLarge" />
                    <Typography variant="displaySmall">Received</Typography>
                  </StyledTitleWrap>
                  <StyledLead variant="lead">Thank you for your submission.</StyledLead>
                </Spacer>
              </Panel>
            </BladeBody>
          </BladeContainer>
        )}
      </FormProvider>
    </Blade>
  );
};
