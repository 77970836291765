import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { UseFormReturn } from 'react-hook-form';

interface UseRecaptchaProps {
  formMethods: UseFormReturn<any>;
  action: string;
}
interface UseRecaptchaReturn {
  handleReCaptchaVerify: () => Promise<void>;
  isHuman: boolean;
}
export const useRecaptcha = ({ formMethods, action }: UseRecaptchaProps): UseRecaptchaReturn => {
  // Setup extra form field

  const { register, setValue, watch } = formMethods;

  useEffect(() => {
    register('captchaToken', { required: true });
  });
  // Setup Recaptcha
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha(action);
    setValue('captchaToken', token, { shouldDirty: true });
  }, [executeRecaptcha, setValue, action]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const isHuman = !!watch('captchaToken');

  return { handleReCaptchaVerify, isHuman };
};
