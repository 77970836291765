import React, { useContext, useRef, useEffect, FC, useCallback, MutableRefObject } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { UiStateContext } from '../context/UiStateContext';
import { Blades } from '../types/blades';
import { isBrowser } from '../utils/isBrowser';
import { IconButton } from './IconButton';
import { Portal } from './Portal';
import { Typography } from './Typography';
import { Button, ButtonProps } from './Button';
import { Close } from './icon/icons/Close';

export type BladeWidths = 'narrow' | 'wide' | 'full';

export interface BladeProps {
  id: Blades;
  width?: BladeWidths;
  renderChildrenOnOpen?: boolean;
  heading?: string;
  customFooterComponent?: React.ReactNode;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  customHeaderComponent?: React.ReactNode;
  customBlade?: boolean;
  hideFooterOnDesktop?: boolean;
  leftAlign?: boolean;
}

interface BladeFooterProps {
  customFooterComponent?: React.ReactNode;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  as?: any;
  hideFooterOnDesktop?: boolean;
  leftAlign?: boolean;
}

interface BladeContainerProps {
  heading?: string;
  customHeaderComponent?: React.ReactNode;
  as?: any;
}

interface StyledBladeProps {
  isOpen?: boolean;
  width?: BladeWidths;
  animate?: boolean;
}

interface StyledBladeHeaderInnerProps {
  spaceBetween?: boolean;
}

interface StyledBladeFooterProps {
  doubleAction?: boolean;
  hideFooterOnDesktop?: boolean;
  leftAlign?: boolean;
}

const styleSettings = {
  narrowContainer: '492px',
  wideContainer: '960px',
  fullContainer: '1184px',
  overlay: {
    transitionSpeed: '0.25s',
    transitionDelay: '0s',
  },
  container: {
    transitionSpeed: '0.5s',
    transitionDelay: '0.25s',
  },
};

const StyledBladeContainer = styled.div`
  ${({ theme: { colors, shadows, maxMedia } }) => css`
    display: flex;
    flex-direction: column;
    position: absolute;
    max-width: var(--containerWidth);
    transform: translateX(var(--containerOffset));
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    max-height: -webkit-fill-available;
    background-color: ${colors.white.default};
    box-shadow: ${shadows.medium};

    @media (max-width: ${maxMedia.sm}) {
      max-width: 100%;
    }
  `};
`;

export const StyledBladeFooter = styled.div<StyledBladeFooterProps>`
  ${({ theme: { space, media, shadows, layers, maxMedia }, doubleAction, leftAlign, hideFooterOnDesktop }) => css`
    display: flex;
    justify-content: flex-end;
    gap: ${space.sm};
    padding: ${space.md};
    box-shadow: ${shadows.medium};
    z-index: ${layers.bladeFooter};

    ${leftAlign &&
    css`
      justify-content: flex-start;
    `}

    ${doubleAction &&
    css`
      > * {
        flex-shrink: 0;
        max-width: 380px;
      }

      > *:last-child {
        flex-grow: 1;
      }
    `}

    // Mobile styles

    @media (max-width: ${maxMedia.xl}) {
      > * {
        flex-shrink: 0;
      }

      > *:last-child {
        flex-grow: 1;
      }
    }

    // Desktop styles

    @media (min-width: ${media.xl}) {
      padding: ${space.lg};

      ${hideFooterOnDesktop &&
      css`
        display: none;
      `}
    }
  `};
`;

const StyledBlade = styled.div<StyledBladeProps>`
  ${({ theme: { layers }, isOpen, width, animate }) => {
    const slideIn = keyframes`
      from {
        transform: translateX(var(--containerOffset));
      }
      to {
        transform: translateX(0);
      }
    `;

    const slideOut = keyframes`
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(var(--containerOffset));
      }
    `;

    const fadeIn = keyframes`
      from {
        opacity: 0;
        display: none;
      }
      to {
        opacity: 1;
        display: block;
      }
    `;

    const fadeOut = keyframes`
      from {
        opacity: 1;
        display: block;
      }
      to {
        opacity: 0;
        display: none;
      }
    `;

    let containerWidth = styleSettings.narrowContainer;
    if (width === 'wide') {
      containerWidth = styleSettings.wideContainer;
    }
    if (width === 'full') {
      containerWidth = styleSettings.fullContainer;
    }

    return css`
      --containerWidth: ${containerWidth};
      --containerOffset: ${containerWidth};
      display: ${isOpen ? 'block' : 'none'};
      position: fixed;
      right: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      max-height: -webkit-fill-available;
      z-index: ${layers.blade};
      animation: ${isOpen ? fadeIn : fadeOut} ${styleSettings.overlay.transitionSpeed}
        ${styleSettings.overlay.transitionDelay} 1 normal forwards;

      ${StyledBladeContainer} {
        animation: ${slideIn} ${styleSettings.container.transitionSpeed} ease-out
          ${styleSettings.container.transitionDelay} 1 normal forwards;
      }

      // Widths

      ${width === 'wide' &&
      css`
        ${StyledBladeFooter} {
          > *:last-child {
            flex-basis: auto;
          }
        }
      `}
    `;
  }};
`;

const StyledBladeOverlay = styled.button`
  ${({ theme: { colors } }) => css`
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    border: none;
    opacity: 0;
  `};
`;

const StyledBladeHeader = styled.div`
  ${({ theme: { space, shadows, layers } }) => css`
    display: flex;
    gap: ${space.lg};
    padding: ${space.md} ${space.lg};
    box-shadow: ${shadows.small};
    z-index: ${layers.bladeHeader};
  `};
`;

const StyledBladeHeaderInner = styled.div<StyledBladeHeaderInnerProps>`
  ${({ theme: { space }, spaceBetween }) => css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: ${spaceBetween ? 'space-between' : 'center'};

    // Adjust margin to make heading appear centered

    ${!spaceBetween &&
    css`
      ${StyledBladeFooter} {
        > * {
          margin-right: ${space.xl};
        }
      }
    `}
  `};
`;

export const StyledBladeMain = styled.div`
  ${({ theme: { media } }) => css`
    flex-grow: 1;
    overflow-y: auto;
    // Desktop styles

    @media (min-width: ${media.xl}) {
    }
  `};
`;

export const StyledBladeBody = styled.div`
  ${({ theme: { space, media } }) => css`
    padding: ${space.md};
    flex-grow: 1;
    overflow-y: auto;

    // Desktop styles

    @media (min-width: ${media.xl}) {
      padding: ${space.lg};
    }
  `};
`;

export const BladeContainer: FC<React.PropsWithChildren<BladeContainerProps>> = ({
  children,
  as,
  heading,
  customHeaderComponent,
}) => {
  const { setActiveBlade, activeBladeTriggerEl, setActiveBladeTriggerEl, activeBladeWidth, setActiveBladeWidth } =
    useContext(UiStateContext);

  const handleClose = () => {
    setActiveBlade(null);
    if (activeBladeWidth) {
      setActiveBladeWidth(undefined);
    }
    if (activeBladeTriggerEl?.current) {
      activeBladeTriggerEl.current.focus();
      setActiveBladeTriggerEl(undefined);
    }
  };

  return (
    <StyledBladeContainer as={as}>
      <StyledBladeHeader>
        <IconButton onClick={handleClose} type="button" a11yTitle={`Close ${heading && heading}`}>
          <Close size="medium" />
        </IconButton>
        <StyledBladeHeaderInner spaceBetween={Boolean(heading && customHeaderComponent)}>
          {heading && (
            <Typography variant="titleSmall" component="h3">
              {heading}
            </Typography>
          )}
          {customHeaderComponent && customHeaderComponent}
        </StyledBladeHeaderInner>
      </StyledBladeHeader>
      {children}
    </StyledBladeContainer>
  );
};

export const BladeBody = ({ children, ...rest }) => <StyledBladeBody {...rest}>{children}</StyledBladeBody>;

export const BladeFooter: FC<React.PropsWithChildren<BladeFooterProps>> = ({
  customFooterComponent,
  secondaryButton,
  primaryButton,
  hideFooterOnDesktop,
  leftAlign,
  ...rest
}) => {
  const { setActiveBlade, activeBladeTriggerEl, setActiveBladeTriggerEl, activeBladeWidth, setActiveBladeWidth } =
    useContext(UiStateContext);

  const handleClose = () => {
    setActiveBlade(null);
    if (activeBladeWidth) {
      setActiveBladeWidth(undefined);
    }
    if (activeBladeTriggerEl?.current) {
      activeBladeTriggerEl.current.focus();
      setActiveBladeTriggerEl(undefined);
    }
  };

  return (
    <StyledBladeFooter
      {...rest}
      doubleAction={!!primaryButton}
      hideFooterOnDesktop={hideFooterOnDesktop}
      leftAlign={leftAlign}
    >
      {customFooterComponent || (
        <>
          <Button
            variant="outline"
            as={!secondaryButton && 'button'}
            onClick={secondaryButton && secondaryButton.onClick ? secondaryButton.onClick : handleClose}
            {...secondaryButton}
          >
            {secondaryButton && secondaryButton.label ? secondaryButton.label : 'Close'}
          </Button>
          {primaryButton && <Button variant="solid" {...primaryButton} />}
        </>
      )}
    </StyledBladeFooter>
  );
};

const Blade: FC<React.PropsWithChildren<BladeProps>> = ({
  children,
  id,
  width = 'narrow',
  heading,
  renderChildrenOnOpen,
  customHeaderComponent,
  customBlade,
  secondaryButton,
  primaryButton,
  hideFooterOnDesktop,
  leftAlign,
}) => {
  const {
    activeBlade,
    setActiveBlade,
    activeBladeTriggerEl,
    setActiveBladeTriggerEl,
    activeBladeWidth,
    setActiveBladeWidth,
    activeBladeAnimate,
  } = useContext(UiStateContext);
  const bladeEl = useRef<HTMLDivElement | null | undefined>();

  const focusableElements =
    'a[href], button:not(#blade-overlay), textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select';

  const isActive = id === activeBlade;

  const handleClose = useCallback(() => {
    setActiveBlade(null);
    if (activeBladeWidth) {
      setActiveBladeWidth(undefined);
    }
    if (activeBladeTriggerEl?.current) {
      activeBladeTriggerEl.current.focus();
      setActiveBladeTriggerEl(undefined);
    }
  }, [activeBladeTriggerEl, activeBladeWidth, setActiveBlade, setActiveBladeTriggerEl, setActiveBladeWidth]);

  const handleTabKey = (e) => {
    const focusableBladeElements = bladeEl?.current?.querySelectorAll<HTMLElement>(focusableElements);
    const firstElement = focusableBladeElements ? focusableBladeElements[0] : undefined;
    const lastElement = focusableBladeElements ? focusableBladeElements[focusableBladeElements.length - 1] : undefined;

    if (firstElement && !e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus();
      e.preventDefault();
    }

    if (lastElement && e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus();
      e.preventDefault();
    }
  };

  useEffect(() => {
    const focusableBladeElements = bladeEl?.current?.querySelectorAll<HTMLElement>(focusableElements);
    const { body } = document;
    const scrollbarWidth = window.innerWidth - body.clientWidth;
    if (focusableBladeElements && focusableBladeElements.length >= 1) {
      const firstElement = focusableBladeElements[0];
      firstElement?.focus();
    }
    const keyListenersMap = new Map([
      [27, handleClose],
      [9, handleTabKey],
    ]);

    const keyListener = (e) => {
      const listener = keyListenersMap.get(e.keyCode as number);
      return listener && listener(e);
    };

    if (isActive) {
      if (isBrowser) {
        body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.classList.add('is-hidden');
        document.body.classList.add('has-overlay');
        window.addEventListener('keydown', keyListener);
      }
    }
    return () => {
      if (isBrowser) {
        body.style.paddingRight = '0px';
        document.body.classList.remove('is-hidden');
        document.body.classList.remove('has-overlay');
        window.removeEventListener('keydown', keyListener);
      }
    };
  }, [isActive, id, handleClose]);

  return (
    <>
      <Portal id="blade-portal">
        {isActive && (
          <StyledBlade
            isOpen={isActive}
            role="dialog"
            aria-modal="true"
            aria-hidden={activeBlade !== id}
            ref={bladeEl as any}
            id={id as string}
            width={activeBladeWidth || width}
            animate={activeBladeAnimate}
          >
            <StyledBladeOverlay onClick={handleClose} tabIndex={-1} id="blade-overlay">
              Close
            </StyledBladeOverlay>
            {customBlade ? (
              <>{children}</>
            ) : (
              <BladeContainer heading={heading} customHeaderComponent={customHeaderComponent}>
                {renderChildrenOnOpen ? (
                  isActive && <BladeBody>{children}</BladeBody>
                ) : (
                  <BladeBody>{children}</BladeBody>
                )}
                <BladeFooter
                  primaryButton={primaryButton}
                  secondaryButton={secondaryButton}
                  hideFooterOnDesktop={hideFooterOnDesktop}
                  leftAlign={leftAlign}
                />
              </BladeContainer>
            )}
          </StyledBlade>
        )}
      </Portal>
    </>
  );
};

export default Blade;
