import { useContext } from 'react';
import { UiStateContext } from '../context/UiStateContext';
import { UserContext } from '../context/UserContext';
import { isBrowser } from '../utils/isBrowser';
import { useCurrencies } from './useCurrencies';

interface useDataLayerPushReturn {
  dataLayerPush: any;
}

export const useDataLayerPush = (): useDataLayerPushReturn => {
  const { activeBlade } = useContext(UiStateContext);
  const { user } = useContext(UserContext);
  const { currentName } = useCurrencies();

  const dataLayerPush = (data) => {
    if (isBrowser && window.dataLayer) {
      window.dataLayer.push({
        ...data,
        bladeOpen: !!activeBlade,
        bladeId: activeBlade || undefined,
        myKFHLogInStatus: user ? 'logged in' : 'logged out',
        myKFHUserId: user ? user.id : undefined,
        myKFHUserType: user ? user.profile.aboutMe : undefined,
        currency: currentName,
      });
    }

    // Webtrends optimise data push for conversion tracking
    if (isBrowser && window.opt_data) {
      if (
        data.event === 'propertyValuation' ||
        data.event === 'stampDutyCalculator' ||
        data.event === 'arrangeAViewing'
      ) {
        window.opt_data.push({
          event: data.event,
        });
      }

      if (data.event === 'cta button' && data.clickBladeId === 'propertyRequestAValuation') {
        window.opt_data.push({
          event: 'Request a valuation button click',
        });
      }
    }
  };

  return {
    dataLayerPush,
  };
};
