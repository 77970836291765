import React, { FC, forwardRef, RefObject } from 'react';
import styled, { css } from 'styled-components';
import { getOffsetSpace } from '../../utils';
import { BaseFormProps, StyledBase } from './Base';
import { FormField, FormFieldProps } from './FormField';
import { IconButton } from '../IconButton';
import { Close } from '../icon/icons/Close';

export interface InputProps extends BaseFormProps, FormFieldProps {
  type: 'text' | 'number' | 'email' | 'password' | 'tel';
  prefix?: string;
  suffix?: string;
  clearOnClick?: any; // TODO - TYPE - function type!?
  displayClearButton?: boolean;
  defaultValue?: string;
}

interface StyledBaseWrapperProps {
  hasPrefix: boolean;
  hasSuffix: boolean;
}

const StyledBaseWrapper = styled.div<StyledBaseWrapperProps>`
  ${({ hasPrefix, hasSuffix }) => css`
    display: flex;
    position: relative;

    // Affixes

    ${hasPrefix &&
    css`
      ${StyledBase} {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    `}

    ${hasSuffix &&
    css`
      ${StyledBase} {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    `}
  `};
`;

const StyledAffix = styled.div`
  ${({ theme: { space, global, borders } }) => css`
    border: ${borders.default};
    border-radius: ${global.borderRadius.default};
    padding: ${getOffsetSpace(space.md)};
  `};
`;

const StyledPrefix = styled(StyledAffix)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
`;

const StyledSuffix = styled(StyledAffix)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
`;

const StyledClearButton = styled(IconButton)`
  ${({ theme: { space, global, borders } }) => css`
    position: absolute;
    top: 50%;
    right: ${space.md};
    transform: translateY(-50%);
  `};
`;

export const Input: FC<InputProps> = forwardRef(
  (
    {
      label,
      id,
      type = 'text',
      tooltipText,
      tooltipInfoIcon,
      valid,
      invalid,
      defaultValue,
      constrained,
      placeholder,
      required,
      errorMessage,
      prefix,
      suffix,
      hideLabel,
      displayClearButton,
      clearOnClick,
      disabled,
      hideAsterisk,
      helpText,
      labelHelper,
      ...rest
    },
    ref
  ) => (
    <FormField
      label={label}
      htmlFor={id}
      tooltipText={tooltipText}
      tooltipInfoIcon={tooltipInfoIcon}
      required={required}
      invalid={invalid}
      errorMessage={errorMessage}
      hideLabel={hideLabel}
      hideAsterisk={hideAsterisk}
      helpText={helpText}
      labelHelper={labelHelper}
      {...rest}
    >
      <StyledBaseWrapper hasPrefix={!!prefix} hasSuffix={!!suffix}>
        {prefix && <StyledPrefix>{prefix}</StyledPrefix>}
        <StyledBase
          as="input"
          type={type}
          id={id}
          name={id}
          valid={valid}
          invalid={invalid}
          ref={ref as RefObject<HTMLInputElement>}
          placeholder={placeholder}
          constrained={constrained}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        {suffix && <StyledSuffix>{suffix}</StyledSuffix>}
        {displayClearButton && clearOnClick ? (
          <StyledClearButton a11yTitle="Clear" onClick={clearOnClick}>
            <Close />
          </StyledClearButton>
        ) : (
          ''
        )}
      </StyledBaseWrapper>
    </FormField>
  )
);
