import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Euro: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        d="M24.032 25.156a.696.696 0 0 0-.837-.544c-.62.142-1.592.314-2.636.314-3.295 0-5.916-2.019-7.05-4.959h6.599a.696.696 0 0 0 .679-.543l.37-1.646a.696.696 0 0 0-.68-.85h-7.79c-.07-.831-.082-1.641.008-2.451h8.507c.328 0 .61-.228.68-.548l.379-1.727a.696.696 0 0 0-.68-.845h-8.026c1.198-2.61 3.639-4.354 6.826-4.354.841 0 1.658.13 2.197.24a.697.697 0 0 0 .813-.5l.696-2.575a.696.696 0 0 0-.542-.866A16.901 16.901 0 0 0 20.488 3c-5.64 0-10.192 3.497-11.872 8.357h-1.92a.696.696 0 0 0-.696.697v1.726c0 .385.312.697.696.697h1.252c-.058.79-.068 1.7-.01 2.452H6.696a.696.696 0 0 0-.696.696v1.646c0 .384.312.696.696.696h1.748C9.896 25.21 14.43 29 20.488 29c1.526 0 2.818-.264 3.546-.452a.697.697 0 0 0 .51-.81l-.512-2.582Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </Icon>
);
