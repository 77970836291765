import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Panel } from '../Panel';

interface MockComponentProps {
  children?: any;
  title?: string;
}

const StyledMockComponent = styled(Panel)`
  ${({ theme }) => css`
    /* TEMP */
    // margin: 32px 0;
  `};
`;

const MockComponent: FC<React.PropsWithChildren<MockComponentProps>> = ({ children, ...rest }) => (
  <StyledMockComponent border="default" backgroundColor="white" {...rest}>
    {children}
  </StyledMockComponent>
);

export default MockComponent;
