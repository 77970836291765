export const LISTINGS = {
  perPage: 12,
};

export const TITLES = [
  { name: 'Mr', value: 'Mr' },
  { name: 'Mrs', value: 'Mrs' },
  { name: 'Ms', value: 'Ms' },
  { name: 'Miss', value: 'Miss' },
  { name: 'Dr', value: 'Dr' },
  { name: 'Prof', value: 'Prof' },
  { name: 'Prefer not to say', value: 'Prefer not to say' },
];

export const SEARCH_ORDER = [
  { name: 'High to low', value: 'HIGHEST' },
  { name: 'Low to high', value: 'LOWEST' },
  { name: 'Recently added', value: 'DATE' },
];

export const SEARCH_SOLD_ORDER = [
  { name: 'Recently sold', value: 'DATE' },
  { name: 'High to low', value: 'HIGHEST' },
  { name: 'Low to high', value: 'LOWEST' },
];

export const SEARCH_LET_ORDER = [
  { name: 'Recently let', value: 'DATE' },
  { name: 'High to low', value: 'HIGHEST' },
  { name: 'Low to high', value: 'LOWEST' },
];

export const ABOUT_ME_SALES = [
  { name: 'Buyer', value: 'Buyer' },
  { name: 'Seller', value: 'Seller' },
  { name: 'Buyer and seller', value: 'Buyer and seller' },
  { name: 'Investor', value: 'Investor' },
  { name: 'Interested in property', value: 'Interested in property' },
];

export const ABOUT_ME_LETTINGS = [
  { name: 'Landlord', value: 'Landlord' },
  { name: 'Tenant', value: 'Tenant' },
  { name: 'Investor', value: 'Investor' },
  { name: 'Interested in property', value: 'Interested in property' },
];

export const PRICE_SINCE = [
  { name: '1 year ago', value: '1' },
  { name: '2 year ago', value: '2' },
  { name: '3 year ago', value: '3' },
  { name: '4 year ago', value: '4' },
  { name: '5 year ago', value: '5' },
  { name: '6 year ago', value: '6' },
  { name: '7 year ago', value: '7' },
  { name: '8 year ago', value: '8' },
  { name: '9 year ago', value: '9' },
  { name: '10 year ago', value: '10' },
];

export const ABOUT_ME_VALUES = {
  firstTimeBuyer: 'First time buyer',
  buyer: 'Buyer',
  seller: 'Seller',
  tenant: 'Tenant',
  landlord: 'Landlord',
  investor: 'Investor/looking to invest',
  developer: 'Developer',
  interested: 'Interested in property',
  buyerAndSeller: 'Buyer and seller', // Handle historic option
};

export const NEWSCATEGORYURL = {
  news: 'news',
  insights: 'insights',
};
