import 'intl-tel-input/build/css/intlTelInput.css';
import React, { FC, forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { getButtonStyles } from '../../utils';
import { Attach } from '../icon/icons/Attach';
import { Typography } from '../Typography';

export interface InputProps {
  id: string;
  label: string;
  setFiles: (...event: any[]) => void;
}

const StyledFileUpload = styled.div`
  ${({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.md};
  `};
`;

const StyledLabel = styled.label`
  ${({ theme: { space } }) => css`
    display: inline-flex;
    gap: ${space.sm};
    background: transparent;
    cursor: pointer;
    align-items: center;

    svg {
      flex-shrink: 0;
    }
    ${getButtonStyles('pill')};
  `};
`;

const StyledInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const StyledFileList = styled.ul`
  ${({ theme: { space } }) => css`
    display: flex;
  `};
`;

export const FileUpload: FC<InputProps> = forwardRef(({ id, label, setFiles, ...rest }, ref) => {
  const [uploadedFiles, setUploadedFiled] = useState([]);
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve(
          reader.result
            .replace('data:application/pdf;base64,', '')
            .replace('data:text/plain;base64,', '')
            .replace('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,', '')
        );
      reader.onerror = (error) => reject(error);
    });

  const handleOnChange = (event: any) => {
    if (setFiles) {
      const files = [];
      const inputFiles = Array.from(event.target.files);
      if (inputFiles && inputFiles.length > 0) {
        setUploadedFiled(inputFiles);
        inputFiles.forEach(async (file) => {
          const { name, type } = file as File;
          files.push({
            base64File: await toBase64(file),
            extension: name.split('.')[1],
            fileName: name.split('.')[0],
          });
        });
      }
      setFiles(files);
    }
  };

  return (
    <>
      <StyledFileUpload>
        <StyledLabel htmlFor={id}>
          <Attach />
          {label}
        </StyledLabel>
        <StyledInput id={id} name={id} onChange={handleOnChange} type="file" multiple {...rest} ref={ref} />
        <Typography component="p" variant="smallprint">
          PDF, DOC, DOCX
          <br />
          Max size 8MB
        </Typography>
      </StyledFileUpload>
      {uploadedFiles.length > 0 && (
        <StyledFileList>
          {uploadedFiles.map(({ name }, index) => (
            <Typography component="li" variant="smallprint" key={index}>
              {name}
              {uploadedFiles.length > 0 && uploadedFiles.length !== index + 1 ? ', ' : ''}
            </Typography>
          ))}
        </StyledFileList>
      )}
    </>
  );
});
