import React, { createContext, FC, useState } from 'react';
import { Kfh_PropertyTypeInterface } from '../types/graphqlTypes';
import { isBrowser } from '../utils';

interface RecentlyViewedPropertiesContextProps {
  recentlyViewedProperties?: Kfh_PropertyTypeInterface[];
  setRecentlyViewedProperties: React.Dispatch<React.SetStateAction<Kfh_PropertyTypeInterface[]>>
}
export const RecentlyViewedPropertiesContext = createContext({} as RecentlyViewedPropertiesContextProps);
RecentlyViewedPropertiesContext.displayName = 'RecentlyViewedContext';

export const RecentlyViewedPropertiesProvider: FC<React.PropsWithChildren<any>> = ({ children }) => {
  const storedRecentlyViewedProperties = isBrowser
    ? JSON.parse(localStorage.getItem('recentlyViewedProperties') as string)
    : [];
  const [recentlyViewedProperties, setRecentlyViewedProperties] = useState<Kfh_PropertyTypeInterface[]>(storedRecentlyViewedProperties || []);
  const contextValues: RecentlyViewedPropertiesContextProps = { recentlyViewedProperties, setRecentlyViewedProperties };
  return (
    <RecentlyViewedPropertiesContext.Provider value={contextValues}>
      {children}
    </RecentlyViewedPropertiesContext.Provider>
  );
};
