import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Minus: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M26.286 13.429H5.714c-.946 0-1.714.767-1.714 1.714v1.714c0 .947.768 1.714 1.714 1.714h20.572c.946 0 1.714-.767 1.714-1.714v-1.714c0-.947-.768-1.714-1.714-1.714Z"
      />
    </g>
  </Icon>
);
