import { isBrowser } from './isBrowser';

export const updateCallTracking = () => {
  // Call tracking
  if (isBrowser && window?._its) {
    setTimeout(() => {
      window._its('track');
    }, 50);
  }
};
