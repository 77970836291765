import React from 'react';
import { Typography } from '../Typography';

export const SmallPrint = () => (
  <>
    <Typography variant="smallprint" component="p">
      By submitting this form, you confirm that you agree to our website{' '}
      <a href="/terms-of-use" target="_blank">
        terms and conditions
      </a>
      ,{' '}
      <a href="/privacy-policy" target="_blank">
        privacy policy
      </a>{' '}
      and{' '}
      <a href="/privacy-policy" target="_blank">
        cookie policy
      </a>
      .
      <br />
      This site is protected by reCAPTCHA and the Google{' '}
      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>{' '}
      and{' '}
      <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>{' '}
      apply.
    </Typography>
  </>
);
