import React, { FC } from 'react';
import styled from 'styled-components';
import { StyledPanel } from './Panel';

export interface PanelGroupProps {
  children: any;
  as?: 'div' | 'section' | 'ul' | 'ol';
  id?: string;
}

const StyledPanelGroup = styled.div`
  ${StyledPanel} + ${StyledPanel} {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  ${StyledPanel}:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const PanelGroup: FC<React.PropsWithChildren<PanelGroupProps>> = ({ children, as, id, ...rest }) => (
  <StyledPanelGroup {...rest} as={as} id={id}>
    {children}
  </StyledPanelGroup>
);
