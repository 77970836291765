import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Play: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 0C7.161 0 0 7.161 0 16s7.161 16 16 16 16-7.161 16-16S24.839 0 16 0Zm7.465 17.548L12.11 24.065c-1.02.567-2.304-.162-2.304-1.355V9.29c0-1.187 1.278-1.922 2.304-1.355l11.355 6.904c1.058.593 1.058 2.122 0 2.71Z"
    />
  </Icon>
);
