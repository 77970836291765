import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Edit: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="m17.629 8.37 6 6L10.6 27.402l-5.35.591a1.125 1.125 0 0 1-1.242-1.243l.595-5.354L17.63 8.37Zm9.712-.893-2.818-2.818a2.251 2.251 0 0 0-3.183 0l-2.65 2.65 6 6.002 2.65-2.651a2.251 2.251 0 0 0 0-3.183Z"
      />
    </g>
  </Icon>
);
