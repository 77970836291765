import React, { FC, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { ThemeColorsKeys } from '../styles/tokens/colors';
import { hideOnPrint } from '../styles/utils';

interface IconButtonProps {
  a11yTitle: string;
  id?: string;
  className?: string;
  onClick?: any;
  disabled?: boolean;
  type?: any;
  color?: ThemeColorsKeys;
  strikeThrough?: boolean;
  eventClickSection?: string;
}

interface IconButtonStyleProps {
  color?: ThemeColorsKeys;
  strikeThrough?: boolean;
}

const IconButtonStyles = styled.button<IconButtonStyleProps>`
  ${({ theme: { colors }, color, strikeThrough }) => css`
    background-color: transparent;
    cursor: pointer;

    ${hideOnPrint}

    ${color &&
    css`
      color: ${colors[color].default};
    `}

    ${strikeThrough &&
    css`
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        background-color: currentColor;
        width: 2px;
        height: 30px;
      }
    `}

    &:hover {
      color: ${colors.brand.default};
    }
  `};
`;

export const IconButton: FC<React.PropsWithChildren<IconButtonProps>> = forwardRef(
  (
    {
      children,
      a11yTitle,
      id,
      className,
      onClick,
      disabled = false,
      strikeThrough,
      type = 'button',
      color,
      eventClickSection,
      ...rest
    },
    ref
  ) => {
    if (!a11yTitle && !children) {
      return null;
    }

    const handleClick = () => {
      if (onClick) {
        onClick();
      }
    };

    return (
      <IconButtonStyles
        ref={ref}
        id={id}
        className={className}
        onClick={handleClick}
        aria-label={a11yTitle}
        disabled={disabled}
        aria-disabled={disabled}
        type={type}
        color={color}
        strikeThrough={strikeThrough}
        eventName="cta button"
        eventClickSection={eventClickSection}
        {...rest}
      >
        {children}
      </IconButtonStyles>
    );
  }
);
