import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const PinProperty: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <circle cx="16" cy="12" r="8" fill="#FFF" />
      <path
        fill="currentCOlor"
        d="M16 0c6.627 0 12 5.373 12 12 0 4.838-1.686 6.19-10.767 19.354a1.5 1.5 0 0 1-2.466 0C5.686 18.19 4 16.838 4 12 4 5.373 9.373 0 16 0Zm0 9.395-4.553 4.015c-.033.03-.076.045-.113.07v3.853c0 .184.149.333.333.333h2.666c.185 0 .334-.15.334-.333v-2.667c0-.184.149-.333.333-.333h2c.184 0 .333.149.333.333v2.667c0 .184.15.333.334.333h2.666c.184 0 .334-.15.334-.333V13.48c-.036-.024-.078-.038-.11-.067L16 9.395Zm-.556-2.18-5.333 4.708a.333.333 0 0 0-.026.47l.446.496a.333.333 0 0 0 .471.025L15.78 8.7a.333.333 0 0 1 .44 0l4.778 4.214a.333.333 0 0 0 .471-.025l.446-.496a.333.333 0 0 0-.026-.471l-1.222-1.08V8a.333.333 0 0 0-.334-.334H19a.333.333 0 0 0-.333.334v1.076l-2.11-1.862a.83.83 0 0 0-1.113 0Z"
      />
    </g>
  </Icon>
);
