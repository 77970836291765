import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ListView: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0-3h32v32H0z" />
      <path
        fill="currentColor"
        d="M5 20H1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1ZM5 0H1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1Zm0 10H1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Zm26 11H11a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Zm0-20H11a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Zm0 10H11a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z"
      />
    </g>
  </Icon>
);
