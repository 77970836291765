import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Filters: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0-2h32v32H0z" />
      <path
        fill="currentColor"
        d="M1.063 22h21v-1c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v1h5c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1h-5v1c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-1h-21c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1Zm0-10h5v-1c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v1h21c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1h-21v1c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-1h-5c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1Zm0-10h13V1c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v1h13c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1h-13v1c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1V6h-13c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1Z"
      />
    </g>
  </Icon>
);
