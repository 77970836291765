import React, { FC, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { LinkTo } from './LinkTo';
import { useDataLayerPush } from '../hooks/useDataLayerPush';
import { getUrlParts } from '../utils/getUrlParts';
import { CaretLeft } from './icon/icons/CaretLeft';
import { CaretRight } from './icon/icons/CaretRight';

export interface IconLinkProps {
  a11yTitle?: string;
  id?: string;
  className?: string;
  onClick?: any;
  target?: boolean;
  label?: string;
  disabled?: boolean;
  as?: any;
  url?: any;
  type?: string;
  arrowRight?: boolean;
  arrowLeft?: boolean;
  arrowLeftBack?: boolean;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  disabledDefaultStyling?: boolean;
  children?: any;
  hideLabelOnMobile?: boolean;
  state?: any;
  eventName?: string;
  eventClickSection?: string;
  eventClickBladeId?: string;
  topAlignIcon?: boolean;
  customDataLayerEvent?: object;
  disableClickTracking?: boolean;
}

interface IconLinkStylesProps {
  hideLabelOnMobile?: boolean;
  disabledDefaultStyling?: boolean;
  topAlignIcon?: boolean;
}

const IconLinkStyles = styled.a<IconLinkStylesProps>`
  ${({ theme: { space, colors, maxMedia }, hideLabelOnMobile, disabledDefaultStyling, topAlignIcon }) => css`
    display: inline-flex;
    gap: ${space.sm};
    background: transparent;
    cursor: pointer;
    align-items: center;

    span {
      position: relative;
    }

    svg {
      flex-shrink: 0;
    }

    // Top align icon

    ${topAlignIcon &&
    css`
      align-items: flex-start;

      svg {
        margin-top: ${space.xs};
      }
    `}

    // Default styling

    ${hideLabelOnMobile &&
    css`
      // Tablet < styles

      @media (max-width: ${maxMedia.md}) {
        span {
          display: none;
        }
      }
    `}

    // Default styling

    ${!disabledDefaultStyling &&
    css`
      color: ${colors.brand.default};
      text-decoration: none;

      &:hover,
      &.is-active {
        text-decoration: underline;
      }
    `}
  `};
`;

export const IconLink: FC<React.PropsWithChildren<IconLinkProps>> = forwardRef(
  (
    {
      children,
      a11yTitle,
      id,
      className,
      onClick,
      disabled = false,
      as,
      url,
      target,
      label,
      type,
      arrowRight,
      arrowLeft,
      arrowLeftBack,
      iconLeft,
      iconRight,
      disabledDefaultStyling,
      hideLabelOnMobile,
      state,
      eventName,
      eventClickSection,
      topAlignIcon,
      customDataLayerEvent,
      eventClickBladeId,
      disableClickTracking,
      ...rest
    },
    ref
  ) => {
    const { dataLayerPush } = useDataLayerPush();

    if (!label && !children) {
      return null;
    }

    const handleClick = (...props) => {
      if (!disableClickTracking) {
        dataLayerPush(
          customDataLayerEvent
            ? {
                ...customDataLayerEvent,
              }
            : {
                event: eventName || 'arrow link',
                clickText: label || children,
                clickSection: eventClickSection || undefined,
                clickBladeId: eventClickBladeId || undefined,
                ...getUrlParts(url as string),
              }
        );
      }

      if (onClick) {
        onClick(...props);
      }
    };

    const IconLeft = iconLeft;
    const IconRight = iconRight;

    return (
      <IconLinkStyles
        ref={ref}
        as={!url ? 'button' : LinkTo}
        id={id}
        className={className}
        to={url}
        onClick={handleClick}
        aria-label={a11yTitle}
        type={type}
        disabled={disabled}
        aria-disabled={disabled}
        target={target && '__blank'}
        disabledDefaultStyling={disabledDefaultStyling}
        {...rest}
        hideLabelOnMobile={hideLabelOnMobile}
        state={{ ...state }}
        topAlignIcon={topAlignIcon}
      >
        {arrowLeft && !arrowLeftBack ? <CaretRight /> : ''}
        {arrowLeft && arrowLeftBack ? <CaretLeft /> : ''}
        {iconLeft && !arrowLeft ? IconLeft : ''}
        <span>{label || children}</span>
        {arrowRight && <CaretRight />}
        {iconRight && !arrowRight ? IconRight : ''}
      </IconLinkStyles>
    );
  }
);
