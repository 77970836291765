import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { ImageCardFragment, ImageCardCustomFragment, ImageCardPagePickerFragment } from '../types/graphqlTypes';
import { ImageCard } from './ImageCard';
import { getPageType } from '../utils/getPageType';

interface ImageCardRendererProps extends ImageCardFragment {
  vertical?: boolean;
  small?: boolean;
  onClick?: ((e: any) => any) | undefined;
  disableClickTracking?: boolean;
  hideLearnMore?: boolean;
}

export const ImageCardRenderer: FC<Omit<ImageCardRendererProps, '__typename'>> = ({
  page,
  tag,
  title,
  text,
  image,
  link,
  small,
  vertical,
  disableClickTracking,
  hideLearnMore,
  onClick,
}) => {
  let cardHeading;
  let cardIntro;
  let cardActionLabel;
  let cardUrl;
  let cardImage;
  let cardTag;
  let cardTagColour;
  let cardNewTab;

  // Check if there is page - other wise use custom values

  if (page) {
    cardHeading = page.feed.shortTitle ? page.feed.shortTitle : page.pageName;
    cardIntro = page.feed.shortDescription;
    cardActionLabel = 'Learn more';
    cardUrl = page.relativeUrl;
    cardImage = page.feed.featuredImage;
    cardTag = getPageType(page?.layout?.remoteTypeName as string);
    cardTagColour = 'brand';
    cardNewTab = false;
  } else {
    cardHeading = title;
    cardIntro = text;
    cardActionLabel = link?.name ? link.name : undefined;
    cardUrl = link?.url;
    cardImage = image;
    cardTag = tag;
    cardTagColour = 'brand';
    cardNewTab = link?.target === '_blank';
  }

  return (
    <ImageCard
      heading={cardHeading}
      url={cardUrl}
      intro={cardIntro}
      actionLabel={cardActionLabel}
      image={cardImage}
      tag={cardTag}
      tagColour={cardTagColour}
      vertical={vertical}
      small={small}
      onClick={onClick}
      disableClickTracking={disableClickTracking}
      hideLearnMore={hideLearnMore}
      newTab={cardNewTab}
    />
  );
};

export const ImageCardPagePicker = graphql`
  fragment ImageCardPagePicker on KFH_UmbracoImageCardPagePickerComponentType {
    page {
      feed {
        featuredImage {
          localFile {
            gatsbyImage(width: 520, height: 544, quality: 30, layout: CONSTRAINED, cropFocus: CENTER)
          }
        }
        shortTitle
        shortDescription
      }
      pageName
      relativeUrl
      layout {
        remoteTypeName
      }
    }
  }
`;

export const ImageCardPagePickerSmall = graphql`
  fragment ImageCardPagePickerSmall on KFH_UmbracoImageCardPagePickerComponentType {
    page {
      feed {
        featuredImage {
          localFile {
            gatsbyImage(width: 320, height: 216, layout: CONSTRAINED, cropFocus: CENTER)
          }
        }
        shortTitle
      }
      pageName
      relativeUrl
      layout {
        remoteTypeName
      }
    }
  }
`;

export const ImageCardCustom = graphql`
  fragment ImageCardCustom on KFH_UmbracoImageCardCustomRepeaterComponentType {
    tag
    title
    text
    image {
      altText
      localFile {
        gatsbyImage(width: 520, height: 544, layout: CONSTRAINED, cropFocus: CENTER)
      }
    }
    link {
      url
      name
      target
    }
  }
`;

export const ImageCardCustomSmall = graphql`
  fragment ImageCardCustomSmall on KFH_UmbracoImageCardCustomRepeaterComponentType {
    tag
    title
    text
    image {
      altText
      localFile {
        gatsbyImage(width: 320, height: 216, layout: CONSTRAINED, cropFocus: CENTER)
      }
    }
    link {
      url
      name
      target
    }
  }
`;

export const SingleImageCardFragment = graphql`
  fragment ImageCard on KFH_UmbracoImageCardComponent {
    remoteTypeName
    ...ImageCardCustom
    ...ImageCardPagePicker
  }
`;
