import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Fullscreen: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M0 10.571V1.714C0 .764.764 0 1.714 0h8.857a.86.86 0 0 1 .858.857v2.857a.86.86 0 0 1-.858.857h-6v6a.86.86 0 0 1-.857.858H.857A.86.86 0 0 1 0 10.57ZM20.571.857v2.857a.86.86 0 0 0 .858.857h6v6a.86.86 0 0 0 .857.858h2.857A.86.86 0 0 0 32 10.57V1.714A1.71 1.71 0 0 0 30.286 0h-8.857a.86.86 0 0 0-.858.857Zm10.572 19.714h-2.857a.86.86 0 0 0-.857.858v6h-6a.86.86 0 0 0-.858.857v2.857a.86.86 0 0 0 .858.857h8.857A1.71 1.71 0 0 0 32 30.286v-8.857a.86.86 0 0 0-.857-.858ZM11.429 31.143v-2.857a.86.86 0 0 0-.858-.857h-6v-6a.86.86 0 0 0-.857-.858H.857A.86.86 0 0 0 0 21.43v8.857A1.71 1.71 0 0 0 1.714 32h8.857a.86.86 0 0 0 .858-.857Z"
      />
    </g>
  </Icon>
);
