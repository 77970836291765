import React, { FC, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { BaseFormProps, StyledBase } from './Base';
import { FormField, FormFieldProps } from './FormField';

export interface TextareaProps extends BaseFormProps, FormFieldProps {
  defaultValue?: string;
}

export const Textarea: FC<TextareaProps> = forwardRef(
  (
    {
      label,
      id,
      tooltipText,
      tooltipInfoIcon,
      valid,
      invalid,
      defaultValue,
      constrained,
      placeholder,
      required,
      errorMessage,
      hideLabel,
      ...rest
    },
    ref
  ) => (
    <FormField
      label={label}
      htmlFor={id}
      tooltipText={tooltipText}
      tooltipInfoIcon={tooltipInfoIcon}
      required={required}
      invalid={invalid}
      errorMessage={errorMessage}
      hideLabel={hideLabel}
      {...rest}
    >
      <StyledBase
        as="textarea"
        id={id}
        name={id}
        valid={valid}
        invalid={invalid}
        ref={ref}
        placeholder={placeholder}
        defaultValue={defaultValue}
        rows="6"
      />
    </FormField>
  )
);
