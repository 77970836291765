import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const MapView: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0-2h32v32H0z" />
      <path
        fill="currentColor"
        d="M16 0a7 7 0 0 0-7 7c0 3.126 4.575 8.822 6.328 10.89a.876.876 0 0 0 1.344 0C18.425 15.822 23 10.126 23 7a7 7 0 0 0-7-7Zm0 9.333a2.333 2.333 0 1 1 0-4.666 2.333 2.333 0 0 1 0 4.666ZM1.118 11.997A1.778 1.778 0 0 0 0 13.647v13.907a.889.889 0 0 0 1.219.826l7.67-3.491V11.94a16.824 16.824 0 0 1-1.18-2.579l-6.591 2.636ZM16 19.982a2.654 2.654 0 0 1-2.028-.943 58.682 58.682 0 0 1-3.305-4.262v10.111l10.666 3.556V14.778a58.648 58.648 0 0 1-3.305 4.262 2.656 2.656 0 0 1-2.028.942ZM30.781 8.953l-7.67 3.491v16l7.771-3.108A1.778 1.778 0 0 0 32 23.686V9.779a.889.889 0 0 0-1.219-.826Z"
      />
    </g>
  </Icon>
);
