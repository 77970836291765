import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Kfh_UmbracoButtonLinkType } from '../../../types/graphqlTypes';
import { Button } from '../../Button';
import { IconLink } from '../../IconLink';

interface LinkProps extends Kfh_UmbracoButtonLinkType {
  arrowLink?: boolean;
}

export const Link: FC<LinkProps> = ({ link, arrowLink, ...rest }) => {
  if (!link?.url) {
    return null;
  }
  return (
    <>
      {arrowLink ? (
        <IconLink url={link.url} target={link.target} {...rest} label={link.name} arrowRight />
      ) : (
        <Button url={link.url} target={link.target} {...rest} label={link.name} arrowRight />
      )}
    </>
  );
};

export const LinkQuery = graphql`
  fragment Link on KFH_UmbracoButtonLinkType {
    link {
      name
      target
      url
      type
    }
  }
`;
