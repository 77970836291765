import React from 'react';

import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { replaceSiteUrl } from './replaceSiteUrl';
import { Img } from '../components/Img';

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    // Handle sup character styling
    if (domNode.type === 'text' && domNode.data.includes('†') && domNode.parent?.name !== 'sup') {
      return <>{domNode.data.split('').map((char) => (char === '†' ? <sup>†</sup> : <>{char}</>))}</>;
    }

    // domNode instanceof Element &&

    if (domNode.name === 'img') {
      const { src, alt } = domNode.attribs;
      return <Img src={src} alt={alt} />;
    }
  },
};

export const createMarkup = (html) => {
  let checkHtml = html;
  if (!checkHtml) {
    return null;
  }
  if (Array.isArray(checkHtml)) {
    checkHtml = checkHtml.join('');
  }
  if (typeof checkHtml !== 'string') {
    return null;
  }

  // Remove empty <p> tags
  checkHtml = checkHtml.replace(/<p><\/p>/g, '');

  const videoWrapperDiv = {
    start: '<div class="video-wrapper">',
    end: '</div>',
  };
  const iframeRegex = /<iframe.*?<\/iframe>/g;

  const output: string = checkHtml.replace(iframeRegex, (match) => videoWrapperDiv.start + match + videoWrapperDiv.end);

  return parse(replaceSiteUrl(output), options);
};
