import React, { FC, Suspense } from 'react';
import Blade from '../Blade';
import { Loader } from '../Loader';

const RequestAValuationForm = React.lazy(() => import('./RequestAValuationForm'));

interface RequestAValuationBladeProps {
  enquiryType?: 'Sales' | 'Lettings';
}

export const RequestAValuationBlade: FC<RequestAValuationBladeProps> = ({ enquiryType }) => (
  <Blade width="narrow" id="propertyRequestAValuation" customBlade>
    <Suspense fallback={<Loader size="small" />}>
      <RequestAValuationForm enquiryType={enquiryType} />
    </Suspense>
  </Blade>
);

export default RequestAValuationBlade;
