import React, { createContext, FC, useState } from 'react';
import { Kfh_PropertyTypeInterface } from '../types/graphqlTypes';

interface ActivePropertyContextProps {
  activeProperty?: Kfh_PropertyTypeInterface;
  setActiveProperty: React.Dispatch<React.SetStateAction<Kfh_PropertyTypeInterface | undefined>>;
}
export const ActivePropertyContext = createContext({} as ActivePropertyContextProps);
ActivePropertyContext.displayName = 'ActivePropertyContext';

export const ActivePropertyProvider: FC<React.PropsWithChildren<any>> = ({ children }) => {
  const [activeProperty, setActiveProperty] = useState<Kfh_PropertyTypeInterface | undefined>();
  const contextValues: ActivePropertyContextProps = { activeProperty, setActiveProperty };
  return <ActivePropertyContext.Provider value={contextValues}>{children}</ActivePropertyContext.Provider>;
};
