import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ImageGallery: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0-2h32v32H0z" />
      <path
        fill="currentColor"
        d="M9.333 1.5v5a1.5 1.5 0 0 1-1.5 1.5H1.5A1.5 1.5 0 0 1 0 6.5v-5A1.5 1.5 0 0 1 1.5 0h6.333a1.5 1.5 0 0 1 1.5 1.5Zm11.334 15v-5a1.5 1.5 0 0 0-1.5-1.5h-6.334a1.5 1.5 0 0 0-1.5 1.5v5a1.5 1.5 0 0 0 1.5 1.5h6.334a1.5 1.5 0 0 0 1.5-1.5Zm2-15v5a1.5 1.5 0 0 0 1.5 1.5H30.5A1.5 1.5 0 0 0 32 6.5v-5A1.5 1.5 0 0 0 30.5 0h-6.333a1.5 1.5 0 0 0-1.5 1.5Zm-2 5v-5a1.5 1.5 0 0 0-1.5-1.5h-6.334a1.5 1.5 0 0 0-1.5 1.5v5a1.5 1.5 0 0 0 1.5 1.5h6.334a1.5 1.5 0 0 0 1.5-1.5ZM7.833 10H1.5A1.5 1.5 0 0 0 0 11.5v5A1.5 1.5 0 0 0 1.5 18h6.333a1.5 1.5 0 0 0 1.5-1.5v-5a1.5 1.5 0 0 0-1.5-1.5ZM0 21.5v5A1.5 1.5 0 0 0 1.5 28h6.333a1.5 1.5 0 0 0 1.5-1.5v-5a1.5 1.5 0 0 0-1.5-1.5H1.5A1.5 1.5 0 0 0 0 21.5ZM24.167 18H30.5a1.5 1.5 0 0 0 1.5-1.5v-5a1.5 1.5 0 0 0-1.5-1.5h-6.333a1.5 1.5 0 0 0-1.5 1.5v5a1.5 1.5 0 0 0 1.5 1.5Zm0 10H30.5a1.5 1.5 0 0 0 1.5-1.5v-5a1.5 1.5 0 0 0-1.5-1.5h-6.333a1.5 1.5 0 0 0-1.5 1.5v5a1.5 1.5 0 0 0 1.5 1.5Zm-12.834-6.5v5a1.5 1.5 0 0 0 1.5 1.5h6.334a1.5 1.5 0 0 0 1.5-1.5v-5a1.5 1.5 0 0 0-1.5-1.5h-6.334a1.5 1.5 0 0 0-1.5 1.5Z"
      />
    </g>
  </Icon>
);
