import React, { FC, forwardRef } from 'react';
import { ImageDataLike } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { ThemeBordersKeys } from '../styles/tokens/borders';
import { ThemeColorsKeys } from '../styles/tokens/colors';
import { ThemeShadowsKeys } from '../styles/tokens/shadows';
import { ThemeFontsKeys } from '../styles/tokens/typography';
import { getBackgroundColor } from '../utils';
import { Button, ButtonProps } from './Button';
import { Img } from './Img';
import { Typography } from './Typography';

export type PanelHeadingMarginSizes = 'default' | 'double';

export interface PanelProps {
  children: any;
  backgroundColor?: ThemeColorsKeys;
  border?: ThemeBordersKeys | boolean;
  shadow?: ThemeShadowsKeys | boolean;
  heading?: string;
  introCopy?: string;
  headingBorder?: boolean;
  buttonOne?: ButtonProps;
  buttonTwo?: ButtonProps;
  as?: any;
  iconImage?: ImageDataLike;
  headingComponent?: any;
  headingVariant?: ThemeFontsKeys;
  headingMargin?: PanelHeadingMarginSizes;
  noTopPadding?: boolean;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  method?: 'post' | 'get';
  noValidate?: boolean;
  id?: string;
  stretch?: boolean;
}

interface StyledPanelProps {
  backgroundColor?: ThemeColorsKeys;
  border?: ThemeBordersKeys | boolean;
  shadow?: ThemeShadowsKeys | boolean;
  noTopPadding?: boolean;
}

interface StyledHeadingProps {
  headingBorder: boolean;
  headingMargin?: PanelHeadingMarginSizes;
}

export const StyledPanel = styled.div<StyledPanelProps>`
  ${({ theme: { space, global, borders, shadows }, backgroundColor, border, shadow, noTopPadding, stretch }) => css`
    display: flex;
    flex-direction: column;
    padding: ${space.lg};
    border-radius: ${global.borderRadius.default};
    min-inline-size: auto;

    > *:last-child {
      margin-bottom: 0;
    }

    // remove top padding

    ${noTopPadding &&
    css`
      padding-top: 0;
    `}

    // Background and color

    ${backgroundColor && getBackgroundColor(backgroundColor)}

    // Border

    ${border &&
    css`
      border: ${typeof border === 'string' ? borders[border] : borders.default};
    `}

    // Shadow

    ${shadow &&
    css`
      box-shadow: ${typeof shadow === 'string' ? shadows[shadow] : shadows.default};
    `}

    // 100% height
    ${stretch &&
    css`
      height: 100%;
    `}
  `};
`;

const StyledHeading = styled(Typography)<StyledHeadingProps>`
  ${({ theme: { space, borders }, headingBorder, headingMargin }) => css`
    width: 90%;
    margin-bottom: ${space.md};

    // Margin size

    ${headingMargin === 'double' &&
    css`
      margin-bottom: ${space.lg};
    `}

    // Heading border

    ${headingBorder &&
    css`
      padding-bottom: ${space.md};
      border-bottom: ${borders.default};
    `}
  `};
`;

const StyledIntroCopy = styled(Typography)`
  ${({ theme: { space } }) => css`
    margin-bottom: ${space.lg};
  `};
`;

const StyledBody = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `};
`;

const StyledButton = styled(Button)``;

const StyledButtons = styled.div`
  ${({ theme: { space } }) => css`
    margin-top: ${space.lg};

    ${StyledButton} + ${StyledButton} {
      margin-top: ${space.sm};
    }
  `};
`;

const StyledIcon = styled.div`
  ${({ theme: { space } }) => css`
    margin-bottom: ${space.md};
    max-width: 72px;
  `};
`;

// JSX

export const Panel: FC<React.PropsWithChildren<PanelProps>> = forwardRef(
  (
    {
      children,
      backgroundColor,
      border,
      shadow,
      iconImage,
      heading,
      introCopy,
      headingComponent = 'h3',
      headingVariant = 'titleMedium',
      buttonOne,
      buttonTwo,
      as,
      headingBorder,
      headingMargin,
      stretch,
      ...rest
    },
    ref
  ) => (
    <StyledPanel
      backgroundColor={backgroundColor}
      border={border}
      shadow={shadow}
      as={as}
      stretch={stretch}
      {...rest}
      ref={ref}
    >
      {iconImage && (
        <StyledIcon>
          <Img src={iconImage} />
        </StyledIcon>
      )}

      {heading && (
        <StyledHeading
          component={headingComponent}
          variant={headingVariant}
          headingBorder={headingBorder}
          headingMargin={headingMargin}
        >
          {heading}
        </StyledHeading>
      )}

      {introCopy && (
        <StyledIntroCopy component="p" variant="bodyLarge">
          {introCopy}
        </StyledIntroCopy>
      )}

      <StyledBody>{children}</StyledBody>
      {buttonOne || buttonTwo ? (
        <StyledButtons>
          {buttonOne && <StyledButton {...buttonOne} fullWidth />}
          {buttonTwo && <StyledButton {...buttonTwo} fullWidth />}
        </StyledButtons>
      ) : (
        ''
      )}
    </StyledPanel>
  )
);
