import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Underground: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="#C33"
        d="M16 8c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8m0 21C8.832 29 3 23.168 3 16S8.832 3 16 3s13 5.832 13 13-5.832 13-13 13"
      />
      <path fill="#09C" d="M1 19h30v-6H1z" />
    </g>
  </Icon>
);
