import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Pound: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        d="M24.875 21.571h-2.64a.696.696 0 0 0-.697.697v2.95h-7.11v-7.36h4.876A.696.696 0 0 0 20 17.16v-2.322a.696.696 0 0 0-.696-.696h-4.875v-3.689c0-1.872 1.425-3.313 3.586-3.313 1.373 0 2.662.668 3.346 1.094.299.187.69.12.91-.156l1.654-2.06a.696.696 0 0 0-.123-.992C22.85 4.31 20.73 3 17.907 3c-4.754 0-8.121 3.06-8.121 7.31v3.833H8.16a.696.696 0 0 0-.697.696v2.322c0 .384.312.696.697.696h1.625v7.429h-2.09a.696.696 0 0 0-.696.696v2.322c0 .384.312.696.696.696h17.179a.696.696 0 0 0 .696-.696v-6.036a.696.696 0 0 0-.696-.697Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </Icon>
);
