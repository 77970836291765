import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { createMarkup, getResponsiveType } from '../utils';
import { OneTrustCookieList } from './OneTrustCookieList';

interface HtmlProps {
  children?: any;
  styleLead?: boolean;
}

interface StyledHtmlProps {
  styleLead?: boolean;
}

const StyledHtml = styled.div`
  ${({ theme: { space, colors, global, borders }, styleLead }) => css<StyledHtmlProps>`
    // Spacing

    * + * {
      margin-top: ${space.md};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: ${space.lg};
    }

    ol,
    ul {
      padding-left: ${space.md};

      li + li {
        margin-top: ${space.sm};
      }
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0%;
    }

    // Links

    a:not([href^='tel:']) {
      color: ${colors.black.default};
    }

    // List styles

    ul {
      li {
        position: relative;
        &:before {
          content: '';
          display: block;
          background: ${colors.black.default};
          width: 6px;
          height: 6px;
          position: absolute;
          left: -16px;
          top: 10px;
        }
      }
    }

    // Typography

    h1 {
      ${getResponsiveType('displaySmall')}
    }

    h2 {
      ${getResponsiveType('titleLarge')}
    }

    h3 {
      ${getResponsiveType('titleMedium')}
    }

    h4 {
      ${getResponsiveType('titleSmall')}
    }

    h5,
    h6 {
      ${getResponsiveType('titleExtraSmall')}
    }

    .lead {
      ${getResponsiveType('lead')}
    }

    // Make first 'P' lead

    ${styleLead &&
    css`
      > p:first-child {
        ${getResponsiveType('lead')}
      }
    `};

    // Tables

    table {
      border-collapse: separate;
      border-spacing: 0;
      border-radius: ${global.borderRadius.default};
      width: 100%;
      margin: ${space.lg} 0;
      border-top: ${borders.default};

      td {
        padding: ${space.md};
        border-right: ${borders.default};
        ${getResponsiveType('bodySmall')}
        border-bottom: ${borders.default};
        background: #fff;

        &:first-child {
          border-left: ${borders.default};
        }
      }

      th {
        padding: ${space.md};
        border-right: 1px solid ${colors.grey1.default};
        background-color: ${colors.grey3.default};
        ${getResponsiveType('titleExtraSmall')}

        &:last-child {
          border-right: 1px solid ${colors.grey3.default};
        }
      }
    }

    //inline images
    img {
      max-width: 100%;
      height: auto;
    }

    // One trust cookie settings button

    .ot-sdk-show-settings {
      color: ${colors.white.default};
      background: ${colors.brand.default};
      border: solid 1px ${colors.brand.default};
      text-decoration: none;
      padding: ${space.md} ${space.lg};
      ${getResponsiveType('bodyLarge')}
      font-weight: 700;
      border-radius: ${global.borderRadius.default};

      &:hover {
        background: ${colors.brandDark.default};
        border: solid 1px ${colors.brandDark.default};
      }
    }
  `};
`;

const HtmlContent: FC<React.PropsWithChildren<HtmlProps>> = ({ children, styleLead, ...rest }) => (
  <>
    <StyledHtml styleLead={styleLead} {...rest}>
      {typeof children === 'string' ? createMarkup(children) : children}
    </StyledHtml>
  </>
);

export default HtmlContent;
