import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Mobile: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M20.222 3.111a4.227 4.227 0 0 1 4.222 4.222v17.334a4.227 4.227 0 0 1-4.222 4.222h-8.444a4.227 4.227 0 0 1-4.222-4.222V7.333a4.227 4.227 0 0 1 4.222-4.222h8.444Zm-4.222 24a.889.889 0 1 0 0-1.778.889.889 0 0 0 0 1.778Z"
    />
  </Icon>
);
