import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Kfh_UmbracoButtonLinkDownloadType } from '../../../types/graphqlTypes';
import { Button } from '../../Button';
import { Download } from '../../icon/icons/Download';
import { IconLink } from '../../IconLink';

interface LinkDownloadProps extends Kfh_UmbracoButtonLinkDownloadType {
  arrowLink?: boolean;
}

export const LinkDownload: FC<LinkDownloadProps> = ({ arrowLink, file, label, ...rest }) => (
  <>
    {arrowLink ? (
      <IconLink url={file} target="_blank" {...rest} label={label} iconRight={<Download />} />
    ) : (
      <Button url={file} target="_blank" {...rest} label={label} iconRight={<Download />} />
    )}
  </>
);

export const LinkDownloadQuery = graphql`
  fragment LinkDownload on KFH_UmbracoButtonLinkDownloadType {
    file
    label
  }
`;
