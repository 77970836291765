import { Kfh_PropertyTypeInterface } from '../types/graphqlTypes';

export interface PropertyCardProps {
  property?: Kfh_PropertyTypeInterface;
}

export const getCleanPropertyDetails = function (property?: Kfh_PropertyTypeInterface): PropertyCardProps {
  // Remove image urls from property data for GA events
  let cleanedPropertyDetails = property;

  if (typeof structuredClone !== 'undefined') {
    cleanedPropertyDetails = structuredClone(property);

    // Clean the id property so we only take the last part of the id
    if (cleanedPropertyDetails?.id && typeof cleanedPropertyDetails.id === 'string') {
      const idParts = cleanedPropertyDetails.id.split(':');
      cleanedPropertyDetails.id = idParts[idParts.length - 1];
    }

    delete cleanedPropertyDetails?.staticMapUrl;
    delete cleanedPropertyDetails?.staticMap;
    delete cleanedPropertyDetails?.images;
    delete cleanedPropertyDetails?.firstImage;
    delete cleanedPropertyDetails?.branch?.mediaSet;
    delete cleanedPropertyDetails?.branch?.businessProfile?.salesContactDetails?.photo;
    delete cleanedPropertyDetails?.branch?.businessProfile?.lettingsContactDetails?.photo;
    delete cleanedPropertyDetails?.area?.mediaSet;
    delete cleanedPropertyDetails?.area?.region?.mediaSet;
  }

  return cleanedPropertyDetails;
};
