import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const SliderRight: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <rect width="32" height="32" fill="#111" opacity=".32" rx="2" />
      <path d="M11 11h10.667v10.667H11z" />
      <path
        fill="#FFF"
        d="M15 19.008v-5.361c0-.372.449-.557.711-.295l2.68 2.68a.417.417 0 0 1 0 .59l-2.68 2.68a.417.417 0 0 1-.711-.294Z"
      />
    </g>
  </Icon>
);
