import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { Query, Kfh_CurrencyTypeEdge, Kfh_CurrencyType } from '../types/graphqlTypes';

interface UseCurrenciesReturn {
  currencies: Kfh_CurrencyTypeEdge[];
  current: Kfh_CurrencyType | undefined;
  handleSetCurrency: (currency: number) => void;
  currentName: string;
}
export const useCurrencies = (): UseCurrenciesReturn => {
  const { handleSetCurrency, currency } = useContext(UserContext);
  const { allKfhCurrencyType } = useStaticQuery<Query>(
    graphql`
      query GET_CURRENCIES {
        allKfhCurrencyType {
          edges {
            node {
              code
              rate
              id
              remoteId
              format
              inverseRate
              name
            }
          }
        }
      }
    `
  );

  const current =
    currency && allKfhCurrencyType?.edges.length >= 1
      ? allKfhCurrencyType?.edges.find(({ node }) => node.remoteId.toString() === currency.toString())
      : undefined;
  return {
    currencies: allKfhCurrencyType?.edges,
    handleSetCurrency,
    current: current?.node,
    currentName: current?.node?.name || '',
  };
};
