import React, { FC } from 'react';
import styled, { css } from 'styled-components';

export interface DividerProps {
  minimal?: boolean;
}

const StyledDivider = styled.hr<DividerProps>`
  ${({ theme: { colors, space, media }, minimal }) => css`
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: ${colors.grey3.default};

    ${minimal &&
    css`
      max-width: 96px;
      margin-left: auto;
      margin-right: auto;
    `}
  `};
`;

export const Divider: FC<React.PropsWithChildren<DividerProps>> = ({ children, minimal, ...rest }) => (
  <StyledDivider minimal={minimal} {...rest} />
);
