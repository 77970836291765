import React, { useContext, useState, FC, useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
import { globalHistory } from '@reach/router';
import { Container } from './Container';
import { IconButton } from './IconButton';
import { StyledLinkList } from './LinkList';
import { Overlay } from './Overlay';
import { Panel } from './Panel';
import KfhLogo from './svg/KfhLogo';
import MyKfhLogo from './svg/MyKfhLogo';
import { Typography } from './Typography';
import { PanelGroup } from './PanelGroup';
import { Spacer } from './Spacer';
import { IconLink } from './IconLink';
import { Divider } from './Divider';
import { MY_KFH_ROUTES } from '../urls';
import { UserContext } from '../context/UserContext';
import { useLogout } from '../hooks/useAuth';
import { ImageCardRenderer } from './ImageCardRenderer';
import { useDataLayerPush } from '../hooks/useDataLayerPush';
import { getUrlParts } from '../utils/getUrlParts';
import { Avatar } from './icon/icons/Avatar';
import { CaretDown } from './icon/icons/CaretDown';
import { Close } from './icon/icons/Close';
import { Menu } from './icon/icons/Menu';
import { isBrowser } from '../utils/isBrowser';

// Interfaces

interface StyledPrimaryNavLinkProps {
  $dropdownIsActive: boolean;
}

interface MyKfhDropdownSectionProps {
  heading: string;
  text: string;
  url: string;
  myKfh?: boolean;
}

interface MainMenuItemProp {
  link: any;
  menuItems?: any;
  imageCards?: any;
  setDropdownActive?: any;
  menuTitle?: any;
  viewAllLink?: any;
}

// Styles

const StyledMobileContainer = styled(Container)`
  ${({ theme: { space, media } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${space.md};
    padding-bottom: ${space.md};

    // Desktop styles

    @media (min-width: ${media.lg}) {
      display: none;
    }
  `};
`;

const StyledMobileNav = styled.div`
  ${({ theme: { space, colors, layers } }) => css`
    padding: ${space.lg};
    background: ${colors.white.default};
    position: fixed;
    top: 64px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 64px);
    z-index: ${layers.headerDropdown};
    overflow-y: auto;

    a {
      text-decoration: none;
    }
  `};
`;

const StyledDesktopContainer = styled.div`
  ${({ theme: { maxMedia } }) => css`
    // Mobile

    @media (max-width: ${maxMedia.lg}) {
      display: none;
    }
  `};
`;

const StyledSecondaryNav = styled.div`
  ${({ theme: { colors, space } }) => css`
    background-color: ${colors.black.default};
    padding-top: ${space.sm};
    padding-bottom: ${space.sm};
  `};
`;

const StyledPrimaryNav = styled(Container)`
  ${({ theme: { space } }) => css`
    padding-top: ${space.lg};
    padding-bottom: ${space.lg};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `};
`;

const StyledLogo = styled(KfhLogo)`
  ${({ theme: { space, media } }) => css`
    width: 224px;

    // Desktop styles

    @media (min-width: ${media.lg}) {
      width: 280px;
    }
  `};
`;

const StyledSecondaryNavLinks = styled.ul`
  ${({ theme: { colors, space } }) => css`
    display: flex;
    gap: ${space.md};
    justify-content: flex-end;

    li {
      display: grid;
      place-items: center;

      &:last-child {
        border-left: solid 1px rgba(255, 255, 255, 0.16);
        padding-left: ${space.md};
      }
    }
  `};
`;

const StyledSecondaryNavLink = styled(Typography)`
  ${({ theme: { colors } }) => css`
    color: ${colors.white.default};
    text-decoration: none;
    line-height: 16px; // Prefered this to throw in a padding '5' to match the designs
  `};
`;

const StyledMyKfhDropdown = styled(PanelGroup)`
  ${({ theme: { maxMedia, media, layers } }) => css`
    // Shared styles

    right: 0;
    z-index: ${layers.headerMyKFHDropdown};
    overflow-y: auto;
    max-height: calc(100vh - 64px);
    // Mobile styles

    @media (max-width: ${maxMedia.lg}) {
      position: fixed;
      top: 64px;
      width: 100vw;
      height: calc(100vh - 64px);
      display: flex;
      flex-direction: column;
      border-radius: 0;
      > * {
        border-radius: 0;

        &:last-child {
          flex-grow: 1;
        }
      }
    }

    // Desktop styles

    @media (min-width: ${media.lg}) {
      position: absolute;
      width: 348px;
      height: auto;
      top: calc(100% + 24px);
    }
  `};
`;

const StyledMyKFHButton = styled(Typography)<StyledPrimaryNavLinkProps>`
  ${({ theme: { colors, space, layers }, $dropdownIsActive }) => css`
    color: ${colors.white.default};
    background: none;
    border: none;
    display: flex;
    align-items: center;

    ${$dropdownIsActive &&
    css`
      &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 4px 4px;
        border-color: transparent transparent ${colors.white.default} transparent;
        position: absolute;
        top: calc(100% + 22px);
        left: 50%;
        transform: translateY(-50%);
        z-index: ${layers.headerDropdown};
      }
    `};
  `};
`;

const StyledSecondaryNavItem = styled.li`
  ${({ theme: { colors } }) => css`
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 24px;
    }
  `};
`;

const StyledMyKfhLogo = styled(MyKfhLogo)`
  ${({ theme: { space } }) => css`
    margin: 0 ${space.xs} 0 ${space.sm};
  `};
`;

const StyledPrimaryNavLinks = styled.ul`
  ${({ theme: { colors, space } }) => css`
    display: flex;
    gap: ${space.lg};
  `};
`;

const StyledPrimaryDropdown = styled(Panel)<{ active: boolean }>`
  ${({ theme: { space, layers }, active }) => css`
    position: absolute;
    top: calc(100% + 56px);
    right: -145px;
    width: 736px;
    z-index: ${layers.headerDropdown};

    ${!active &&
    css`
      display: none;
    `}
  `};
`;

const StyledPrimaryDropdownInner = styled.div`
  ${({ theme: { space, layers } }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${space.lg};
  `};
`;
const StyledPrimaryMobileDropdown = styled(Spacer)`
  ${({ theme: { space } }) => css`
    padding-top: ${space.md};
  `};
`;

const StyledPrimaryNavLink = styled(Typography)<StyledPrimaryNavLinkProps>`
  ${({ theme: { space, colors, layers }, $dropdownIsActive }) => css`
    text-decoration: none;

    &.is-active {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: calc(100% + ${space.sm});
        left: 0;
        width: 100%;
        height: 2px;
        background: currentColor;
        z-index: ${layers.headerDropdown};
      }
    }

    // triangle

    ${$dropdownIsActive &&
    css`
      color: ${colors.brand.default};

      &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 4px 4px;
        border-color: transparent transparent ${colors.white.default} transparent;
        position: absolute;
        top: calc(100% + 54px);
        left: 50%;
        transform: translateY(-50%);
        z-index: ${layers.headerDropdown};
      }
    `};

    &:hover {
      color: ${colors.brand.default};
    }
  `};
`;
const StyledPrimaryMobileNavLink = styled(Typography)<StyledPrimaryNavLinkProps>`
  ${({ theme: { space, colors, layers }, $dropdownIsActive }) => css`
    text-decoration: none;
  `};
`;
const StyledPrimaryMobileNavLinkLabel = styled(Link)<any>`
  ${({ theme: { space, colors, layers }, $dropdownIsActive }) => css`
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // triangle

    ${$dropdownIsActive &&
    css`
      color: ${colors.brand.default};

      .caret {
        transform: rotate(180deg);
      }
    `};
  `};
`;

const StyledPrimaryNavItem = styled.li`
  ${({ theme: { colors } }) => css`
    position: relative;
  `};
`;

const StyledPrimaryNavOverlay = styled(Overlay)<{ active: boolean }>`
  ${({ theme: { colors }, active }) => css`
    position: absolute;
    top: 136px;
    height: 100%;

    ${!active &&
    css`
      display: none;
    `}
  `};
`;

const StyledMyKfhDropdownOverlay = styled(Overlay)<{ active: boolean }>`
  ${({ theme: { colors }, active }) => css`
    position: absolute;
    top: 32px;
    height: 100%;

    ${!active &&
    css`
      display: none;
    `}
  `};
`;

const StyledMyKfhDropdownPanel = styled(Panel)`
  ${({ theme: { space } }) => css`
    padding-bottom: ${space.xl};
  `};
`;

const StyledMyKfhDropdownSectionHeading = styled(Typography)`
  ${({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.sm};
  `};
`;

const StyledPrimaryDropdownLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledPrimaryDropdownViewAll = styled(IconLink)`
  ${({ theme: { space, borders } }) => css`
    border-top: ${borders.default};
    padding: ${space.md} 0;
    margin-top: ${space.lg};
  `};
`;

const StyledMyKFHLink = styled(IconLink)`
  ${({ theme: { space, borders } }) => css`
    width: 100%;
  `};
`;

const MyKfhDropdownSection: FC<MyKfhDropdownSectionProps> = ({ heading, text, url, myKfh }) => {
  const { user } = useContext(UserContext);
  const { dataLayerPush } = useDataLayerPush();
  const signout = useLogout();

  // handle  the logout by calling the function then redirecting the user
  const handleLogout = async () => {
    await signout();

    dataLayerPush({
      event: 'myKFH navigation',
      clickText: 'Sign out',
      clickBladeId: undefined,
      ...getUrlParts(undefined),
    });
  };

  return (
    <Spacer spacing="half">
      <StyledMyKfhDropdownSectionHeading component="h3" variant="titleMedium">
        {myKfh && <Avatar size="default" />}
        {heading}
      </StyledMyKfhDropdownSectionHeading>
      <p>{text}</p>
      {!myKfh && (
        <IconLink
          url={url}
          disableClickTracking
          onClick={() =>
            dataLayerPush({
              event: 'myKFH navigation',
              clickText: `${heading} log in / Register`,
              clickBladeId: undefined,
              ...getUrlParts(url),
            })
          }
          arrowRight
        >
          Log in / Register
        </IconLink>
      )}

      {myKfh && user && (
        <Spacer spacing="half">
          <StyledMyKFHLink
            url={MY_KFH_ROUTES.dashboard}
            onClick={() =>
              dataLayerPush({
                event: 'myKFH navigation',
                clickText: `View your dashboard`,
                clickBladeId: undefined,
                ...getUrlParts(MY_KFH_ROUTES.dashboard),
              })
            }
            arrowRight
          >
            View your dashboard
          </StyledMyKFHLink>
          <StyledMyKFHLink onClick={handleLogout}>Sign out</StyledMyKFHLink>
        </Spacer>
      )}

      {myKfh && !user && (
        <IconLink
          url={url}
          disableClickTracking
          onClick={() =>
            dataLayerPush({
              event: 'myKFH navigation',
              clickText: `${heading} log in / Register`,
              clickBladeId: undefined,
              ...getUrlParts(url),
            })
          }
          arrowRight
        >
          Log in / Register
        </IconLink>
      )}
    </Spacer>
  );
};

const MyKfhDropdown = () => {
  const { user } = useContext(UserContext);
  return (
    <StyledMyKfhDropdown suppressHydrationWarning>
      <Panel backgroundColor="white">
        <MyKfhDropdownSection
          heading="MyKFH"
          text="Manage your property search with MyKFH."
          url={user ? MY_KFH_ROUTES.dashboard : MY_KFH_ROUTES.login}
          icon
          myKfh
        />
      </Panel>
      <StyledMyKfhDropdownPanel backgroundColor="grey1">
        <Spacer>
          <MyKfhDropdownSection
            heading="Block Management Resident Portal"
            text="24/7 block management resident portal."
            url="https://propman.kfh.co.uk/scripts/cgiip.exe/WService=wsPropman/wp-login.p?app=twp&_gl=1*wpa9jz*_ga*MTAwMzI4OTQzOS4xNjY0MjY5Nzgy*_ga_HSKRZEY2FZ*MTY2NjExNDE1OS41OS4xLjE2NjYxMTQzOTkuMC4wLjA.&_ga=2.154970488.1180850585.1665992990-1003289439.1664269782"
          />
          <Divider />
          <MyKfhDropdownSection
            heading="Tenant Portal"
            text="Manage your tenancy with our dedicated online portal."
            url="https://tenant.kfh.co.uk/login?_ga=2.205125008.1871600603.1648541483-245512541.1622023229"
          />
          <Divider />
          <MyKfhDropdownSection
            heading="Landlord Portal"
            text="Manage your portfolio with our dedicated online portal."
            url="https://landlord.kfh.co.uk/?_ga=2.235534977.1871600603.1648541483-245512541.1622023229"
          />
        </Spacer>
      </StyledMyKfhDropdownPanel>
    </StyledMyKfhDropdown>
  );
};

const MobileMainMenuItem: FC<MainMenuItemProp> = ({ link, menuItems, viewAllLink, menuTitle }) => {
  const hasDropdown = !!(menuItems && menuItems.length > 0);
  const [menuIsActive, setMenuIsActive] = useState(false);
  const { dataLayerPush } = useDataLayerPush();

  return (
    <StyledPrimaryMobileNavLink $dropdownIsActive={menuIsActive} component="li" variant="titleMedium">
      <StyledPrimaryMobileNavLinkLabel
        $dropdownIsActive={menuIsActive}
        to={hasDropdown ? '#' : link.url}
        onClick={(e) => {
          if (hasDropdown) {
            e.preventDefault();
            setMenuIsActive((active) => !active);
          }
          dataLayerPush({
            event: 'primary navigation',
            clickText: link.name,
            ...getUrlParts(link.url as string),
            clickBladeId: undefined,
            mobileNavigation: true,
          });
        }}
      >
        <span>{link.name}</span>
        {hasDropdown && <CaretDown className="caret" />}
      </StyledPrimaryMobileNavLinkLabel>

      {hasDropdown && menuIsActive && (
        <StyledPrimaryMobileDropdown>
          <Spacer as="ul" spacing="quarter">
            <>
              {menuItems.map(({ name, url }, index) => (
                <Typography component="li" variant="bodyLarge" key={`mtm-${index}`}>
                  <Link
                    to={url}
                    onClick={() =>
                      dataLayerPush({
                        event: 'secondary navigation',
                        clickText: name,
                        clickBladeId: undefined,
                        ...getUrlParts(url as string),
                        mobileNavigation: true,
                      })
                    }
                  >
                    {name}
                  </Link>
                </Typography>
              ))}
              {viewAllLink && (
                <Typography component="li" variant="bodyLarge">
                  <Link
                    url={viewAllLink.url}
                    disableClickTracking
                    onClick={() =>
                      dataLayerPush({
                        event: 'primary navigation',
                        clickText: viewAllLink.name,
                        ...getUrlParts(viewAllLink.url as string),
                        clickBladeId: undefined,
                        navigationHeader: menuTitle,
                        clickSection: menuTitle,
                        clickType: 'arrow link',
                      })
                    }
                  >
                    {viewAllLink.name}
                  </Link>
                </Typography>
              )}
            </>
          </Spacer>
        </StyledPrimaryMobileDropdown>
      )}
    </StyledPrimaryMobileNavLink>
  );
};
const MainMenuItem: FC<MainMenuItemProp> = ({
  link,
  menuItems,
  imageCards,
  setDropdownActive,
  viewAllLink,
  menuTitle,
}) => {
  const { dataLayerPush } = useDataLayerPush();
  const hasDropdown = !!(menuItems && menuItems.length > 0);
  const [menuIsActive, setMenuIsActive] = useState(false);
  let timer: number;

  const handleMouseEnter = () => {
    window.clearTimeout(timer);
    if (setDropdownActive && hasDropdown) {
      setDropdownActive(true);
      setMenuIsActive(true);
    }
  };

  const handleMouseExit = () => {
    timer = window.setTimeout(() => {
      if (setDropdownActive && hasDropdown) {
        setDropdownActive(false);
        setMenuIsActive(false);
      }
    }, 300);
  };

  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === 'PUSH') {
          setMenuIsActive(false);
        }
      }),
    [setMenuIsActive]
  );

  return (
    <StyledPrimaryNavItem onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseExit}>
      <StyledPrimaryNavLink
        component={Link}
        to={link.url}
        variant="titleSmall"
        onClick={() =>
          dataLayerPush({
            event: 'primary navigation',
            clickText: link.name,
            ...getUrlParts(link.url as string),
            clickBladeId: undefined,
            clickSection: link.name,
            clickType: 'header',
          })
        }
        $dropdownIsActive={hasDropdown && menuIsActive}
        activeClassName="is-active"
        partiallyActive
      >
        {link.name}
      </StyledPrimaryNavLink>
      {hasDropdown ? (
        <StyledPrimaryDropdown shadow backgroundColor="white" active={menuIsActive}>
          <StyledPrimaryDropdownInner>
            <StyledPrimaryDropdownLinks>
              <Spacer spacing="half">
                {menuTitle && (
                  <Typography component="h3" variant="titleSmall">
                    {menuTitle}
                  </Typography>
                )}
                <StyledLinkList>
                  {menuItems.map(({ url, name }, index) => (
                    <li key={`mi-${index}`}>
                      <IconLink
                        disableClickTracking
                        arrowRight
                        url={url}
                        label={name}
                        onClick={() =>
                          dataLayerPush({
                            event: 'primary navigation',
                            clickText: name,
                            ...getUrlParts(url as string),
                            clickBladeId: undefined,
                            clickSection: menuTitle,
                            navigationHeader: menuTitle,
                            clickType: 'arrow link',
                          })
                        }
                      />
                    </li>
                  ))}
                </StyledLinkList>
              </Spacer>

              {viewAllLink && (
                <StyledPrimaryDropdownViewAll
                  label={viewAllLink.name}
                  arrowRight
                  url={viewAllLink.url}
                  disableClickTracking
                  onClick={() =>
                    dataLayerPush({
                      event: 'primary navigation',
                      clickText: viewAllLink.name,
                      ...getUrlParts(viewAllLink.url as string),
                      clickBladeId: undefined,
                      navigationHeader: menuTitle,
                      clickSection: menuTitle,
                      clickType: 'arrow link',
                    })
                  }
                />
              )}
            </StyledPrimaryDropdownLinks>
            {imageCards?.length > 0 ? (
              <Spacer>
                {imageCards && imageCards.length > 0 ? (
                  <>
                    {imageCards.map((imageCard, index) => (
                      <ImageCardRenderer
                        {...imageCard}
                        key={`ic-${index}`}
                        small
                        disableClickTracking
                        onClick={() =>
                          dataLayerPush({
                            event: 'primary navigation',
                            clickText: imageCard.page ? imageCard.page.pageName : imageCard.title,
                            ...getUrlParts(imageCard.page ? imageCard.page.relativeUrl : (imageCard.url as string)),
                            navigationHeader: menuTitle,
                            clickSection: menuTitle,
                            clickBladeId: undefined,
                            clickType: 'image card',
                          })
                        }
                      />
                    ))}
                  </>
                ) : (
                  ''
                )}
              </Spacer>
            ) : (
              ''
            )}
          </StyledPrimaryDropdownInner>
        </StyledPrimaryDropdown>
      ) : (
        ''
      )}
    </StyledPrimaryNavItem>
  );
};

export const Header = () => {
  const [primaryNavDropdownIsActive, setPrimaryNavDropdownIsActive] = useState(false);
  const [myKfhDropdownIsActive, setMyKfhDropdownIsActive] = useState(false);
  const [mobileNavIsActive, setMobileNavIsActive] = useState(false);
  const { dataLayerPush } = useDataLayerPush();
  const { user } = useContext(UserContext);

  const { headerData } = useStaticQuery(graphql`
    query headerQuery {
      headerData: kfhContentGlobalHeaderUmbracoType {
        topMenu {
          name
          url
        }
        mainMenu {
          imageCards {
            ...ImageCardCustomSmall
            ...ImageCardPagePickerSmall
          }
          link {
            name
            url
          }
          viewAllLink {
            url
            name
          }
          menuTitle
          menuItems {
            name
            url
          }
        }
      }
    }
  `);

  const { topMenu, mainMenu } = headerData;

  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === 'PUSH') {
          setMobileNavIsActive(false);
          setMyKfhDropdownIsActive(false);
          setPrimaryNavDropdownIsActive(false);
        }
      }),
    [setMobileNavIsActive, setMyKfhDropdownIsActive]
  );

  useEffect(() => {
    if (isBrowser) {
      const { body } = document;
      const scrollbarWidth = window.innerWidth - body.clientWidth;
      if (mobileNavIsActive || primaryNavDropdownIsActive || myKfhDropdownIsActive) {
        body.style.paddingRight = `${scrollbarWidth}px`;
        body.classList.add('is-hidden');
      } else {
        body.style.paddingRight = '0px';
        body.classList.remove('is-hidden');
      }
    }
  }, [mobileNavIsActive, myKfhDropdownIsActive, primaryNavDropdownIsActive]);

  // MyKFH dropdown

  let timer = null;

  const handleMouseEnterMyKFH = () => {
    clearTimeout(timer);
    setMyKfhDropdownIsActive(true);
  };

  const handleMouseExitMyKFH = () => {
    timer = setTimeout(() => {
      setMyKfhDropdownIsActive(false);
    }, 300);
  };

  return (
    <>
      <header>
        {/* Mobile container */}
        <StyledMobileContainer>
          {mobileNavIsActive || myKfhDropdownIsActive ? (
            <IconButton
              a11yTitle="Close navigation"
              onClick={() => {
                setMobileNavIsActive(false);
                setMyKfhDropdownIsActive(false);
                dataLayerPush({
                  event: 'mobile navigation',
                  eventType: 'closed',
                });
              }}
            >
              <Close size="medium" />
            </IconButton>
          ) : (
            <IconButton
              a11yTitle="Open navigation"
              onClick={() => {
                setMobileNavIsActive(true);
                dataLayerPush({
                  event: 'mobile navigation',
                  eventType: 'opened',
                });
              }}
            >
              <Menu size="medium" />
            </IconButton>
          )}
          {mobileNavIsActive && (
            <StyledMobileNav>
              <Spacer as="nav">
                <Spacer as="ul" spacing="half">
                  {mainMenu.map((props, index) => (
                    <MobileMainMenuItem {...props} key={index} />
                  ))}
                </Spacer>
                <Divider />
                <Spacer as="ul" spacing="half">
                  {topMenu.map(({ name, url }, index) => (
                    <li key={`tm-${index}`}>
                      <Link
                        to={url}
                        onClick={() =>
                          dataLayerPush({
                            event: 'secondary navigation',
                            clickText: name,
                            clickBladeId: undefined,
                            ...getUrlParts(url as string),
                            mobileNavigation: true,
                          })
                        }
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link to="/">MyKFH</Link>
                  </li>
                </Spacer>
              </Spacer>
            </StyledMobileNav>
          )}
          <Link
            to="/"
            onClick={() =>
              dataLayerPush({
                event: 'header logo click',
                clickText: 'MyKFH',
                clickBladeId: undefined,
                ...getUrlParts('/' as string),
              })
            }
          >
            <StyledLogo />
          </Link>
          {mobileNavIsActive || myKfhDropdownIsActive ? (
            <Avatar size="medium" color="white" />
          ) : (
            <IconButton a11yTitle="Open myKfh menu" onClick={() => setMyKfhDropdownIsActive(true)}>
              <Avatar size="medium" />
            </IconButton>
          )}
          {myKfhDropdownIsActive && <MyKfhDropdown />}
        </StyledMobileContainer>

        {/* Desktop container */}
        <StyledDesktopContainer>
          {/* Secondary nav */}
          <StyledSecondaryNav>
            <Container>
              <nav>
                <StyledSecondaryNavLinks>
                  {topMenu.map(({ name, url }, index) => (
                    <StyledSecondaryNavItem key={`tmd-${index}`}>
                      <StyledSecondaryNavLink
                        component={Link}
                        variant="bodySmall"
                        to={url}
                        onClick={() =>
                          dataLayerPush({
                            event: 'secondary navigation',
                            clickText: name,
                            clickBladeId: undefined,
                            ...getUrlParts(url as string),
                            mobileNavigation: false,
                          })
                        }
                      >
                        {name}
                      </StyledSecondaryNavLink>
                    </StyledSecondaryNavItem>
                  ))}
                  <StyledSecondaryNavItem onMouseEnter={handleMouseEnterMyKFH} onMouseLeave={handleMouseExitMyKFH}>
                    <StyledMyKFHButton
                      aria-label="Toggle MyKFH"
                      component={Link}
                      to={user ? MY_KFH_ROUTES.dashboard : MY_KFH_ROUTES.login}
                      variant="bodySmall"
                      onClick={() =>
                        dataLayerPush({
                          event: 'secondary navigation',
                          clickText: 'MyKFH log in',
                          clickBladeId: undefined,
                          ...getUrlParts(MY_KFH_ROUTES.login),
                          mobileNavigation: false,
                        })
                      }
                      $dropdownIsActive={myKfhDropdownIsActive}
                    >
                      <Avatar />
                      <StyledMyKfhLogo suppressHydrationWarning />
                      <CaretDown />
                    </StyledMyKFHButton>
                    {myKfhDropdownIsActive && <MyKfhDropdown />}
                  </StyledSecondaryNavItem>
                </StyledSecondaryNavLinks>
              </nav>
            </Container>
          </StyledSecondaryNav>

          {/* Primary nav */}

          <StyledPrimaryNav>
            <Link
              to="/"
              aria-label="Kinleigh Folkard & Hayward"
              onClick={() =>
                dataLayerPush({
                  event: 'header logo click',
                  clickText: 'MyKFH',
                  clickBladeId: undefined,
                  ...getUrlParts('/' as string),
                })
              }
            >
              <StyledLogo />
            </Link>
            <nav>
              <StyledPrimaryNavLinks>
                {mainMenu.map(({ link, menuItems, imageCards, menuTitle, viewAllLink }, index) => (
                  <MainMenuItem
                    link={link}
                    menuItems={menuItems}
                    key={`mm-pn-${index}`}
                    setDropdownActive={setPrimaryNavDropdownIsActive}
                    imageCards={imageCards}
                    menuTitle={menuTitle}
                    viewAllLink={viewAllLink}
                  />
                ))}
              </StyledPrimaryNavLinks>
            </nav>
          </StyledPrimaryNav>
          <StyledPrimaryNavOverlay active={primaryNavDropdownIsActive} />
          <StyledMyKfhDropdownOverlay active={myKfhDropdownIsActive} />
        </StyledDesktopContainer>
      </header>
    </>
  );
};
