import React, { useContext, useEffect, FC } from 'react';
import Blade from '../Blade';
import { Typography } from '../Typography';
import { Spacer } from '../Spacer';
import { UiStateContext } from '../../context/UiStateContext';
import { Login } from './Login';
import { Panel } from '../Panel';
import { UserContext } from '../../context/UserContext';

interface LoginBladeProps {}

export const LoginBlade: FC<LoginBladeProps> = () => {
  const { loading, user } = useContext(UserContext);
  const { setActiveBlade } = useContext(UiStateContext);

  useEffect(() => {
    if (!loading && user) {
      setActiveBlade(null);
    }
  }, [user, loading, setActiveBlade]);

  return (
    <Blade id="login">
      <Spacer>
        <Typography component="h2" variant="displaySmall">
          Log in to MyKFH
        </Typography>
        <Panel border>
          <Login disableRedirect />
        </Panel>
      </Spacer>
    </Blade>
  );
};
