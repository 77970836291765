import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useDataLayerPush } from '../hooks/useDataLayerPush';
import PrsLogo from '../images/logos/prs.png';
import { hideOnPrint } from '../styles/utils';
import { getUrlParts } from '../utils/getUrlParts';
import HtmlContent from './Html';
import { LinkTo } from './LinkTo';
import { Section } from './Section';
import { Spacer } from './Spacer';
import { Typography } from './Typography';
import { CaretDown } from './icon/icons/CaretDown';
import { Facebook } from './icon/icons/Facebook';
import { Instagram } from './icon/icons/Instagram';
import { LinkedIn } from './icon/icons/LinkedIn';
import { Twitter } from './icon/icons/Twitter';
import ArlaLogo from './svg/ArlaLogo';
import KfhLogo from './svg/KfhLogo';
import LondonYouthLogo from './svg/LondonYouthLogo';
import OnTheMarketLogo from './svg/OnTheMarketLogo';

// Interfaces

interface StyledNavProps {
  isActive: boolean;
}

// Styles

const StyledFooter = styled.footer`
  ${({ theme: { colors } }) => css`
    a:hover {
      color: ${colors.brand.default};
      text-decoration: underline;
    }
  `};
`;

const StyledSection = styled(Section)`
  ${({ theme: { space, maxMedia } }) => css`
    // Mobile styles

    @media (max-width: ${maxMedia.lg}) {
      padding-right: ${space.md};
      padding-left: ${space.md};
    }
  `};
`;

const StyledKfhLogo = styled(KfhLogo)`
  ${({ theme: { space, media } }) => css`
    width: 168px;
    margin-bottom: ${space.lg};

    // Desktop styles

    @media (min-width: ${media.lg}) {
      width: 280px;
      margin-bottom: ${space.xl};
    }
  `};
`;

const StyledNavs = styled.div`
  ${({ theme: { space, media } }) => css`
    a {
      text-decoration: none;
    }
    // Desktop styles

    @media (min-width: ${media.lg}) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: ${space.lg};
    }

    ${hideOnPrint}
  `};
`;

const StyledNav = styled.nav<StyledNavProps>`
  ${({ theme: { space, media, maxMedia, borders }, isActive }) => css`
    // Mobile styles

    @media (max-width: ${maxMedia.lg}) {
      border-bottom: ${borders.default};

      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: ${space.md} 0;
      }

      ul {
        padding-bottom: ${space.md};
        display: none;
      }

      ${isActive &&
      css`
        ul {
          display: block;
        }
        button svg {
          transform: rotate(180deg);
        }
      `}
    }

    // Desktop styles

    @media (min-width: ${media.lg}) {
      button {
        margin-bottom: ${space.md};
        cursor: default;

        svg {
          display: none;
        }
      }
    }
  `};
`;

const StyledSocialLinks = styled(Spacer)`
  ${({ theme: { space, maxMedia } }) => css`
    ul {
      display: flex;
      gap: ${space.md};
    }

    // Mobile styles

    @media (max-width: ${maxMedia.lg}) {
      margin-top: ${space.lg};
    }
    ${hideOnPrint}
  `};
`;

const StyledLogos = styled.div`
  ${({ theme: { space, media } }) => css`
    margin-top: ${space.lg};
    // Desktop styles
    @media (min-width: ${media.lg}) {
      margin-top: ${space.xl};
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: ${space.lg};
    }
  `};
`;

const StyledPartnerLogo = styled(Spacer)`
  ${({ theme: { space, maxMedia } }) => css`
    a {
      display: block;
    }

    svg {
      height: 32px;
    }

    // Mobile styles

    @media (max-width: ${maxMedia.lg}) {
      svg {
        height: 24px;
      }

      & + & {
        margin-top: ${space.md};
      }
    }
  `};
`;

const StyledAffiliateLogos = styled.ul`
  ${({ theme: { space, media, maxMedia } }) => css`
    display: flex;

    // Mobile styles

    @media (max-width: ${maxMedia.lg}) {
      margin-top: ${space.lg};
      gap: ${space.md};
      align-items: center;

      span svg,
      img {
        height: 32px;
      }
    }

    // Desktop styles

    @media (min-width: ${media.lg}) {
      justify-content: flex-end;
      grid-column: 3 / 5;
      grid-row: 1;
      gap: ${space.lg};

      span svg,
      img {
        height: 40px;
      }
    }
  `};
`;

const StyledSmallprint = styled(Typography)`
  ${({ theme: { space, media } }) => css`
    margin-bottom: ${space.md};
  `};
`;

const StyledSmallLinks = styled.ul`
  ${({ theme: { space, media } }) => css`
    & + & {
      margin-top: ${space.sm};
    }

    // Desktop styles

    @media (min-width: ${media.lg}) {
      display: flex;

      li + li {
        margin-left: ${space.sm};
        padding-left: ${space.sm};
        border-left: solid 1px;
      }
    }
    ${hideOnPrint}
  `};
`;

// JSX

export const Footer = () => {
  const { dataLayerPush } = useDataLayerPush();
  const [navOneIsActive, setNavOneIsActive] = useState(false);
  const [navTwoIsActive, setNavTwoIsActive] = useState(false);
  const [navThreeIsActive, setNavThreeIsActive] = useState(false);
  const { footerMenuData, socialLinksData } = useStaticQuery(graphql`
    query footerQuery {
      footerMenuData: kfhContentGlobalFooterUmbracoType {
        footerMenu {
          links {
            name
            url
          }
          title
        }
        footerSmallMenu {
          links {
            name
            url
          }
        }
        smallPrint
      }
      socialLinksData: kfhContentGlobalSettingsUmbracoType {
        socialMedia {
          faceBook
          instagram
          linkedIn
          twitter
        }
      }
    }
  `);

  const { footerMenu, footerSmallMenu, smallPrint } = footerMenuData;

  const { socialMedia } = socialLinksData;

  return (
    <>
      <StyledFooter>
        <StyledSection contained background="grey">
          {/* Logo */}
          <StyledKfhLogo />

          {/* Navs */}
          {footerMenu && footerMenu[0] && footerMenu[1] && footerMenu[2] ? (
            <StyledNavs>
              {/* Nav one */}
              <StyledNav isActive={navOneIsActive}>
                <Typography component="h3" variant="titleSmall">
                  <button type="button" onClick={() => setNavOneIsActive(navOneIsActive !== true)}>
                    {footerMenu[0].title}
                    <CaretDown />
                  </button>
                </Typography>

                <Spacer as="ul" spacing="quarter">
                  {footerMenu[0].links.map(({ url, name }, index) => (
                    <Typography component="li" variant="bodySmall" key={`menu1-${index}`}>
                      <LinkTo
                        to={url}
                        onClick={() => {
                          dataLayerPush({
                            event: 'footer',
                            clickText: name,
                            ...getUrlParts(url as string),
                            clickSection: footerMenu[0].title,
                          });
                        }}
                      >
                        {name}
                      </LinkTo>
                    </Typography>
                  ))}
                </Spacer>
              </StyledNav>

              {/* Nav two */}
              <StyledNav isActive={navTwoIsActive}>
                <Typography component="h3" variant="titleSmall">
                  <button type="button" onClick={() => setNavTwoIsActive(navTwoIsActive !== true)}>
                    {footerMenu[1].title}
                    <CaretDown />
                  </button>
                </Typography>
                <Spacer as="ul" spacing="quarter">
                  {footerMenu[1].links.map(({ url, name }, index) => (
                    <Typography component="li" variant="bodySmall" key={`menu2-${index}`}>
                      <LinkTo
                        to={url}
                        onClick={() => {
                          dataLayerPush({
                            event: 'footer',
                            clickText: name,
                            ...getUrlParts(url as string),
                            clickSection: footerMenu[1].title,
                          });
                        }}
                      >
                        {name}
                      </LinkTo>
                    </Typography>
                  ))}
                </Spacer>
              </StyledNav>

              {/* Nav three */}
              <StyledNav isActive={navThreeIsActive}>
                <Typography component="h3" variant="titleSmall">
                  <button type="button" onClick={() => setNavThreeIsActive(navThreeIsActive !== true)}>
                    {footerMenu[2].title}
                    <CaretDown />
                  </button>
                </Typography>
                <Spacer as="ul" spacing="quarter">
                  {footerMenu[2].links.map(({ url, name }, index) => (
                    <Typography component="li" variant="bodySmall" key={`menu3-${index}`}>
                      <LinkTo
                        to={url}
                        onClick={() => {
                          dataLayerPush({
                            event: 'footer',
                            clickText: name,
                            ...getUrlParts(url as string),
                            clickSection: footerMenu[2].title,
                          });
                        }}
                      >
                        {name}
                      </LinkTo>
                    </Typography>
                  ))}
                </Spacer>
              </StyledNav>

              {/* Social links */}
              <StyledSocialLinks forwardedAs="nav" spacing="half">
                <Typography component="h3" variant="titleSmall">
                  Connect with us
                </Typography>
                <ul>
                  <li>
                    <a
                      href={socialMedia.faceBook}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        dataLayerPush({
                          event: 'footer',
                          clickText: 'Facebook',
                          ...getUrlParts(socialMedia.faceBook as string),
                          clickSection: 'social icons',
                        });
                      }}
                    >
                      <Facebook size="default" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={socialMedia.twitter}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        dataLayerPush({
                          event: 'footer',
                          clickText: 'Twitter',
                          ...getUrlParts(socialMedia.twitter as string),
                          clickSection: 'social icons',
                        });
                      }}
                    >
                      <Twitter size="default" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={socialMedia.instagram}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Instagram profile"
                      onClick={() => {
                        dataLayerPush({
                          event: 'footer',
                          clickText: 'Instagram',
                          ...getUrlParts(socialMedia.instagram as string),
                          clickSection: 'social icons',
                        });
                      }}
                    >
                      <Instagram size="default" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={socialMedia.linkedIn}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="LinkedIn profile"
                      onClick={() => {
                        dataLayerPush({
                          event: 'footer',
                          clickText: 'LinkedIn',
                          ...getUrlParts(socialMedia.linkedIn as string),
                          clickSection: 'social icons',
                        });
                      }}
                    >
                      <LinkedIn size="default" />
                    </a>
                  </li>
                </ul>
              </StyledSocialLinks>
            </StyledNavs>
          ) : (
            ''
          )}

          {/* Logos */}

          <StyledLogos>
            <StyledPartnerLogo responsive spacing="quarter">
              <Typography component="h3" variant="titleSmall">
                Proudly supporting
              </Typography>
              <a
                href="https://londonyouth.org/"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  dataLayerPush({
                    event: 'footer',
                    clickText: 'London Youth',
                    ...getUrlParts('https://londonyouth.org/' as string),
                    clickSection: 'partner and affiliate logos',
                  });
                }}
              >
                <LondonYouthLogo />
              </a>
            </StyledPartnerLogo>
            <StyledPartnerLogo responsive spacing="quarter">
              <Typography component="h3" variant="titleSmall">
                See our properties on
              </Typography>
              <a
                href="https://www.onthemarket.com/"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  dataLayerPush({
                    event: 'footer',
                    clickText: 'On The Market',
                    ...getUrlParts('https://www.onthemarket.com/' as string),
                    clickSection: 'partner and affiliate logos',
                  });
                }}
              >
                <OnTheMarketLogo />
              </a>
            </StyledPartnerLogo>
            <StyledAffiliateLogos>
              <li>
                <a
                  href="https://www.theprs.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    dataLayerPush({
                      event: 'footer',
                      clickText: 'Approved Standard',
                      ...getUrlParts('https://www.theprs.co.uk/' as string),
                      clickSection: 'partner and affiliate logos',
                    });
                  }}
                >
                  <img src={PrsLogo} alt="prs" />
                </a>
              </li>
              <li>
                <a
                  href="http://www.arla.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    dataLayerPush({
                      event: 'footer',
                      clickText: 'Arla',
                      ...getUrlParts('http://www.arla.co.uk/' as string),
                      clickSection: 'partner and affiliate logos',
                    });
                  }}
                >
                  <span>
                    <ArlaLogo />
                  </span>
                </a>
              </li>
            </StyledAffiliateLogos>
          </StyledLogos>
        </StyledSection>
        <StyledSection contained tight>
          {/* Small print */}
          {smallPrint && (
            <StyledSmallprint component="div" variant="smallprint">
              <HtmlContent>{smallPrint}</HtmlContent>
            </StyledSmallprint>
          )}

          {/* Small links one */}
          {footerSmallMenu && footerSmallMenu[0] && footerSmallMenu[1] ? (
            <>
              <StyledSmallLinks>
                <Typography component="li" variant="smallprint">
                  <LinkTo to="/documents/cmp-certificate-2024.pdf" target="_blank">
                    Client Money Protection (CMP)
                  </LinkTo>{' '}
                  provided by ARLA
                </Typography>
                {footerSmallMenu[0].links.map(({ url, name }, index) => (
                  <Typography component="li" variant="smallprint" key={`menu4-${index}`}>
                    <LinkTo
                      to={url}
                      onClick={() => {
                        dataLayerPush({
                          event: 'footer',
                          clickText: name,
                          ...getUrlParts(url as string),
                          clickSection: 'small print links',
                        });
                      }}
                    >
                      {name}
                    </LinkTo>
                  </Typography>
                ))}
              </StyledSmallLinks>
              {/* Small links two */}
              <StyledSmallLinks>
                {footerSmallMenu[1].links.map(({ url, name }, index) => (
                  <Typography component="li" variant="smallprint" key={`menu5-${index}`}>
                    <LinkTo
                      to={url}
                      onClick={() => {
                        dataLayerPush({
                          event: 'footer',
                          clickText: name,
                          ...getUrlParts(url as string),
                          clickSection: 'small print links',
                        });
                      }}
                    >
                      {name}
                    </LinkTo>
                  </Typography>
                ))}
              </StyledSmallLinks>
            </>
          ) : (
            ''
          )}
        </StyledSection>
      </StyledFooter>
    </>
  );
};
