import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const TooltipQuestion: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M32 16c0 8.839-7.164 16-16 16S0 24.839 0 16C0 7.167 7.164 0 16 0s16 7.167 16 16ZM16.43 5.29c-3.517 0-5.759 1.481-7.52 4.114a.775.775 0 0 0 .175 1.049l2.239 1.697a.774.774 0 0 0 1.075-.137c1.153-1.462 1.943-2.31 3.697-2.31 1.318 0 2.948.849 2.948 2.127 0 .966-.797 1.462-2.099 2.192-1.517.85-3.526 1.91-3.526 4.559v.258c0 .427.347.774.775.774h3.612a.774.774 0 0 0 .775-.774v-.086c0-1.837 5.366-1.913 5.366-6.882 0-3.742-3.881-6.58-7.518-6.58Zm-.43 16a2.971 2.971 0 0 0-2.968 2.968A2.971 2.971 0 0 0 16 27.226a2.971 2.971 0 0 0 2.968-2.968A2.971 2.971 0 0 0 16 21.29Z"
      />
    </g>
  </Icon>
);
