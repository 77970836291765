import React, { FC } from 'react';
import Blade from '../Blade';
import { Kfh_DivisionType } from '../../types/graphqlTypes';
import { Typography } from '../Typography';
import { Divider } from '../Divider';
import { Spacer } from '../Spacer';
import HtmlContent from '../Html';
import { Button } from '../Button';
import { WhatsAppButton } from '../WhatsAppButton';

interface DivisionBladeProps {
  division: Kfh_DivisionType;
}

export const DivisionBlade: FC<DivisionBladeProps> = ({ division }) => {
  if (!division) {
    return null;
  }
  const { name, divisionAddresses, content, whatsApp } = division;
  return (
    <Blade id="division">
      <Spacer>
        {/* Heading and link */}

        <Typography component="h2" variant="displaySmall">
          {name}
        </Typography>
        {name === 'Block Management' && (
          <>
            <Button
              fullWidth
              label="24/7 Residents portal login"
              url="https://propman.kfh.co.uk/scripts/cgiip.exe/WService=wsPropman/wp-login.p?app=twp"
            />

            <Button
              fullWidth
              variant="outline"
              label="Report an issue"
              url="/services/block-management/report-an-issue/"
            />
          </>
        )}

        <Spacer>
          {/* Whatsapp */}

          {whatsApp.whatsAppEnabled && <WhatsAppButton url={whatsApp.whatsAppFullUrl} />}

          {/* Division address */}

          {divisionAddresses.length >= 1 && (
            <Spacer>
              {divisionAddresses.map(
                ({
                  name: addressName,
                  line1,
                  line2,
                  town,
                  postcode,
                  telephone,
                  email,
                  contactName,
                  id,
                  contactPosition,
                }) => (
                  <Spacer spacing="half" key={id}>
                    {addressName && (
                      <>
                        <Typography component="h4" variant="titleSmall">
                          {addressName}
                        </Typography>
                      </>
                    )}
                    <address>
                      {line1 && (
                        <>
                          {line1} <br />
                        </>
                      )}
                      {line2 && (
                        <>
                          {line2} <br />
                        </>
                      )}
                      {town && (
                        <>
                          {town} <br />
                        </>
                      )}
                      {postcode && (
                        <>
                          {postcode} <br />
                        </>
                      )}
                    </address>
                    <div>
                      {contactName && (
                        <>
                          <Typography component="h5" variant="titleSmall">
                            {contactName}
                          </Typography>
                        </>
                      )}
                      {contactPosition && (
                        <>
                          <p>{contactPosition}</p>
                        </>
                      )}
                    </div>
                    {telephone || email ? (
                      <ul>
                        {telephone && (
                          <li>
                            <a href={`tel:${telephone.replace(/\s+/g, '')}`}>{telephone}</a>
                          </li>
                        )}
                        {email && (
                          <li>
                            <a href={`mailto:${email}`}>{email}</a>
                          </li>
                        )}
                      </ul>
                    ) : (
                      ''
                    )}
                  </Spacer>
                )
              )}
            </Spacer>
          )}
          {content && divisionAddresses.length >= 1 ? <Divider /> : ''}
          {content && (
            <>
              <HtmlContent>{content}</HtmlContent>
            </>
          )}
        </Spacer>
      </Spacer>
    </Blade>
  );
};

export default DivisionBlade;
