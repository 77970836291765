import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
}

const StyledMain = styled.main`
  &.is-hidden-x {
    overflow-x: hidden;
  }
`;
export const Main: FC<Props> = ({ children }) => <StyledMain>{children}</StyledMain>;
