import React, { FC, Suspense } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from './Typography';
import { getBackgroundColor } from '../utils';
import { ThemeColorsKeys } from '../styles/tokens/colors';
// import Tooltip from './Tooltip';
const Tooltip = React.lazy(() => import('./Tooltip'));
export interface TagProps {
  text: string;
  backgroundColor?: ThemeColorsKeys;
  tooltipText?: string;
  tooltipInfoIcon?: boolean;
}

interface StyledTagProps {
  backgroundColor?: ThemeColorsKeys;
}

export const StyledTag = styled(Typography)<StyledTagProps>`
  ${({ theme: { space, global }, backgroundColor }) => css`
    padding: ${space.xs} ${space.sm};
    border-radius: ${global.borderRadius.default};
    min-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: ${space.xs};
    white-space: nowrap;

    // Background and color

    ${backgroundColor && getBackgroundColor(backgroundColor)}
  `};
`;

export const Tag: FC<TagProps> = ({ text, backgroundColor = 'brand', tooltipText, tooltipInfoIcon, ...rest }) => (
  <StyledTag component="span" variant="caps" backgroundColor={backgroundColor} {...rest}>
    {text}
    <Suspense>{tooltipText && <Tooltip text={tooltipText} infoIcon={tooltipInfoIcon} />}</Suspense>
  </StyledTag>
);
