const fontFamily = {
  base: 'Inter, sans-serif',
  display: 'Butler, "Hoefler Text", Garamond, "Times New Roman", serif',
};

export const fontWeight = {
  light: 300,
  regular: 400,
  bold: 700,
};

const type = {
  displayLarge: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.display,
      fontSize: '48px',
      lineHeight: '52px',
      letterSpacing: '-0.48px',
      breakpoints: [
        {
          breakpointSize: 'md',
          fontSize: '72px',
          lineHeight: '80px',
          letterSpacing: '-0.72px',
        },
      ],
    },
    fallback: {
      // ...
    },
  },
  displaySmall: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.display,
      fontSize: '34px',
      lineHeight: '42px',
      letterSpacing: '-0.34px',
      breakpoints: [
        {
          breakpointSize: 'md',
          fontSize: '48px',
          lineHeight: '56px',
          letterSpacing: '-0.48px',
        },
      ],
    },
  },
  titleLarge: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '28px',
      lineHeight: '34px',
      letterSpacing: '-0.14px',
      breakpoints: [
        {
          breakpointSize: 'md',
          fontSize: '32px',
          lineHeight: '40px',
          letterSpacing: '-0.48px',
        },
      ],
    },
  },
  titleMedium: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '22px',
      lineHeight: '30px',
      letterSpacing: '-0.11px',
      breakpoints: [
        {
          breakpointSize: 'md',
          fontSize: '24px',
          lineHeight: '32px',
        },
      ],
    },
  },
  titleSmall: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  titleExtraSmall: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  lead: {
    default: {
      fontWeight: fontWeight.light,
      fontFamily: fontFamily.base,
      fontSize: '20px',
      lineHeight: '28px',
    },
  },
  leadBold: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      fontSize: '20px',
      lineHeight: '28px',
    },
  },
  bodyLarge: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.base,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  bodySmall: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.base,
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  smallprint: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.base,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  caps: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.base,
      textTransform: 'uppercase',
      fontSize: '10px',
      lineHeight: '12px',
      letterSpacing: '0.1px',
    },
  },
};

export default type;
export type ThemeFonts = typeof type;
export type ThemeFontsKeys = keyof typeof type;
