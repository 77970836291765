import { getLastItem } from './getLastItem';

export const isPropertyPageCheck = (thePath: string) => {
  if (!thePath) {
    return false;
  }
  if (thePath.startsWith('/p/')) {
    return true;
  }
  const check = getLastItem(thePath).replace('d', '').replace('p', '').replace('-', '');
  if (Number.isInteger(parseInt(check, 10))) {
    return true;
  }
  return false;
};
