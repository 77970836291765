import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Attach: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M12.716 19.996c-.31.312-.328.84-.041 1.143a.666.666 0 0 0 .98.01L25.085 9.47a3.273 3.273 0 0 0 0-4.55 3 3 0 0 0-4.322 0L7.65 18.331c-2.173 2.223-2.207 5.82-.075 8.02a5.33 5.33 0 0 0 7.691.017l10.754-11a1 1 0 0 1 1.414-.016l1.43 1.398a1 1 0 0 1 .016 1.414l-10.755 11a9.326 9.326 0 0 1-13.423-.03c-3.652-3.768-3.584-9.845.086-13.599L17.9 2.125a6.994 6.994 0 0 1 10.041 0c2.743 2.806 2.747 7.333 0 10.143L16.514 23.946a4.663 4.663 0 0 1-6.75-.063c-1.767-1.875-1.71-4.842.09-6.684l8.987-9.178a1 1 0 0 1 1.413-.015l1.429 1.4a1 1 0 0 1 .015 1.414l-8.982 9.176Z"
      />
    </g>
  </Icon>
);
