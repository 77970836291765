import React, { FC, Suspense, useEffect, useState } from 'react';
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { Input } from './Input';
import { Panel } from '../Panel';
import { Spacer } from '../Spacer';
import { FormSubmissionInput } from '../../types/graphqlTypes';
import { useCreate_Property_Management_ReviewMutation } from '../../types/graphqlHelpers';
import { Textarea } from './Textarea';
import { Typography } from '../Typography';
import { Select } from './Select';
import { TITLES } from '../../const';
import Blade, { BladeBody, BladeContainer, BladeFooter } from '../Blade';
import { useDataLayerPush } from '../../hooks/useDataLayerPush';
import { SmallPrint } from './SmallPrint';
import { getFormFields } from '../../utils/getFormFields';
import { TickCircle } from '../icon/icons/TickCircle';
import { isBrowser } from '../../utils/isBrowser';
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { RecaptchaAction } from './RecaptchaAction';
import { alertBadEmail, validateEmail } from '../../utils/emailBlackList';

export interface PropertyManagementReviewProps {}

const StyledTitleWrap = styled.div`
  ${({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.md};
  `}
`;

const StyledLead = styled(Typography)`
  ${({ theme: { space } }) => css`
    max-width: 530px;
  `}
`;

export const PropertyManagementReview: FC<PropertyManagementReviewProps> = ({ ...rest }) => {
  const { dataLayerPush } = useDataLayerPush();
  // Handle form submission

  // Temp var to allow mock submisisons
  const [hasSent, setHasSent] = useState(false);

  // Mutation to submit the form
  const [CreatePropertyManagementReviewMutation, { data, loading, error }] =
    useCreate_Property_Management_ReviewMutation();
  const formMethods = useForm({
    mode: 'onSubmit',
  });

  const { register, handleSubmit, formState, control } = formMethods;
  const { errors, dirtyFields, touchedFields, isSubmitted } = formState;
  const { handleReCaptchaVerify, isHuman } = useRecaptcha({ formMethods, action: 'propertyManagementReview' });
  useEffect(() => {
    function checkSuccess() {
      if (!loading && data?.form?.createFormSubmission?.success) {
        setHasSent(true);
      }
    }
    checkSuccess();
  }, [data, dataLayerPush, loading]);

  // Function to handle collecting form data and  submitting the GQL mutation
  const onSubmit: SubmitHandler<FormSubmissionInput> = async (formData) => {
    alertBadEmail(formData.email);
    delete formData.captchaToken;
    const submitData = {
      pageUrl: isBrowser ? window.location.href : '',
      type: 'PROPERTY_MANAGEMENT_REVIEW',
      fromName: `${formData.forename} ${formData.surname}`,
      fromEmail: formData.email,
      fields: getFormFields(formData),
    };

    dataLayerPush({
      event: 'formSubmit',
      formName: 'propertyManagementReview',
      formValidation: 'success',
      formError: undefined,
    });

    await CreatePropertyManagementReviewMutation({
      variables: { formSubmission: submitData },
    });
  };

  return (
    <Blade id="propertyManagementReview" customBlade>
      <FormProvider {...formMethods}>
        {(!isSubmitted || !hasSent) && (
          <BladeContainer>
            <BladeBody>
              <Spacer>
                <Typography variant="displaySmall">Property management review</Typography>
                <Panel as="form" onSubmit={handleSubmit(onSubmit)} method="post" noValidate border>
                  <Spacer spacing="half">
                    <Typography component="h3" variant="titleMedium">
                      Your details
                    </Typography>
                    <Typography variant="smallprint">*Please complete all required form fields</Typography>

                    <Select
                      id="title"
                      label="Title"
                      {...register('title', { required: true })}
                      required
                      options={TITLES}
                      constrained
                      invalid={!!errors.title}
                      valid={touchedFields.title && dirtyFields.title && !errors.title}
                    />
                    <Input
                      type="text"
                      invalid={!!errors.forename}
                      valid={touchedFields.forename && dirtyFields.forename && !errors.forename}
                      label="First name"
                      id="forename"
                      placeholder="e.g Sarah"
                      {...register('forename', { required: true })}
                      required
                    />
                    <Input
                      type="text"
                      label="Last name"
                      id="surname"
                      invalid={!!errors.surname}
                      placeholder="e.g Smith"
                      valid={touchedFields.surname && dirtyFields.surname && !errors.surname}
                      {...register('surname', { required: true })}
                      required
                    />
                    <Input
                      type="email"
                      label="Email"
                      id="email"
                      invalid={!!errors.email}
                      valid={touchedFields.email && dirtyFields.email && !errors.email}
                      required
                      placeholder="e.g sarah@email.com"
                      {...register('email', {
                        validate: validateEmail, // email spam check
                        required: true,
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />

                    <Select
                      id="aboutYou"
                      label="About you"
                      {...register('aboutYou', { required: true })}
                      required
                      options={[
                        { name: 'Landlord', value: 'Landlord' },
                        { name: 'Tenant', value: 'Tenant' },
                      ]}
                      invalid={!!errors.aboutYou}
                      valid={touchedFields.aboutYou && dirtyFields.aboutYou && !errors.aboutYou}
                    />

                    <Input
                      type="text"
                      label="Property manager name"
                      id="propertyManagerName"
                      invalid={!!errors.propertyManagerName}
                      placeholder="e.g Smith"
                      valid={
                        touchedFields.propertyManagerName &&
                        dirtyFields.propertyManagerName &&
                        !errors.propertyManagerName
                      }
                      {...register('propertyManagerName', { required: true })}
                      required
                    />

                    <Select
                      id="rating"
                      label="How satisfied are you with the service provided by your Property Manager?"
                      {...register('rating', { required: true })}
                      required
                      options={[
                        { name: '1 - Very unlikely', value: '1' },
                        { name: '2', value: '2' },
                        { name: '3', value: '3' },
                        { name: '4', value: '4' },
                        { name: '5', value: '5' },
                        { name: '6', value: '6' },
                        { name: '7', value: '7' },
                        { name: '8', value: '8' },
                        { name: '9', value: '9' },
                        { name: '10 - Very likely', value: '10' },
                      ]}
                      invalid={!!errors.rating}
                      valid={touchedFields.rating && dirtyFields.rating && !errors.rating}
                    />

                    <Textarea
                      invalid={!!errors.review}
                      valid={touchedFields.review && dirtyFields.review && !errors.review}
                      label="Write a review about our service"
                      id="review"
                      {...register('review', { required: true })}
                      required
                    />
                  </Spacer>
                </Panel>
                <RecaptchaAction show={!isHuman} handleReCaptchaVerify={handleReCaptchaVerify} />
                <SmallPrint />
              </Spacer>
            </BladeBody>
            <BladeFooter
              primaryButton={{
                label: 'Submit review',
                onClick: handleSubmit(onSubmit),
                disableClickTracking: true,
              }}
            />
          </BladeContainer>
        )}

        {isSubmitted && hasSent && (
          <BladeContainer>
            <BladeBody>
              <Panel>
                <Spacer>
                  <StyledTitleWrap>
                    <TickCircle size="extraLarge" />
                    <Typography variant="displaySmall">Received</Typography>
                  </StyledTitleWrap>
                  <StyledLead variant="lead">Thank you for your review.</StyledLead>
                </Spacer>
              </Panel>
            </BladeBody>
          </BladeContainer>
        )}
      </FormProvider>
    </Blade>
  );
};
