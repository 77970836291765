import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const CaretDown: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M8.25 11h16.09c1.112 0 1.669 1.344.88 2.132l-8.04 8.047a1.253 1.253 0 0 1-1.77 0l-8.04-8.047C6.58 12.344 7.137 11 8.25 11Z"
      />
    </g>
  </Icon>
);
