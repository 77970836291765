import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const TooltipInfo: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M16 0C7.164 0 0 7.167 0 16c0 8.839 7.164 16 16 16s16-7.161 16-16c0-8.833-7.164-16-16-16Zm0 7.097a2.71 2.71 0 1 1 0 5.42 2.71 2.71 0 0 1 0-5.42Zm3.613 16.387a.774.774 0 0 1-.774.774H13.16a.774.774 0 0 1-.774-.774v-1.549c0-.427.347-.774.774-.774h.774v-4.129h-.774a.774.774 0 0 1-.774-.774V14.71c0-.428.347-.775.774-.775h4.13c.427 0 .774.347.774.775v6.451h.774c.427 0 .774.347.774.774v1.549Z"
      />
    </g>
  </Icon>
);
