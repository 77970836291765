import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Epc: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M1 32a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v27h27a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H1Zm17-12a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h9Zm4-8a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h13Zm5-8a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h18Z"
      />
    </g>
  </Icon>
);
