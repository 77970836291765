import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Trash: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M29 2h-7.5L20.913.831A1.5 1.5 0 0 0 19.569 0h-7.144a1.483 1.483 0 0 0-1.337.831L10.5 2H3a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1ZM5.325 29.188A3 3 0 0 0 8.319 32H23.68a3 3 0 0 0 2.994-2.812L28 8H4l1.325 21.188Z"
      />
    </g>
  </Icon>
);
