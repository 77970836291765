import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ThemeIconSizesKeys } from '../styles/tokens/iconSizes';

export interface LoaderProps {
  size?: ThemeIconSizesKeys;
  centered?: boolean;
  minHeight?: string;
}

interface StyledLoaderProps {
  size: ThemeIconSizesKeys;
  centered?: boolean;
  minHeight?: string;
}

const rotateAni = keyframes`
   0% {
      transform-origin: 50% 50%;
    }
    100% {
      transform: rotate(360deg);
    }
`;
const dashAni = keyframes`
 0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -125px;
    }
`;
const loadingAni = keyframes`
    0% {
      color: #3aa75b;
    }
    25% {
      color: #d8183a;
    }
    50% {
      color: #674c99;
    }
    75% {
      color: #00a2e8;
    }
`;

const StyledLoader = styled.div<StyledLoaderProps>`
  ${({ theme: { iconSizes }, size, centered, minHeight }) => {
    const width = iconSizes[size].split(' ')[0];
    const height = iconSizes[size].split(' ')[1];
    return css`
      display: inline-block;

      svg {
        animation: ${rotateAni} 1.4s linear infinite;
        display: block;
        animation: ${loadingAni} 1.4s infinite;
        width: ${width}px;
        height: ${height}px;
      }
      circle {
        stroke: currentColor;
        animation: ${dashAni} 1.4s ease-in-out infinite;
        stroke-dasharray: 80px, 200px;
        stroke-dashoffset: 0;
      }

      ${centered &&
      css`
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
      `}

      ${minHeight &&
      css`
        min-height: ${minHeight};
      `}
    `;
  }};
`;

export const Loader: FC<LoaderProps> = ({ size = 'extraLarge', centered, minHeight }) => (
  <>
    <StyledLoader role="progressbar" size={size} centered={centered} minHeight={minHeight}>
      <svg viewBox="22 22 44 44">
        <circle cx={44} cy={44} r="20.2" fill="none" strokeWidth="3.6" />
      </svg>
    </StyledLoader>
  </>
);
