export const colors = {
  // KFH Black
  black: {
    default: '#111111',
    contrast: '#FFFFFF',
  },
  // KFH Red
  brand: {
    default: '#D8183A',
    contrast: '#FFFFFF',
  },
  // KFH Red - Dark
  brandDark: {
    default: '#B81432',
    contrast: '#FFFFFF',
  },
  // KFH Yellow
  accent1: {
    default: '#F3A72A',
    contrast: '#FFFFFF',
  },
  // KFH Purple
  accent2: {
    default: '#674C99',
    contrast: '#FFFFFF',
  },
  // KFH Blue
  accent3: {
    default: '#00A2E8',
    contrast: '#FFFFFF',
  },
  // KFH Blue
  accent4: {
    default: '#3AA75B',
    contrast: '#FFFFFF',
  },
  // KFH White
  white: {
    default: '#FFFFFF',
    contrast: '#111111',
  },
  // KFH Grey - Extra Light
  grey1: {
    default: '#F5F5F5',
    contrast: '#111111',
  },
  // KFH Grey - Light
  grey2: {
    default: '#EEEEEE',
    contrast: '#111111',
  },
  // KFH Grey - Mid
  grey3: {
    default: '#E5E5E5',
    contrast: '#111111',
  },
  // KFH Grey - Dark
  grey4: {
    default: '#999999',
    contrast: '#111111',
  },
  // Valid - green
  valid: {
    default: '#3AA75B',
    contrast: '#FFFFFF',
  },
  // Invalid - red
  invalid: {
    default: '#D8183A',
    contrast: '#FFFFFF',
  },
  // Transparent
  transparent: {
    default: 'transparent',
    contrast: 'transparent',
  },
  // WhatsApp
  whatsAppGreen: {
    default: '#16bf46',
    contrast: '#FFFFFF',
  },
};

export default colors;

export type ThemeColors = typeof colors;
export type ThemeColorsKeys = keyof typeof colors;
