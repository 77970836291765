import React, { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMykfh_Forgot_PasswordMutation } from '../../types/graphqlHelpers';
import Blade, { BladeBody, BladeContainer, BladeFooter } from '../Blade';
import { Button } from '../Button';
import { Input } from '../forms/Input';
import { Loader } from '../Loader';
import { Spacer } from '../Spacer';
import { Typography } from '../Typography';
import { alertBadEmail, validateEmail } from '../../utils/emailBlackList';

interface RequestResetPasswordBladeProps {
  email: string;
}

interface FormValues {
  email: string;
}

export const RequestResetPasswordBlade: FC<RequestResetPasswordBladeProps> = ({ email }) => {
  const [response, setResponse] = useState<any>();
  const [myKfhForgotPasswordMutation, { data, loading, error }] = useMykfh_Forgot_PasswordMutation();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      email,
    },
  });
  useEffect(() => {
    if (data) {
      setResponse(data);
    }
  }, [data]);

  const { register, handleSubmit, formState } = formMethods;
  const { errors, dirtyFields, touchedFields } = formState;

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    alertBadEmail(formData.email);
    myKfhForgotPasswordMutation({
      variables: {
        myKfhForgotPassword: {
          email: formData.email.trim(),
        },
      },
    });
  };

  return (
    <Blade id="myKfhRequestResetPassword" width="narrow" customBlade>
      {loading && <Loader />}
      {!loading &&
      (response?.myKfh.myKfhForgotPassword.errorDescription || response?.myKfh.myKfhForgotPassword.success) ? (
        <BladeContainer>
          <BladeBody>
            <Spacer>
              {response?.myKfh.myKfhForgotPassword.errorDescription ? (
                <>
                  <Typography variant="displaySmall">Password reset error. </Typography>
                  <Typography variant="lead">{response?.myKfh.myKfhForgotPassword.errorDescription}</Typography>
                </>
              ) : (
                <>
                  <Typography variant="displaySmall">We’ve sent you an email to reset your password. </Typography>
                  <Typography variant="lead">
                    Click the link in the email to reset your password and log in to MyKFH.
                  </Typography>
                </>
              )}
              <Button variant="solid" label="Start again" onClick={() => setResponse(undefined)} />
            </Spacer>
          </BladeBody>
          <BladeFooter />
        </BladeContainer>
      ) : (
        <BladeContainer as="form" onSubmit={handleSubmit(onSubmit)} method="post" noValidate>
          <BladeBody>
            <Spacer>
              <Typography variant="displaySmall">Reset password</Typography>

              <Input
                id="email"
                type="email"
                label="Email"
                {...register('email', {
                  validate: validateEmail, // email spam check
                  required: {
                    value: true,
                    message: 'Please enter your email address',
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Invalid email address',
                  },
                })}
                invalid={!!errors.email}
                valid={touchedFields.email && dirtyFields.email && !errors.email}
                required
              />
              {error}
            </Spacer>
          </BladeBody>

          <BladeFooter
            primaryButton={{
              label: 'Submit request',
              disableClickTracking: true,
              onClick: handleSubmit(onSubmit),
            }}
          />
        </BladeContainer>
      )}
    </Blade>
  );
};
