import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Padlock: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M25.313 14.375h-1.22v-3.656C24.094 6.463 20.63 3 16.376 3s-7.719 3.463-7.719 7.719v3.656h-1.22A2.438 2.438 0 0 0 5 16.813v9.75A2.438 2.438 0 0 0 7.438 29h17.875a2.438 2.438 0 0 0 2.437-2.438v-9.75a2.438 2.438 0 0 0-2.438-2.437Zm-5.282 0H12.72v-3.656a3.66 3.66 0 0 1 3.656-3.656 3.66 3.66 0 0 1 3.656 3.656v3.656Z"
      />
    </g>
  </Icon>
);
