import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Location: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M16 0C7.163 0 0 7.163 0 16c0 8.836 7.163 16 16 16 8.836 0 16-7.164 16-16 0-8.837-7.164-16-16-16Zm7.112 10.95-6.19 13.413c-.723 1.548-2.993 1.031-2.993-.619V18.07H8.255c-1.651 0-2.167-2.27-.619-2.992l13.412-6.19c1.239-.516 2.58.826 2.064 2.064Z"
      />
    </g>
  </Icon>
);
