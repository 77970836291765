import styled, { css } from 'styled-components';
import { getBackgroundColor, getOffsetSpace } from '../../utils';

export interface BaseFormProps {
  label: string;
  id: string;
  valid?: boolean;
  invalid?: boolean;
  constrained?: boolean;
  as?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

interface StyledInputProps {
  valid?: boolean;
  invalid?: boolean;
  as?: string;
  constrained?: boolean;
}

export const StyledBase = styled.input<StyledInputProps>`
  ${({ theme: { global, borders, colors, space }, valid, invalid, constrained, as }) => css`
    border: ${borders.default};
    border-radius: ${global.borderRadius.default};
    ${getBackgroundColor('white')}
    padding: ${getOffsetSpace(space.md)};
    width: 100%;
    display: block;
    position: relative;
    transition: all 0.2s ease;

    &::placeholder {
      color: ${colors.grey4.default};
    }

    &:focus,
    &:hover {
      outline: none;
      border-color: ${colors.black.default};
    }

    // If select input
    ${as === 'select' &&
    css`
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' class='Icon__StyledIcon-NDAcm ieTwjA'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h32v32H0z'%3E%3C/path%3E%3Cpath fill='currentColor' d='M8.25 11h16.09c1.112 0 1.669 1.344.88 2.132l-8.04 8.047a1.253 1.253 0 0 1-1.77 0l-8.04-8.047C6.58 12.344 7.137 11 8.25 11Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 16px center;
      background-size: 16px 16px;
      appearance: none;
      padding-right: ${space.lg};
    `}

    // Constrained

    ${constrained &&
    css`
      max-width: 180px;
    `}

    // Valid state

    ${valid &&
    css`
      border-color: ${colors.valid.default};

      &:focus,
      &:hover {
        border-color: ${colors.valid.default};
      }
    `}

    // Invalid state

    ${invalid &&
    css`
      border-color: ${colors.invalid.default};

      &:focus,
      &:hover {
        border-color: ${colors.invalid.default};
      }
    `}
  `};
`;
