// This file allows for the theme to be extended by the child theme.
import theme from '../theme';
import colorDefaults from './colors';
import borderDefaults from './borders';
import shadowDefaults from './shadows';
import gradientDefaults from './gradients';
import iconSizeDefaults from './iconSizes';
import layersDefaults from './layers';
import mediaDefaults from './media';
import maxMediaDefaults from './maxMedia';
import spaceDefaults from './space';
import typographyDefaults from './typography';
import buttonDefaults from './buttons';
import animationDefaults from './animation';
import globalDefaults from './global';

export const colors = { ...colorDefaults, ...(theme.colors || {}) };
export const borders = { ...borderDefaults, ...(theme.borders || {}) };
export const shadows = { ...shadowDefaults, ...(theme.shadows || {}) };
export const gradients = { ...gradientDefaults, ...(theme.gradients || {}) };
export const iconSizes = { ...iconSizeDefaults, ...(theme.iconSizes || {}) };
export const layers = { ...layersDefaults, ...(theme.layers || {}) };
export const media = { ...mediaDefaults, ...(theme.media || {}) };
export const maxMedia = { ...maxMediaDefaults, ...(theme.maxMedia || {}) };
export const space = { ...spaceDefaults, ...(theme.space || {}) };
export const buttons = { ...buttonDefaults, ...(theme.buttons || {}) };
export const typography = {
  ...typographyDefaults,
  ...(theme.typography || {}),
};
export const animation = { ...animationDefaults, ...(theme.animation || {}) };
export const global = { ...globalDefaults, ...(theme.global || {}) };

export default {
  colors,
  borders,
  shadows,
  gradients,
  iconSizes,
  global,
  layers,
  media,
  maxMedia,
  space,
  animation,
  typography,
  buttons,
};
