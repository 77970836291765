import React, { FC } from 'react';
import { capitalize } from '../../utils/capitalize';
import { Kfh_UmbracoButton } from '../../types/graphqlTypes';
import { DataPreview } from '../dev/DataPreview';
import MockComponent from '../dev/MockComponent';
import * as AllComponents from './components';
import { ThemeButtonKeys } from '../../styles/tokens/buttons';

export interface ButtonRendererProps {
  button?: Kfh_UmbracoButton;
  variant?: ThemeButtonKeys;
  arrowLink?: boolean;
}

interface DynamicButtonComponentProps {
  alias: string;
  configuration: Record<string, any>;
  variant?: ThemeButtonKeys;
  arrowLink?: boolean;
}

const DynamicButtonComponent: FC<DynamicButtonComponentProps> = ({
  alias,
  configuration,
  variant,
  arrowLink,
  ...rest
}) => {
  // check for fieldGroupName/ alias

  if (!alias) {
    return null;
  }
  // 1. Generate the component name
  const componentName: string = capitalize(alias.replace('UmbracoButton', '').replace('Type', ''));

  // 2. Grab the layout
  const TagName = AllComponents[componentName];

  // 3. Check it actually exists
  if (!TagName) {
    console.log(`*** COMPONENT not found: ${componentName} ***`);

    // 3.a Not found
    return process.env.NODE_ENV === 'development' ? (
      <MockComponent>
        <p>
          <b>*** 🔧 BUTTON COMPONENT not found: {componentName} 🔧 ***</b>
        </p>
        {configuration && <DataPreview data={configuration} />}
        <hr />
      </MockComponent>
    ) : null;
  }

  // 3b. return component
  return <TagName {...configuration} variant={variant} arrowLink={arrowLink} {...rest} />;
};

export const ButtonRenderer: FC<ButtonRendererProps> = ({ button, variant = 'solid', arrowLink, ...rest }) => {
  if (!button?.remoteTypeName) {
    return null;
  }

  return (
    <>
      <DynamicButtonComponent
        alias={button.remoteTypeName}
        configuration={button}
        variant={variant}
        arrowLink={arrowLink}
        {...rest}
      />
    </>
  );
};
