import React, { FC, Suspense, useContext, useEffect } from 'react';
import { UiStateContext } from '../context/UiStateContext';
import { Blades } from '../types/blades';

const ArrangeAViewingBlade = React.lazy(() => import('./properties/ArrangeAViewingBlade'));
const SavePropertyLoginOrRegisterBlade = React.lazy(() => import('./my-kfh/SavePropertyLoginOrRegisterBlade'));
const DeleteSavedPropertyBlade = React.lazy(() => import('./my-kfh/DeleteSavedpropertyBlade'));

export interface BladeRendererProps {}

export const BladeRenderer: FC<BladeRendererProps> = () => {
  const { activeBlade, setActiveBlade } = useContext(UiStateContext);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const form = params.get('form');

    if (form) {
      setActiveBlade(form as Blades);
    }
  }, [setActiveBlade]);


  switch (activeBlade) {
    case 'propertyArrangeAViewing':
      return (
        <Suspense>
          <ArrangeAViewingBlade />
        </Suspense>
      );
    case 'savePropertyLoginOrRegister':
      return (
        <Suspense>
          <SavePropertyLoginOrRegisterBlade />
        </Suspense>
      );
    case 'deleteSavedProperty':
      return (
        <Suspense>
          <DeleteSavedPropertyBlade />
        </Suspense>
      );
    default:
      return <></>;
  }
};
