import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const SliderLeft: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <rect width="32" height="32" fill="#111" opacity=".32" rx="2" />
      <path d="M21.667 21.667H11V11h10.667z" />
      <path
        fill="#FFF"
        d="M17.667 13.66v5.36a.417.417 0 0 1-.712.295l-2.68-2.68a.417.417 0 0 1 0-.59l2.68-2.68a.417.417 0 0 1 .712.294Z"
      />
    </g>
  </Icon>
);
