import React, { FC } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  id: string;
}

export const Portal: FC<React.PropsWithChildren<PortalProps>> = ({ children, id }) => {
  const portalEl = typeof document !== `undefined` ? document.getElementById(id) : null;

  if (portalEl) {
    return ReactDOM.createPortal(children, portalEl);
  }
  return null;
};

export default Portal;
