export const PROPERTY_URLS = {
  commercialProperties: 'commercial-properties',
  commercialRentalProperties: 'commercial-properties-for-rent',
  developmentProperties: 'development-properties',
  newHomeProperties: 'new-home-properties',
  propertiesForSale: 'properties-for-sale',
  propertiesForRent: 'properties-to-rent',
  propertiesSold: 'sold-properties',
  propertiesLet: 'recently-let',
  housesForSale: 'houses-for-sale',
  flatsForSale: 'flats-for-sale',
  housesForRent: 'houses-to-rent',
  flatsForRent: 'flats-to-rent',
  londonPropertiesForSale: 'london/properties-for-sale',
  londonHousesForSale: 'london/houses-for-sale',
  londonFlatsForSale: 'london/flats-for-sale',
  londonPropertiesForRent: 'london/properties-to-rent',
  londonHousesForRent: 'london/houses-to-rent',
  londonFlatsForRent: 'london/flats-to-rent',
  londonSoldProperties: 'london/sold-properties',
  londonLetProperties: 'london/recently-let',
  searchResults: 'search-results',
};

export const LOCATION_URLS = {
  areaInformation: 'london',
  soldData: 'sold-data',
  councilTax: 'council-tax',
  transport: 'transport',
  schools: 'schools',
};

export const BRANCH_URLS = {
  branches: 'branches',
};

export const CAREERS_URLS = {
  careers: 'careers',
  vacancies: 'careers/vacancies',
};

export const MY_KFH_ROUTE_BASE = '/my-kfh';

export const MY_KFH_ROUTES = {
  login: `/mykfh-login-and-register`,
  dashboard: `${MY_KFH_ROUTE_BASE}/`,
  savedProperties: `${MY_KFH_ROUTE_BASE}/saved-properties/`,
  propertyAlerts: `${MY_KFH_ROUTE_BASE}/property-alerts/`,
  accountSettings: `${MY_KFH_ROUTE_BASE}/account-settings/`,
};
