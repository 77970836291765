import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Twitter: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g clipPath="url(#clip0_596_707)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.571 0H3.43C2.52048 -3.86539e-08 1.6482 0.361237 1.00498 1.00427C0.361756 1.6473 0.000265166 2.51948 0 3.429L0 28.57C-3.86539e-08 29.4795 0.361237 30.3518 1.00427 30.995C1.6473 31.6382 2.51948 31.9997 3.429 32H28.57C29.4795 32 30.3518 31.6388 30.995 30.9957C31.6382 30.3527 31.9997 29.4805 32 28.571V3.43C32 2.52048 31.6388 1.6482 30.9957 1.00498C30.3527 0.361756 29.4805 0.000265166 28.571 0Z"
        fill="#111111"
      />
      <path
        d="M17.9026 14.4686L25.348 6H23.5837L17.1189 13.3532L11.9555 6H6L13.8081 17.1193L6 26H7.76449L14.5916 18.2348L20.0445 26H26L17.9024 14.4686H17.9029H17.9026ZM15.4859 17.2172L14.6948 16.11L8.40015 7.29961H11.1101L16.1901 14.4099L16.9812 15.517L23.5845 24.7593H20.8745L15.4861 17.2175V17.217L15.4859 17.2172Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_596_707">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
