import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Search: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m31.56 27.666-6.231-6.23a1.499 1.499 0 0 0-1.063-.438h-1.018a12.935 12.935 0 0 0 2.75-8c0-7.18-5.819-12.998-13-12.998C5.819 0 0 5.818 0 12.999c0 7.18 5.818 12.998 12.999 12.998 3.018 0 5.793-1.024 7.999-2.75v1.02c0 .4.156.78.437 1.062l6.231 6.23a1.494 1.494 0 0 0 2.119 0l1.768-1.768a1.507 1.507 0 0 0 .006-2.125Zm-18.561-6.668c-4.419 0-8-3.575-8-8A7.996 7.996 0 0 1 13 5a7.994 7.994 0 0 1 7.999 7.999 7.995 7.995 0 0 1-8 7.999Z"
    />
  </Icon>
);
