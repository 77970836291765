import { ApolloQueryResult, NetworkStatus } from '@apollo/client';
import React, { createContext, useEffect, useState } from 'react';
import { useAuthToken } from '../hooks/useAuth';
import { useMykfh_Read_AccountLazyQuery, useMy_Kfh_Read_Saved_Properties_IdsLazyQuery } from '../types/graphqlHelpers';
import {
  MyKfhCreateSavedPropertyInput,
  MyKfhCreateSavedSearchInput,
  MyKfhUserType,
  Mykfh_Read_AccountQuery,
} from '../types/graphqlTypes';
import { isBrowser } from '../utils/isBrowser';

export interface RegisterProps {
  action: 'saveProperty' | 'saveSearch';
  data: MyKfhCreateSavedPropertyInput | MyKfhCreateSavedSearchInput;
}
export interface UserContextProps {
  currency: number;
  handleSetCurrency: (currency: number) => void;
  user?: MyKfhUserType;
  loadUserCalled: boolean;
  loading: boolean;
  handleClearUser: () => void;
  savedProperties: any[];
  networkStatus: NetworkStatus;
  refetchUser: (variables?: any) => Promise<ApolloQueryResult<Mykfh_Read_AccountQuery>>;
  fetchUser: any;
  registerData?: RegisterProps;
  setRegisterData: React.Dispatch<React.SetStateAction<RegisterProps | undefined>>;
}

const CURRENCY_KEY = 'kfh-currency';

export const UserContext = createContext({} as UserContextProps);
UserContext.displayName = 'UserContext';

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState<MyKfhUserType>();
  const [savedProperties, setSavedProperties] = useState<any>();
  const [registerData, setRegisterData] = useState<RegisterProps>();
  const { token } = useAuthToken();
  const [fetchUser, { data, loading, called, networkStatus, refetch }] = useMykfh_Read_AccountLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [fetchSavedProperties, { data: savedPropertiesData, called: savedPropertiesCalled }] =
    useMy_Kfh_Read_Saved_Properties_IdsLazyQuery({
      fetchPolicy: 'network-only',
    });

  // if we a user then load there saved properties
  useEffect(() => {
    if (called && data?.myKfh?.myKfhReadAccount?.success && data?.myKfh?.myKfhReadAccount?.user?.id) {
      const userData = data?.myKfh?.myKfhReadAccount?.user as MyKfhUserType;
      fetchSavedProperties();
      setUser(userData);
    }
  }, [called, fetchSavedProperties, data]);

  useEffect(() => {
    if (savedPropertiesCalled && savedPropertiesData?.myKfh?.myKfhReadSavedProperties?.success) {
      setSavedProperties(savedPropertiesData?.myKfh?.myKfhReadSavedProperties?.savedProperties);
    }
  }, [savedPropertiesData, savedPropertiesCalled]);

  // Only fetch the user if we have a token and the user is not loaded yet

  useEffect(() => {
    if (token && !user) {
      fetchUser();
    }
  }, [fetchUser, token, user]);

  const handleClearUser = () => {
    setUser(undefined);
    setSavedProperties(undefined);
  };

  const [currency, setCurrency] = useState<number>(() => {
    if (!isBrowser || !window.localStorage.getItem(CURRENCY_KEY)) {
      return 1;
    }

    return +window.localStorage.getItem(CURRENCY_KEY)!;
  });

  const handleSetCurrency = (newCurrency: number) => {
    setCurrency(newCurrency);
    if (isBrowser) {
      window.localStorage.setItem(CURRENCY_KEY, newCurrency.toString());
    }
  };

  const contextValues: UserContextProps = {
    currency,
    handleSetCurrency,
    loading,
    user,
    loadUserCalled: called,
    handleClearUser,
    savedProperties,
    networkStatus,
    refetchUser: refetch,
    fetchUser,
    registerData,
    setRegisterData,
  };

  return <UserContext.Provider value={contextValues}>{children}</UserContext.Provider>;
};
