import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Menu: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M3.063 8.25h26a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1h-26a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1Zm0 10h26a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1h-26a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1Zm0 10h26a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1h-26a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1Z"
      />
    </g>
  </Icon>
);
