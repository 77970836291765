import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Instagram: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M16 12.19a3.81 3.81 0 1 0 .003 7.62A3.81 3.81 0 0 0 16 12.19Zm8.908-2.929a3.857 3.857 0 0 0-2.172-2.172c-1.5-.592-5.072-.459-6.736-.459-1.664 0-5.232-.138-6.736.46A3.857 3.857 0 0 0 7.09 9.26c-.591 1.5-.459 5.075-.459 6.738 0 1.663-.132 5.234.462 6.739a3.857 3.857 0 0 0 2.172 2.172c1.5.592 5.072.46 6.737.46s5.231.137 6.736-.46a3.857 3.857 0 0 0 2.172-2.172c.596-1.5.459-5.075.459-6.738 0-1.663.137-5.233-.46-6.738h-.002ZM16 21.857A5.857 5.857 0 1 1 21.857 16 5.85 5.85 0 0 1 16 21.857Zm6.099-10.593a1.367 1.367 0 1 1 1.366-1.367 1.364 1.364 0 0 1-1.364 1.37l-.002-.003ZM28.57 0H3.43A3.429 3.429 0 0 0 0 3.429V28.57A3.429 3.429 0 0 0 3.429 32H28.57A3.429 3.429 0 0 0 32 28.571V3.43A3.429 3.429 0 0 0 28.571 0Zm-1.22 20.714c-.093 1.831-.51 3.453-1.847 4.786-1.336 1.333-2.957 1.76-4.786 1.846-1.886.107-7.542.107-9.428 0-1.83-.092-3.447-.51-4.786-1.846-1.338-1.336-1.76-2.959-1.846-4.786-.107-1.887-.107-7.543 0-9.428.092-1.831.505-3.453 1.846-4.786 1.342-1.333 2.962-1.754 4.786-1.841 1.886-.107 7.542-.107 9.428 0 1.831.092 3.453.51 4.786 1.846 1.334 1.336 1.76 2.959 1.847 4.79.106 1.88.106 7.53 0 9.42Z"
      />
    </g>
  </Icon>
);
