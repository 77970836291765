import React, { FC } from 'react';
import { Link } from 'gatsby';

interface LinkToProps {
  to: string;
  onClick?: any;
  className?: string;
  state?: any;
  target?: string;
}

export const LinkTo: FC<React.PropsWithChildren<LinkToProps>> = ({ to, children, className, onClick, state, target }) => {
  const domain = process.env.GATSBY_FRONTEND_URL;

  let link = to;
  link = link.replace('https://umbraco.kfh.co.uk/media/', '/media/');
  link = link.replace('https://staging-umbraco.kfh.co.uk/media/', '/media/');
  let isExternal = to?.trim().startsWith('http');
  const isAnchor = to?.trim().startsWith('#');
  const isMail = to?.trim().startsWith('mailto');
  const isTel = to?.trim().startsWith('tel');

  if (isExternal && domain && link.includes(domain)) {
    [, link] = to.split(domain);
    isExternal = false;
  }
  return (
    <>
      {isExternal || isAnchor || isMail || isTel || target === '_blank' ? (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={link}
          target={isExternal || target === '_blank' ? '_blank' : ''}
          rel={isExternal ? 'noopener noreferrer' : ''}
          className={className}
          onClick={onClick}
        >
          {children}
        </a>
      ) : (
        <Link to={link} activeClassName="is-active" className={className} onClick={onClick} state={state}>
          {children}
        </Link>
      )}
    </>
  );
};

export default LinkTo;
