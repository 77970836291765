import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Saved: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0-2h32v32H0z" />
      <path
        fill="#D8183A"
        d="M28.894 1.915c-3.425-2.92-8.519-2.394-11.662.85L16 4.033l-1.23-1.268c-3.138-3.244-8.238-3.77-11.663-.85-3.925 3.35-4.131 9.362-.619 12.993l12.094 12.487a1.96 1.96 0 0 0 2.83 0l12.094-12.487c3.52-3.631 3.313-9.644-.612-12.993Z"
      />
    </g>
  </Icon>
);
