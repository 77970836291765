import { GatsbyImage, getImageData, IGatsbyImageData, IGetImageDataArgs, getSrc } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { Kfh_ArtirixImageType, Kfh_ContentImageUmbracoType, Kfh_ContentMediaItemType } from '../types/graphqlTypes';
import { isBrowser } from '../utils/isBrowser';
import NoPhoto from '../images/no-photo.png';

interface ImageTypes
  extends IGatsbyImageData,
    Kfh_ArtirixImageType,
    Kfh_ContentMediaItemType,
    Kfh_ContentImageUmbracoType {
  [x: string]: any;
}

export type CndImageSizes =
  | '160x120'
  | '194x145'
  | '300x220'
  | '370x280'
  | '430x320'
  | '620x340'
  | '870x500'
  | '715x1033'
  | '1600x920'
  | '2200x1400'
  | '1100x700'
  | '598x630'
  | '407x272'
  | '827x552'
  | '1007x672'
  | '1120x748'
  | '1654x1104';

interface ImgProps extends Record<string, any> {
  alt?: string;
  loading?: 'lazy' | 'eager' | undefined;
  src: string | ImageTypes | Record<string, any>; // should be Partial<ImageTypes>
  extension?: string;
  options?: Record<string, any>;
  size?: CndImageSizes;
}

export const getImage = (node) => {
  if (node?.images?.fallback?.src) {
    return node;
  }
  if (node?.gatsbyImage) {
    return node.gatsbyImage;
  }
  return node?.gatsbyImage;
};

// TODO these are the Gatsby Img API
type Fit = 'cover' | 'fill' | 'inside' | 'outside' | 'contain';
type Layout = 'fixed' | 'fullWidth' | 'constrained';

const mapCrops = {
  fullWidth: 'unset',
  constrained: 'crop',
  fixed: 'crop',
};

export function urlBuilder({ baseUrl, width, height, format, options }) {
  const isProd = baseUrl.includes('d2og90x5akoily'); // this is the cloudfront prefix for the root image

  // Different APIs for Prod and Int
  const apiEndpoint = isProd ? 'https://d1dhasr7ob0bv9.cloudfront.net' : 'https://d2majxjayfyf90.cloudfront.net';
  // Buckets where images are stored
  const bucket = isProd ? 'image.prd.kfh.artirix.com' : 'images.int.kfh.artirix.com';

  const fullUrl = new URL(baseUrl);
  // remove slash from start of path if present
  const key = fullUrl.pathname.replace(/^\//, '');

  // TODO Map options.
  //   layout
  // cropFocus
  // quality

  const opts = {
    bucket,
    key,
    edits: {
      format,
      resize: {
        width,
        height,
      },
    },
  } as any; // TODO type this

  if (options.cropFocus) {
    opts.edits.resize.fit = 'cover';
    opts.edits.resize.position = options.cropFocus;
  }

  if (options.quality) {
    opts.edits.quality = options.quality;
  }

  const imageRequest = JSON.stringify(opts);

  const encodedObject = btoa(imageRequest);

  const url = `${apiEndpoint}/${encodedObject}`;
  return url;
}

export const genRuntimeImage = (
  { largestImagePath, filePath, url, width, height, filename, ...props }: any,
  options: any
): IGatsbyImageData | undefined => {
  const imgUrl = largestImagePath || filePath || url;

  // imgs might contain the sourceWidth and sourcHeight e.g: https://d3dh8aiyu254d3.cloudfront.net/images/20240105/1-773417l-1120x748.jpg

  let _;
  let widthString;
  let heightString;
  let sourceHeight;
  let sourceWidth;
  const regex = /-(\d+)x(\d+)\./;
  const matches = regex.exec(imgUrl);

  if (matches) {
    [_, widthString, heightString] = matches;
    sourceWidth = parseInt(widthString, 10);
    sourceHeight = parseInt(heightString, 10);
  }

  const imageData: IGetImageDataArgs = {
    baseUrl: imgUrl,
    sourceWidth: sourceWidth | width,
    sourceHeight: sourceHeight | height,
    urlBuilder,
    pluginName: 'gatsby-source-kfh',
    formats: ['webp', 'avif', 'jpeg'],
    layout: options?.layout || 'constrained',
    backgroundColor: '#F5F5F5',
    options: {
      quality: 80,
      cropFocus: 'center',
      placeholder: 'none',
      backgroundColor: '#F5F5F5',
      ...options,
    },
    ...props,
  };

  if (options?.width) {
    imageData.width = options.width;
  }
  if (options?.height) {
    imageData.height = options.height;
  }

  const gImageData = getImageData(imageData);

  return gImageData;
};

export const getImgSrc = (node: IGatsbyImageData) => getSrc(node);

/**
 * Component for returning either a GatsbyImage or a normal HTML img tag
 *
 * @component
 * @example
 * return (
 *  <Img  src={imageData} />
 * )
 */
export const Img: FC<ImgProps> = ({ src, alt, loading, extension, options, size, ...rest }) => {
  /**
   * We need to handle 4 separate cases in this order of preference:
   * 1. No image
   * 2. Gatsby Image new (gatsbyImage)
   * 3. Gatsby Image legacy (gatsbyImage)
   * 4. Image from Our CDN
   *
   */

  // 1. No image
  if (!src) {
    return (
      <div {...rest}>
        <img src={NoPhoto} alt="" data-test="kfh-img-no-photo" />
      </div>
    );
  }
  // Handle error with image loading
  const handleOnError = (e) => {
    if (e.target.tagName === 'img') {
      e.target.src = isBrowser ? NoPhoto : '';
    } else {
      const imgTag = e.target.querySelector('img');
      if (imgTag) {
        imgTag.src = isBrowser ? NoPhoto : '';
      }
    }
  };

  const isString = typeof src === 'string';

  // 2. Gatsby Image new (gatsbyImage)
  // 3. Gatsby Image legacy (gatsbyImage)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  try {
    let imageData = !isString ? getImage(src.localFile ?? src) : null;

    // 4. Image from Our CDN
    let imageByUrl = '';
    // Grab the corect path for the image
    if (!imageData) {
      if (isString && src.includes('cloudfront.net/images')) {
        // See if string contains info we need to convert to Gatsby Image
        const url = new URL(src);
        const searchParams = new URLSearchParams(url.search);

        if (searchParams.has('width') && searchParams.has('height')) {
          const width = searchParams.get('width');
          const height = searchParams.get('height');

          const newSrc = {
            filePath: src,
            width,
            height,
            filename: url.pathname.split('/').pop(),
          };

          const newOptions = {
            layout: 'fullWidth',
            ...options,
          };

          imageData = genRuntimeImage(newSrc, newOptions);
        } else {
          imageByUrl = src;
        }
      } else if (!isString && src?.largestImagePath) {
        // cloudfront images are property images
        if ((src.largestImagePath as string).includes('cloudfront.net/images') && src.width && src.height) {
          try {
            imageData = genRuntimeImage(src, options);
          } catch (e) {
            console.warn('error', e);
            imageByUrl = src.largestImagePath;
          }
        } else {
          imageByUrl = src.largestImagePath;

          if (size) {
            imageByUrl = imageByUrl.replace(/(?:[0-9]+x[0-9]+).jpg+/g, `${size}.jpg`);
          }
        }
      } else if (!isString && src.url) {
        imageByUrl = src.url;
      } else if (!isString && src.imagePath && extension) {
        imageByUrl = `${src.imagePath}${extension}`;
      } else if (!isString && src.filePath) {
        imageByUrl = src.filePath;
      } else if (!isString && src.imagePath && size) {
        // console.warn(`You need to provide an extension for the image ${src.imagePath}`);
        imageByUrl = `${src.imagePath}-${size}.jpg`;
      } else if (!isString && src.imagePath && !extension && !size) {
        // console.warn(`You need to provide an extension for the image ${src.imagePath}`);
        imageByUrl = `${src.imagePath}-870x500.jpg`;
      } else if (isString) {
        imageByUrl = src;
      }
    }
    // Grab the correct alt text
    let altText = '';
    if (alt) {
      altText = alt;
    } else if (!isString && src?.alternativeText) {
      altText = src.alternativeText;
    } else if (!isString && src?.altText) {
      altText = src.altText;
    }
    // allow size change for CDN images
    if (!imageData && imageByUrl) {
      return (
        <div {...rest}>
          <img
            src={imageByUrl}
            alt={altText}
            loading={loading}
            onError={handleOnError}
            data-test="kfh-img"
            className="cdn-image"
          />
        </div>
      );
    }

    return imageData ? (
      <GatsbyImage image={imageData} alt={altText} data-test="kfh-img" loading={loading} {...rest} />
    ) : null;
  } catch (e) {
    console.error(e);
    return (
      <div {...rest}>
        <img src={NoPhoto} alt="" data-test="kfh-img-no-photo" />
      </div>
    );
  }
};
