import React, { FC, useContext, useState, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { useLogin } from '../../hooks/useAuth';
import { Button } from '../Button';
import { Input } from '../forms/Input';
import { Spacer } from '../Spacer';
import { ResetPasswordBlade } from './ResetPasswordBlade';
import { UiStateContext } from '../../context/UiStateContext';
import { RequestResetPasswordBlade } from './RequestResetPasswordBlade';
import { getQueryString } from '../../utils/queryStrings';
import { ErrorMessage } from '../forms/ErrorMessage';
import { isBrowser } from '../../utils/isBrowser';
import { useMykfh_Account_ActivationMutation } from '../../types/graphqlHelpers';
import { Typography } from '../Typography';
import { useDataLayerPush } from '../../hooks/useDataLayerPush';
import { getResponsiveType } from '../../utils';
import { alertBadEmail, validateEmail } from '../../utils/emailBlackList';

interface FormValues {
  password: string;
  email: string;
}

interface LoginProps {
  disableRedirect?: boolean;
}

const StyledActions = styled.div`
  ${({ theme: { space } }) => css`
    gap: ${space.md};
    display: flex;
    justify-content: space-between;
  `}
`;

const StyledForgottenPasswordButton = styled.button`
  ${({ theme: { space } }) => css`
    padding: 0;
    background: transparent;
    text-decoration: underline;
    ${getResponsiveType('bodySmall')}
  `}
`;

const ToastMessage = () => (
  <div>
    <Typography variant="titleSmall">Account activated</Typography>
    <Typography variant="bodyLarge">Please log in to access myKFH</Typography>
  </div>
);

export const Login: FC<LoginProps> = ({ disableRedirect }) => {
  const [mykfhAccountActivationMutation, { data: activationData, loading: activationLoading, error: activationError }] =
    useMykfh_Account_ActivationMutation();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { register, handleSubmit, formState, watch } = formMethods;
  const { errors, dirtyFields, touchedFields } = formState;
  const { handleLogin, data: loginData } = useLogin({ disableRedirect });
  const { setActiveBlade } = useContext(UiStateContext);
  const [token, setToken] = useState('');
  const { dataLayerPush } = useDataLayerPush();

  useEffect(() => {
    if (isBrowser) {
      const qs = getQueryString(window.location.search);
      const currentUrl = window.location.pathname;
      if (activationData?.myKfh?.myKfhAccountActivation.success) {
        toast.success(<ToastMessage />);
        if (qs?.redirect_url !== currentUrl) {
          setActiveBlade('login');
          navigate(qs?.redirect_url as string, {
            state: { loginBlade: true },
          });
        }
      }
    }
  }, [activationData, setActiveBlade]);

  useEffect(() => {
    if (isBrowser && window.location.search.includes('?token=')) {
      const qs = getQueryString(window.location.search);
      if (qs?.token) {
        // Check if its an activation url
        if (qs?.utm_campaign === 'activationcode') {
          // Activate account
          mykfhAccountActivationMutation({
            variables: {
              myKfhAccountActivation: {
                confirmationToken: qs?.token,
              },
            },
          });
        } else {
          // Must be reset
          setToken(qs.token as string);
          setActiveBlade('myKfhResetPassword');
        }
      }
    }
  }, []);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    // alertBadEmail(formData.email);
    handleLogin({
      email: data.email.trim(),
      password: data.password.trim(),
    });
  };

  const onHandleResetPassword = () => {
    setActiveBlade('myKfhRequestResetPassword');
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} method="post" noValidate>
          <Spacer>
            <Spacer spacing="half">
              <Input
                id="email"
                type="email"
                label="Email"
                {...register('email', {
                  //validate: validateEmail, // email spam check
                  required: {
                    value: true,
                    message: 'Please enter your email address',
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Invalid email address',
                  },
                })}
                invalid={!!errors.email}
                valid={touchedFields.email && dirtyFields.email && !errors.email}
                required
                hideAsterisk
              />
              <Input
                id="password"
                label="Password"
                type="password"
                {...register('password', { required: true })}
                invalid={!!errors.password}
                valid={touchedFields.password && dirtyFields.password && !errors.password}
                required
                hideAsterisk
              />

              {loginData?.myKfh?.mykfhLogin?.errorDescription && (
                <ErrorMessage>{loginData.myKfh.mykfhLogin.errorDescription}</ErrorMessage>
              )}
            </Spacer>
            <StyledActions>
              <Button type="submit" disableClickTracking label="Log in" arrowRight />
              <StyledForgottenPasswordButton type="button" onClick={onHandleResetPassword}>
                Forgotten password?
              </StyledForgottenPasswordButton>
            </StyledActions>
          </Spacer>
        </form>
      </FormProvider>
      <RequestResetPasswordBlade email={watch('email')} />
      <ResetPasswordBlade token={token} />
    </>
  );
};
