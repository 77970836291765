const shadows = {
  default: '0 8px 32px -8px rgba(0,0,0,0.32)',
  small: '2px 4px 16px -4px rgba(17, 17, 17, 0.32)',
  medium: '4px 8px 32px -8px rgba(17,17,17,0.32)',
  mediumTop: '0px -10px 32px -8px rgba(17,17,17,0.32)',
};

export default shadows;

export type ThemeShadows = typeof shadows;
export type ThemeShadowsKeys = keyof typeof shadows;
