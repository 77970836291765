import React, { FC, forwardRef, RefObject } from 'react';

import { BaseFormProps, StyledBase } from './Base';
import { FormField, FormFieldProps } from './FormField';

interface SelectOptions {
  name: string;
  value: string;
}
export interface SelectProps extends BaseFormProps, FormFieldProps {
  options: SelectOptions[];
  defaultValue?: string;
  name?: string;
  noPlaceholder?: boolean;
}

export const Select: FC<React.PropsWithChildren<SelectProps>> = forwardRef(
  (
    {
      label,
      options,
      id,
      tooltipText,
      tooltipInfoIcon,
      valid,
      invalid,
      constrained,
      required,
      placeholder,
      defaultValue,
      children,
      noPlaceholder,
      name,
      ...rest
    },
    ref
  ) => (
    <FormField
      label={label}
      htmlFor={id}
      tooltipText={tooltipText}
      tooltipInfoIcon={tooltipInfoIcon}
      required={required}
      invalid={invalid}
      constrained={constrained}
    >
      <StyledBase
        as="select"
        id={id}
        name={name || id}
        valid={valid}
        invalid={invalid}
        ref={ref as RefObject<HTMLSelectElement>}
        defaultValue={defaultValue}
        {...rest}
      >
        {!children && (
          <>
            {!noPlaceholder && <option value="">{placeholder || 'Select'}</option>}
            {options?.map((option, i) => (
              <option key={i} value={option.value}>
                {option.name}
              </option>
            ))}
          </>
        )}
        {children}
      </StyledBase>
    </FormField>
  )
);
