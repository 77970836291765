import React, { FC, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { hideOnPrint } from '../styles/utils';
import { Button, ButtonProps } from './Button';
import { ButtonRenderer, ButtonRendererProps } from './buttonRenderer/ButtonRenderer';
import { Container } from './Container';
import { Typography } from './Typography';

export type SectionBackgroundColour = 'white' | 'grey' | 'grey1' | 'gradient';

export interface SectionProps {
  background?: SectionBackgroundColour;
  as?: any;
  contained?: boolean;
  narrow?: boolean;
  tight?: boolean;
  heading?: string;
  button?: ButtonRendererProps;
  customButton?: ButtonProps;
  disablePaddingTop?: boolean;
  dontPrint?: boolean;
  overflowHidden?: boolean;
}

type StyledSectionProps = Pick<SectionProps, 'background' | 'tight' | 'disablePaddingTop'>;

const StyledSection = styled.div<StyledSectionProps>`
  ${({
    theme: { space, colors, media, gradients },
    background,
    tight,
    disablePaddingTop,
    dontPrint,
    overflowHidden,
  }) => css`
    padding: ${tight ? `${space.md} 0` : `${space.lg} 0`};
    max-width: 100vw;
    overflow-x: clip;

    // Note we can remove this and all the props using it once safari 16 is more widley adopted as that supports overflow clip
    ${overflowHidden &&
    css`
      overflow-x: hidden;
    `}

    ${disablePaddingTop &&
    css`
      padding-top: 0;
    `}

    // Background color

    ${background === 'white' &&
    css`
      background-color: ${colors.white.default};
    `}

    ${background === 'grey' &&
    css`
      background-color: ${colors.grey2.default};
    `}

      ${background === 'grey1' &&
    css`
      background-color: ${colors.grey2.default};
    `}

    ${background === 'gradient' &&
    css`
      background-image: ${gradients.lightGrey};
    `}

    // Spacing between sibling sections

    &.section--${background} +  .section--${background} {
      padding-top: 0;
    }

    &.section--${background} + div > .section--${background}:first-of-type {
      padding-top: 0;
    }

    &.section--white + .section--gradient {
      padding-top: 0;
    }

    // Hotfix to resolve some spacing. Need to see why classes don't aply when using styled component
    &#section + #section {
      padding-top: 0;
    }
    // Extra hotfix for branch KSP test
    &[id^='section'] + [id^='section']:not(#section2) {
      padding-top: 0;
    }

    &#section2 {
      padding-bottom: 0;
    }

    // Desktop styles

    @media (min-width: ${media.xl}) {
      padding: ${tight ? `${space.lg} 0` : `${space.xl} 0`};

      ${disablePaddingTop &&
      css`
        padding-top: 0;
      `}
    }

    ${dontPrint && hideOnPrint}
  `};
`;

const StyledHeading = styled(Typography)`
  ${({ theme: { space, media } }) => css`
    margin: 0 auto ${space.lg} auto;
    text-align: center;

    // Table > styles

    @media (min-width: ${media.lg}) {
      margin: 0 auto ${space.xl} auto;
    }
  `};
`;

const StyledFooter = styled.div`
  ${({ theme: { space, media } }) => css`
    margin-top: ${space.lg};
    text-align: center;

    // Table > styles

    @media (min-width: ${media.lg}) {
      margin-top: ${space.xl};
    }
  `};
`;

export const Section: FC<React.PropsWithChildren<SectionProps>> = forwardRef(
  (
    {
      children,
      background = 'white',
      contained,
      narrow,
      as,
      tight,
      heading,
      button,
      customButton,
      disablePaddingTop,
      overflowHidden,
      ...rest
    },
    ref
  ) => (
    <StyledSection
      background={background || 'white'}
      as={as}
      className={`section--${background || 'white'}`}
      tight={tight}
      {...rest}
      ref={ref}
      disablePaddingTop={disablePaddingTop}
      overflowHidden={overflowHidden}
    >
      {contained ? (
        <Container narrow={narrow}>
          {heading && (
            <StyledHeading component="h2" variant="displaySmall">
              {heading}
            </StyledHeading>
          )}
          {children}
        </Container>
      ) : (
        <>
          {heading && (
            <StyledHeading component="h2" variant="displaySmall">
              {heading}
            </StyledHeading>
          )}
          {children}
        </>
      )}
      {customButton || button ? (
        <StyledFooter>
          {customButton && <Button {...customButton} />}
          {button && <ButtonRenderer button={button} />}
        </StyledFooter>
      ) : (
        ''
      )}
    </StyledSection>
  )
);
