import React from 'react';
import { Spacer } from '../Spacer';
import { Typography } from '../Typography';
import { LinkTo } from '../LinkTo';
import { WhatsAppButton } from '../WhatsAppButton';

interface ContactInfo {
  line1?: string;
  line2?: string;
  town?: string;
  postcode?: string;
  contactName?: string;
  contactPosition?: string;
  telephone?: string;
  email?: string;
  whatsAppEnabled?: boolean;
  whatsAppFullUrl?: string;
}

interface ContactInformationProps {
  contactInfo?: ContactInfo;
}

const ContactInformation: React.FC<ContactInformationProps> = ({ contactInfo }) => (
  <Spacer>
    <Spacer spacing="half">
      {contactInfo?.line1 || contactInfo?.line2 || contactInfo?.town || contactInfo?.postcode ? (
        <Typography variant="bodyLarge">
          {contactInfo.line1 && (
            <>
              {contactInfo.line1}
              <br />
            </>
          )}
          {contactInfo.line2 && (
            <>
              {contactInfo.line2}
              <br />
            </>
          )}
          {contactInfo.town && (
            <>
              {contactInfo.town}
              <br />
            </>
          )}
          {contactInfo.postcode && <>{contactInfo.postcode}</>}
        </Typography>
      ) : null}

      {contactInfo?.contactName || contactInfo?.contactPosition ? (
        <Typography variant="bodyLarge">
          {contactInfo.contactName && (
            <>
              <strong>{contactInfo.contactName}</strong>
              <br />
            </>
          )}
          {contactInfo.contactPosition && <>{contactInfo.contactPosition}</>}
        </Typography>
      ) : null}

      {contactInfo?.telephone || contactInfo?.email ? (
        <Typography variant="bodyLarge">
          {contactInfo.telephone && (
            <>
              <a href={`tel:${contactInfo.telephone.replace(/\s/g, '')}`}>{contactInfo.telephone}</a>
              <br />
            </>
          )}
          {contactInfo.email && <LinkTo to={`mailto:${contactInfo.email}`}>{contactInfo.email}</LinkTo>}
        </Typography>
      ) : null}
    </Spacer>

    {contactInfo?.whatsAppEnabled && contactInfo.whatsAppFullUrl && (
      <WhatsAppButton url={contactInfo.whatsAppFullUrl} />
    )}
  </Spacer>
);

export default ContactInformation;
