import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Close: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M6.984 5.467a1 1 0 0 1 1.415 0l7.6 7.6 7.602-7.6a1 1 0 0 1 1.32-.084l.095.084 1.767 1.767a1 1 0 0 1 0 1.415l-7.601 7.6 7.601 7.602a1 1 0 0 1 .084 1.32l-.084.095-1.767 1.767a1 1 0 0 1-1.415 0L16 19.432l-7.601 7.601a1 1 0 0 1-1.32.084l-.095-.084-1.767-1.767a1 1 0 0 1 0-1.415l7.6-7.601-7.6-7.601a1 1 0 0 1-.084-1.32l.084-.095Z"
      />
    </g>
  </Icon>
);
