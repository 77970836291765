export const getPageType = (layoutName: string): string | null => {
  switch (layoutName) {
    case 'ContentLondonPropertyMattersMagazineType':
      return 'Magazine Article';
    case 'ContentResourceArticleType':
      return 'Resource';
    case 'ContentNewsArticleType':
      return 'News';
    case 'ContentMarketReportType':
      return 'Market Report';
    case 'ContentCareersNewsType':
      return 'KFH Life';
    case 'ContentPortfolioType':
      return 'Portfolio';
    case 'ContentCaseStudyType':
      return 'Case study';
    case 'ContentServicePageType':
      return 'Service';
    default:
      return null;
  }
};
