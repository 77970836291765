import { createGlobalStyle } from 'styled-components';
import { getBackgroundColor, getResponsiveType } from '../utils';
import { colors, global, space, media, layers } from './tokens';

export const GlobalStyle = createGlobalStyle`

:target {
  scroll-margin-top: 64px; /* Adjust the offset value as needed */
}

    *, *::before, *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: 0;
    }

    html, body {
        height: 100%;
        --toastify-color-success: ${colors.valid.default};
    }

    body {
        -webkit-font-smoothing: antialiased;

        &.is-hidden {
            overflow: hidden;
        }

       

        &.has-overlay:before  {
            content: '';
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            border: none;
            background-color: ${colors.black.default};
            opacity: 0.8;
            z-index: ${layers.bladeBackgroundOverlay};
        }
    }

    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }

    input, button, textarea, select {
        font: inherit;
    }

    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }

    #root, #__next {
        isolation: isolate;
    }

    ul, ol {
        list-style: none;
    }

    a, button {
        color: currentColor; 
    }

    a[href^='tel:'] {
        text-decoration: none;
    }

    address {
        font-style: normal;
    }

    button {
        cursor: pointer;
        background: none;
    }

    .grecaptcha-badge { 
    visibility: hidden;
        }

    // Selection

    ::selection {
        background-color: ${colors.brand.default};
        color: ${colors.brand.contrast};
    }

    // Body

    body {
        ${getResponsiveType('bodyLarge')}
        ${getBackgroundColor('white')}
        min-height: 100vh;
        width: 100%;
        min-width: 330px;
        accent-color: ${colors.brand.default};
    }

    // Gatsby image

    .gatsby-image-wrapper {
        border-radius: ${global.borderRadius.default};
    }

    // Toast

    .Toastify__toast-container {

        @media (min-width: ${media.lg}) {
            width: 528px
        }
    }

    .Toastify__close-button {
        opacity: 1;
    }
    .Toastify__toast-icon {
        width: 48px;
        margin-inline-end: ${space.md};
    }
    .Toastify__toast-body {
        padding: 22px;
    }

`;

export default GlobalStyle;
