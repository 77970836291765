import React, { FC } from 'react';
import { VideoFragment } from '../types/graphqlTypes';
import { Spacer } from './Spacer';
import { Typography } from './Typography';

export const Video: FC<Omit<VideoFragment, '__typename'>> = ({ caption, videoUrl }) => {
  if (!videoUrl) {
    return null;
  }
  React.useEffect(() => {
    if (!document.getElementById('wistia_script')) {
      const wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }
  }, []);

  return (
    <Spacer spacing="half">
      <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <div
          className="wistia_responsive_wrapper"
          style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}
        >
          <div
            className={`wistia_embed wistia_async_${videoUrl} videoFoam=true`}
            style={{ height: '100%', width: '100%' }}
          >
            &nbsp;
          </div>
        </div>
      </div>
      {caption && (
        <Typography component="p" variant="smallprint">
          {caption}
        </Typography>
      )}
    </Spacer>
  );
};
