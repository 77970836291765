import React, { FC, useContext, useRef } from 'react';
import { graphql } from 'gatsby';
import { Kfh_UmbracoButtonLinkFormType } from '../../../types/graphqlTypes';
import { Button } from '../../Button';
import { UiStateContext } from '../../../context/UiStateContext';
import { IconLink } from '../../IconLink';
import { CareersContact } from '../../forms/CareersContact';
import { BlockManagementQuote } from '../../forms/BlockManagementQuote';
import { PropertySolicitorsQuote } from '../../forms/PropertySolicitorsQuote';
import { CorporateRelocationServices } from '../../forms/CorporateRelocationServices';
import { UnsubscribeForm } from '../../forms/UnsubscribeForm';
import { LeaseExtensionQuote } from '../../forms/LeaseExtensionQuote';
import { PropertyManagementReview } from '../../forms/PropertyManagementReviewBlade';
import { LandlordLocator } from '../../forms/LandlordLocator';
import { SurveyingForm } from '../../forms/SurveyingForm'; // Import new form
import { FinancialServicesForm } from '../../forms/FinancialServicesForm'; // Import new form
import { NewHomesForm } from '../../forms/NewHomesForm'; // Import new form
import { BuildToRentForm } from '../../forms/BuildToRentForm'; // Import new form

interface LinkFormProps extends Kfh_UmbracoButtonLinkFormType {
  arrowLink?: boolean;
}

export const LinkForm: FC<LinkFormProps> = ({ arrowLink, label, formType, ...rest }) => {
  const { setActiveBlade, setActiveBladeTriggerEl } = useContext(UiStateContext);
  const formBladeTriggerEl = useRef() as React.MutableRefObject<HTMLButtonElement>;

  const handleOpenFormBlade = () => {
    let activeBlade;

    switch (formType) {
      case 'Careers contact':
        activeBlade = 'careersContact';
        break;
      case 'Property solicitors quote':
        activeBlade = 'propertySolicitorsQuote';
        break;
      case 'Block management quote':
        activeBlade = 'blockManagementQuote';
        break;
      case 'Unsubscribe':
        activeBlade = 'unsubscribe';
        break;
      case 'Corporate/Relocation services':
        activeBlade = 'corporateRelocationServices';
        break;
      case 'Lease extension quote':
        activeBlade = 'leaseExtensionQuote';
        break;
      case 'Property management review':
        activeBlade = 'propertyManagementReview';
        break;
      case 'Landlord Locator':
        activeBlade = 'landlordLocator';
        break;
      case 'Surveying':
        activeBlade = 'surveying';
        break;
      case 'Financial Services':
        activeBlade = 'financialServices';
        break;
      case 'New Homes':
        activeBlade = 'newHomes';
        break;
      case 'Build to Rent':
        activeBlade = 'buildToRent';
        break;
      default:
        activeBlade = '';
    }
    setActiveBlade(activeBlade);
    setActiveBladeTriggerEl(formBladeTriggerEl);
  };

  return (
    <>
      {arrowLink ? (
        <IconLink
          onClick={handleOpenFormBlade}
          ref={formBladeTriggerEl}
          eventClickBladeId={formType}
          {...rest}
          label={label}
          arrowRight
        />
      ) : (
        <Button
          onClick={handleOpenFormBlade}
          ref={formBladeTriggerEl}
          eventClickBladeId={formType}
          {...rest}
          label={label}
          arrowRight
        />
      )}
      {formType === 'Careers contact' && <CareersContact />}
      {formType === 'Block management quote' && <BlockManagementQuote />}
      {formType === 'Property solicitors quote' && <PropertySolicitorsQuote />}
      {formType === 'Unsubscribe' && <UnsubscribeForm />}
      {formType === 'Corporate/Relocation services' && <CorporateRelocationServices />}
      {formType === 'Lease extension quote' && <LeaseExtensionQuote />}
      {formType === 'Property management review' && <PropertyManagementReview />}
      {formType === 'Landlord Locator' && <LandlordLocator />}
      {formType === 'Surveying' && <SurveyingForm />}
      {formType === 'Financial Services' && <FinancialServicesForm />}
      {formType === 'New Homes' && <NewHomesForm />}
      {formType === 'Build to Rent' && <BuildToRentForm />}
    </>
  );
};

export const LinkFormQuery = graphql`
  fragment LinkForm on KFH_UmbracoButtonLinkFormType {
    label
    formType
  }
`;
