import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Floorplan: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M30 0a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h28ZM14 4H4v13h3v4H4v7h10v-3h4v3h10V13h-3V9h3V4H18v5h3v4h-3v8h-7v-4h3V4Z"
      />
    </g>
  </Icon>
);
