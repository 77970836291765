import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Save: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0-2h32v32H0z" />
      <path
        fill="currentColor"
        d="M28.65 2.02C25.037-1.017 19.456-.56 16 2.958 12.544-.562 6.963-1.024 3.35 2.02c-4.7 3.956-4.012 10.406-.662 13.825L13.65 27.014a3.277 3.277 0 0 0 2.35.994 3.26 3.26 0 0 0 2.35-.988l10.962-11.169c3.344-3.418 4.044-9.868-.662-13.83Zm-1.475 11.72L16.212 24.908c-.15.15-.275.15-.425 0L4.825 13.738c-2.281-2.324-2.744-6.724.456-9.418 2.432-2.044 6.181-1.737 8.531.656L16 7.208l2.187-2.232c2.363-2.406 6.113-2.7 8.532-.662 3.193 2.694 2.718 7.119.456 9.425Z"
      />
    </g>
  </Icon>
);
