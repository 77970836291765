import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Rail: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="#C33"
        d="m6.509 6 10.013 4.641H1v3.319h15.658l-8.343 4.014H1v3.319h7.668L18.092 26h7.399l-10.013-4.638H31v-3.315H15.243l.121-.046 7.829-3.969H31v-3.319h-7.668L13.908 6z"
      />
    </g>
  </Icon>
);
