import React, { FC, useContext, useRef } from 'react';
import { graphql } from 'gatsby';
import { Kfh_UmbracoButtonLinkDivisionType } from '../../../types/graphqlTypes';
import { Button } from '../../Button';
import { UiStateContext } from '../../../context/UiStateContext';
import { IconLink } from '../../IconLink';
import { DivisionBlade } from '../../division/DivisionBlade';

interface LinkDivisionProps extends Kfh_UmbracoButtonLinkDivisionType {
  arrowLink?: boolean;
}

export const LinkDivision: FC<LinkDivisionProps> = ({ arrowLink, label, division, ...rest }) => {
  const { setActiveBlade, setActiveBladeTriggerEl } = useContext(UiStateContext);
  const divisionBladeTriggerEl = useRef() as React.MutableRefObject<HTMLButtonElement>;

  const handleOpenDivisionBlade = () => {
    setActiveBlade('division');
    setActiveBladeTriggerEl(divisionBladeTriggerEl);
  };

  return (
    <>
      {arrowLink ? (
        <IconLink
          onClick={handleOpenDivisionBlade}
          ref={divisionBladeTriggerEl}
          eventClickBladeId="division"
          {...rest}
          label={label}
          arrowRight
        />
      ) : (
        <Button
          onClick={handleOpenDivisionBlade}
          ref={divisionBladeTriggerEl}
          eventClickBladeId="division"
          {...rest}
          label={label}
          arrowRight
        />
      )}
      <DivisionBlade division={division} />
    </>
  );
};

export const LinkDivisionQuery = graphql`
  fragment LinkDivision on KFH_UmbracoButtonLinkDivisionType {
    label
    division {
      name
      id
      content
      whatsApp {
        whatsAppEnabled
        whatsAppFullUrl
      }
      divisionAddresses {
        id
        name
        line1
        line2
        town
        postcode
        telephone
        email
        contactName
        contactPosition
      }
    }
  }
`;
