import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const WhatsAppBlack: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M16 5.333c5.891 0 10.667 4.776 10.667 10.667S21.89 26.667 16 26.667c-1.888 0-3.66-.493-5.199-1.353l-5.364 1.2L6.802 21.4A10.617 10.617 0 0 1 5.333 16C5.333 10.109 10.11 5.333 16 5.333Zm4.409 13.892c.187-.52.187-.965.128-1.059-.056-.09-.204-.146-.43-.257-.226-.112-1.331-.65-1.539-.724-.204-.073-.356-.112-.504.112-.151.223-.58.724-.714.87-.13.15-.26.167-.486.055-.226-.111-.951-.345-1.81-1.103-.669-.588-1.12-1.316-1.25-1.538-.13-.223-.014-.345.099-.457.102-.097.226-.257.338-.386.109-.132.148-.223.222-.373.078-.145.038-.278-.018-.39-.056-.111-.492-1.21-.693-1.65-.169-.368-.346-.379-.508-.382-.13-.007-.282-.007-.43-.007a.831.831 0 0 0-.601.278c-.204.223-.785.76-.785 1.856 0 1.093.803 2.151.916 2.297.113.15 1.556 2.468 3.845 3.359 1.897.738 2.285.592 2.7.554.414-.036 1.332-.537 1.52-1.055Z"
    />
  </Icon>
);
