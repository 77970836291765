import React, { FC, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { getButtonStyles } from '../utils';
import { IconLink, IconLinkProps } from './IconLink';
import { ThemeButtonKeys } from '../styles/tokens/buttons';
import { hideOnPrint } from '../styles/utils';

export interface ButtonProps extends IconLinkProps {
  variant?: ThemeButtonKeys;
  selected?: boolean;
  role?: string;
  fullWidth?: boolean;
  count?: number;
  hasMounted?: boolean;
}

interface StyledButtonProps {
  variant?: ThemeButtonKeys;
  selected?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const StyledButton = styled(IconLink)<StyledButtonProps>`
  ${({ variant, selected, disabled, fullWidth }) => css`
    ${variant && getButtonStyles(variant, selected, disabled)};
    position: relative;

    ${fullWidth &&
    css`
      width: 100%;
    `}

    ${hideOnPrint}
  `};
`;

export const StyledCount = styled.div`
  ${({ theme: { space, borders, colors, media } }) => css`
    background: ${colors.white.default};
    color: ${colors.brand.default};
    border-radius: 50%;
    display: block;
    width: ${space.md};
    height: ${space.md};
    font-size: 12px;
    line-height: ${space.md};
    text-align: center;
    position: absolute;
    right: -36px;
    top: -4px;
  `};
`;

export const Button: FC<React.PropsWithChildren<ButtonProps>> = forwardRef(
  (
    {
      children,
      a11yTitle,
      role,
      id,
      className,
      onClick,
      disabled = false,
      variant = 'solid',
      as,
      url,
      target,
      label,
      type,
      hasMounted,
      arrowRight,
      arrowLeft,
      iconLeft,
      iconRight,
      rel,
      selected,
      fullWidth,
      hideLabelOnMobile,
      state,
      count,
      eventClickSection,
      customDataLayerEvent,
      eventClickBladeId,
      disableClickTracking,
      ...rest
    },
    ref
  ) => {
    if (!label && !children) {
      return null;
    }

    const handleClick = (...props) => {
      if (onClick) {
        onClick(...props);
      }
    };

    return (
      <StyledButton
        ref={ref}
        id={id}
        className={className}
        url={url}
        onClick={handleClick}
        aria-label={a11yTitle}
        role={role}
        type={type}
        variant={variant}
        disabled={disabled}
        aria-disabled={disabled}
        target={target && '__blank'}
        {...rest}
        arrowLeft={arrowLeft}
        iconLeft={iconLeft}
        label={label}
        arrowRight={arrowRight}
        iconRight={iconRight}
        disabledDefaultStyling
        arrowLeftBack
        rel={rel}
        selected={selected}
        hideLabelOnMobile={hideLabelOnMobile}
        as={as}
        state={state}
        fullWidth={fullWidth}
        eventName="cta button"
        eventClickSection={eventClickSection}
        customDataLayerEvent={customDataLayerEvent}
        eventClickBladeId={eventClickBladeId}
        disableClickTracking={disableClickTracking}
        hasMounted={hasMounted}
      >
        {children || label}
        {count ? <StyledCount>{count}</StyledCount> : null}
      </StyledButton>
    );
  }
);
