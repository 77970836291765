const space = {
  xxs: '2px',
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '32px',
  xl: '64px',
  xxl: '96px',
  xxxl: '128px',
};

export default space;
export type ThemeSpace = typeof space;
export type ThemeSpaceKeys = keyof typeof space;
