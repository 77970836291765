import React from 'react';
import { ApolloProvider } from '@apollo/client';

import { GatsbyBrowser } from 'gatsby';
import { getApolloClient } from './src/client';
import { updateCallTracking } from './src/utils';

import './src/fonts/font-face.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';

// eslint-disable-next-line react/prop-types
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }, options) => (
  <ApolloProvider client={getApolloClient()}>{element}</ApolloProvider>
);

export { wrapPageElement } from './gatsby-shared';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  updateCallTracking();
};
