import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../Typography';

const StyledErrorMessage = styled(Typography)`
  ${({ theme: { space, colors, global } }) => css`
    padding: ${space.sm} ${space.md};
    color: ${colors.invalid.default};
    background: rgba(216, 24, 58, 0.08);
    border-radius: ${global.borderRadius.default};
  `};
`;

export const ErrorMessage: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <StyledErrorMessage variant="smallprint" component="p">
    {children}
  </StyledErrorMessage>
);
