export const currency = (val: number, code = 'GBP') => {
  if (!val) {
    return '';
  }
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: code,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // regex to remove .00
  const formatted = formatter.format(val).replace(/\D00(?=\D*$)/, '');
  return formatted;
};
