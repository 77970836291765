import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const TickCircle: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0Zm8.172 8.952a.939.939 0 0 0-1.328 0l-8.804 8.804-4.11-4.11a.939.939 0 0 0-1.328 0l-1.327 1.327a.939.939 0 0 0 0 1.328l6.101 6.101a.939.939 0 0 0 1.328 0l10.795-10.795a.939.939 0 0 0 0-1.327Z"
      />
    </g>
  </Icon>
);
