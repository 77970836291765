import React, { createContext, useState } from 'react';

import { Blades } from '../types/blades';

type BladeWidths = 'narrow' | 'wide';
type ActiveBladeTriggerElType = React.MutableRefObject<HTMLButtonElement> | undefined | null;
type ActiveBladeActionType = undefined | (() => void);

interface UiStateContextProps {
  activeBlade: Blades;
  setActiveBlade: React.Dispatch<React.SetStateAction<Blades>>;
  activeBladeTriggerEl: ActiveBladeTriggerElType;
  setActiveBladeTriggerEl: React.Dispatch<React.SetStateAction<ActiveBladeTriggerElType | undefined>>;
  activeBladeAction: ActiveBladeActionType;
  setActiveBladeAction: React.Dispatch<React.SetStateAction<ActiveBladeActionType>>;
  activeBladeActionLabel: string | undefined;
  setActiveBladeActionLabel: React.Dispatch<React.SetStateAction<string | undefined>>;
  activeBladeWidth: BladeWidths | undefined;
  setActiveBladeWidth: React.Dispatch<React.SetStateAction<BladeWidths | undefined>>;
  activeBladeAnimate: boolean | undefined;
  setActiveBladeAnimate: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  heroOutOfView: boolean | undefined;
  setHeroOutOfView: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const UiStateContext = createContext({} as UiStateContextProps);
UiStateContext.displayName = 'UiStateContext';

export const UiStateContextProvider = ({ children }) => {
  const [activeBlade, setActiveBlade] = useState<Blades>(null);
  const [activeBladeTriggerEl, setActiveBladeTriggerEl] = useState<ActiveBladeTriggerElType>(undefined);
  const [activeBladeAction, setActiveBladeAction] = useState<ActiveBladeActionType>(undefined);
  const [activeBladeActionLabel, setActiveBladeActionLabel] = useState<string | undefined>(undefined);
  const [activeBladeWidth, setActiveBladeWidth] = useState<BladeWidths | undefined>(undefined);
  const [activeBladeAnimate, setActiveBladeAnimate] = useState<boolean | undefined>(true);
  const [heroOutOfView, setHeroOutOfView] = useState<boolean | undefined>(true);

  const contextValues: UiStateContextProps = {
    activeBlade,
    setActiveBlade,
    activeBladeTriggerEl,
    setActiveBladeTriggerEl,
    activeBladeAction,
    setActiveBladeAction,
    activeBladeWidth,
    setActiveBladeWidth,
    activeBladeActionLabel,
    setActiveBladeActionLabel,
    activeBladeAnimate,
    setActiveBladeAnimate,
    heroOutOfView,
    setHeroOutOfView,
  };

  return <UiStateContext.Provider value={contextValues}>{children}</UiStateContext.Provider>;
};
