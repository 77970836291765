import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Photos: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill="currentColor"
        d="M32 9v18a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h5.5l.769-2.056A2.996 2.996 0 0 1 12.075 2h7.844c1.25 0 2.369.775 2.806 1.944L23.5 6H29a3 3 0 0 1 3 3Zm-8.5 9c0-4.137-3.363-7.5-7.5-7.5A7.506 7.506 0 0 0 8.5 18c0 4.137 3.363 7.5 7.5 7.5 4.137 0 7.5-3.363 7.5-7.5Zm-2 0c0 3.031-2.469 5.5-5.5 5.5a5.508 5.508 0 0 1-5.5-5.5c0-3.031 2.469-5.5 5.5-5.5s5.5 2.469 5.5 5.5Z"
      />
    </g>
  </Icon>
);
