import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { AUTH_TOKEN } from './hooks/useAuth';
import { isBrowser } from './utils/isBrowser';

let apolloClient;

const liveUrls = [
  'kfh.co.uk',
  'www.kfh.co.uk',
  'kfh.gatsbyjs.io',
  'preview-kfh.gatsbyjs.io',
  'preview-kfh.gtsb.io',
  'kfh.gtsb.io',
];
const getSiteHostname = () => {
  if (!isBrowser) {
    return 'localhost';
  }
  const { hostname } = window.location;
  return hostname;
};

const getApiBaseUrl = () => {
  const hostName = getSiteHostname();
  const baseUrl = process.env.GATSBY_CLIENT_GRAPHQL_URL || 'https://staging-graphql.kfh.co.uk/graphql';

  if (liveUrls.includes(hostName)) {
    return process.env.GATSBY_CLIENT_GRAPHQL_URL_PRODUCTION || 'https://graphql.kfh.co.uk/graphql';
  }
  return baseUrl;
};

export const getApolloClient = () => {
  const clientUrl =
    (process.env.GATSBY_CLOUD || process.env.GATSBY_NETLIFY) &&
    (process.env.GATSBY_BRANCH === 'master' ||
      process.env.GATSBY_BRANCH === 'main' ||
      process.env.GATSBY_BRANCH === 'netlify-migration') &&
    process.env.GATSBY_CLIENT_GRAPHQL_URL_PRODUCTION
      ? process.env.GATSBY_CLIENT_GRAPHQL_URL_PRODUCTION
      : getApiBaseUrl();
  if (!process.env.GATSBY_CLIENT_GRAPHQL_URL && !clientUrl) {
    throw new Error('GATSBY_CLIENT_GRAPHQL_URL or clientUrl is not defined');
  }

  if (apolloClient) {
    return apolloClient;
  }

  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const httpLink = createHttpLink({
    uri: clientUrl || 'https://staging-graphql.kfh.co.uk/graphql',
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const mykfhToken = isBrowser ? localStorage.getItem(AUTH_TOKEN) : '';
    // return the headers to the context so httpLink can read them
    const authorization = process.env.GATSBY_GRAPHQL_CLIENT_TOKEN || '';

    const authTokens = {};

    if (authorization) {
      authTokens['GRAPHQL-ACCESS-TOKEN'] = authorization;
    }

    if (mykfhToken) {
      authTokens['MYKFH-AUTHORIZATION'] = mykfhToken;
    }

    return {
      headers: {
        ...headers,
        ...authTokens,
      },
    };
  });

  apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      // typePolicies: {
      //   myKfh: {
      //     keyFields: [],
      //   },
      // },
      // dataIdFromObject(responseObject, ...rest) {
      //   switch (responseObject.__typename) {
      //     case 'Properties':
      //       console.log({ rest });
      //       console.log({ responseObject });
      //       return `ProductSearch:${responseObject.propertySearch.properties.totalCount}`;
      //     default:
      //       return defaultDataIdFromObject(responseObject);
      //   }
      // },
    }),
    link: ApolloLink.from([authLink, onErrorLink, httpLink]),
  });

  return apolloClient;
};
