import { Link } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useDataLayerPush } from '../hooks/useDataLayerPush';
import { ThemeColorsKeys } from '../styles/tokens/colors';
import { createMarkup } from '../utils';
import { getUrlParts } from '../utils/getUrlParts';
import { CaretRight } from './icon/icons/CaretRight';
import { Img } from './Img';
import { Panel } from './Panel';
import { Tag } from './Tag';
import { Typography } from './Typography';

export interface ImageCardProps {
  heading: string;
  intro?: string;
  url: string;
  actionLabel?: string;
  onClick?: any;
  image?: ImageDataLike;
  tag?: string;
  small?: boolean;
  tagColour?: ThemeColorsKeys;
  vertical?: boolean;
  disableClickTracking?: boolean;
  hideLearnMore?: boolean;
  newTab?: boolean;
  imgOptions?: any;
}

export interface StyledImageCardProps {
  $small?: boolean;
  $vertical?: boolean;
}

const StyledImage = styled(Img)`
  height: 100%;
  width: 100%;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const StyledTag = styled(Tag)`
  align-self: flex-start;
`;

const StyledBody = styled(Panel)`
  ${({ theme: { layers } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: ${layers.imageCardBody};
  `};
`;

const StyledHeader = styled.div`
  ${({ theme: { space } }) => css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: ${space.md};
  `};
`;

const StyledIntro = styled(Typography)`
  ${({ theme: { space } }) => css`
    margin-top: ${space.sm};
  `};
`;

const StyledHeading = styled(Typography)`
  ${({ theme: { space } }) => css`
    max-width: 90%;
  `};
`;

const StyledAction = styled.div`
  ${({ theme: { space, borders, colors, media } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.sm};
    padding-top: ${space.md};
    border-top: ${borders.defaultOpaque};
    color: ${colors.white.default};
    margin-top: ${space.lg};

    @media (min-width: ${media.md}) {
      padding-bottom: ${space.md};
    }
  `};
`;

const StyledImageCard = styled(Link)<StyledImageCardProps>`
  ${({ theme: { space, colors, global, layers, media, shadows }, $small, $vertical }) => css`
    text-decoration: none;
    text-align: left;
    border-radius: ${global.borderRadius.default};
    overflow: hidden;
    position: relative;
    color: ${colors.white.default};
    display: block;
    min-height: ${$small ? '216px' : '408px'};
    max-height: ${$vertical ? '520px' : '408px'};
    isolation: isolate;
    transform: translateZ(0);

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: calc(100% - ${space.xl});
      z-index: ${layers.imageCardOverlay};
      background-image: linear-gradient(0deg, #111111 0%, rgba(17, 17, 17, 0) 100%);
    }

    .gatsby-image-wrapper {
      height: 100%;
      position: absolute;
    }

    // Small

    ${$small &&
    css`
      ${StyledAction} {
        margin-top: ${space.md};

        @media (min-width: ${media.md}) {
          padding-bottom: 0;
        }
      }

      ${StyledBody} {
        padding: ${space.md};
      }
    `}

    // Vertical

     ${$vertical &&
    css`
      min-height: 520px;
    `}

    &:hover {
      box-shadow: ${shadows.small};

      ${StyledAction} {
        text-decoration: underline;
      }
    }
  `};
`;

export const ImageCard: FC<ImageCardProps> = ({
  heading,
  intro,
  actionLabel,
  url,
  onClick,
  image,
  tag,
  tagColour,
  small,
  vertical,
  disableClickTracking,
  hideLearnMore,
  newTab,
  imgOptions,
  ...rest
}) => {
  const { dataLayerPush } = useDataLayerPush();

  const handleClick = (...props) => {
    if (!disableClickTracking) {
      dataLayerPush({
        event: 'image card',
        clickText: heading,
        clickBladeId: undefined,
        ...getUrlParts(url),
      });
    }

    if (onClick) {
      onClick(...props);
    }
  };

  return (
    <StyledImageCard
      to={url}
      target={newTab ? '_blank' : ''}
      onClick={handleClick}
      $small={small}
      $vertical={vertical}
      {...rest}
    >
      {image && <StyledImage src={image} options={imgOptions} />}
      <StyledBody>
        {tag && <StyledTag text={tag} backgroundColor={tagColour || 'brand'} />}
        <StyledHeader>
          {heading && (
            <StyledHeading component="h3" variant="titleMedium">
              {createMarkup(heading)}
            </StyledHeading>
          )}
          {intro && !small && (
            <StyledIntro component="p" variant="bodyLarge">
              {createMarkup(intro)}
            </StyledIntro>
          )}
          {actionLabel && !hideLearnMore ? (
            <StyledAction>
              {actionLabel}
              <CaretRight />
            </StyledAction>
          ) : (
            ''
          )}
        </StyledHeader>
      </StyledBody>
    </StyledImageCard>
  );
};
