import { graphql, useStaticQuery } from 'gatsby';
import { Kfh_KfhSettingsType, Kfh_SocialSettingsType, SiteSiteMetadataFilterInput } from '../types/graphqlTypes';

interface GlobalSettingProps {
  socialSettings: Kfh_SocialSettingsType;
  siteSettings: Kfh_KfhSettingsType;
  siteMeta: SiteSiteMetadataFilterInput;
}

export const useGlobalSettings = (): GlobalSettingProps => {
  const {
    kfhSocialSettings,
    kfhClientSettings,
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query getKfhGlobalSettings {
      site {
        siteMetadata {
          siteUrl
          title
          googleVerification
          pinterestVerification
        }
      }
      kfhSocialSettings: kfhSocialSettingsType {
        facebookUsernameOrId
        facebookServiceUrl
        facebookAppId
        gravatarServiceUrl
        linkedInUsername
        linkedInServiceUrl
        pintrestScreenName
        slideShareName
        twitterScreenName
        twitterServiceUrl
        twitterDataWidgetId
        youTubeUsername
        youTubeServiceUrl
        openGraphEnabled
        openGraphSiteName
        openGraphArticleAuthorEnabled
        openGraphPublisher
      }
      kfhClientSettings: kfhKfhSettingsType {
        companyName
        companyNameLimited
        addressLine1
        addressLine2
        addressLine3
        county
        postcode
        whereRegistered
        companyNumber
        vatRegistrationNumber
        tradingName
        conveyancingQuoteEmail
        commercialTeamName
        commercialPhone
        commercialEmail
        branchBlanketMessage
        branchBlanketMessageStart
        branchBlanketMessageEnd
        branchSalesJobTitle
        branchLettingsJobTitle
        arrangeViewingCTASendBeeApi
        coronavirusInLockdown
        helpToBuyEnabled
        propetyValuationPositionInSearch
        advertEnabled
        advertLocationLat
        advertLocationLong
        advertRadius
        advertBanner
        advertSearch
        propetyValuationPositionInSearch_2
        advertEnabled_2
        advertLocationLat_2
        advertLocationLong_2
        advertRadius_2
        advertBanner_2
        advertSearch_2
        lettingsDisclaimer
        cookiePopupMessage
        branchAutocompleteIdentifier
        defaultCurrencyId
        currencyDisclaimer
        internalCookieStateIps
      }
    }
  `);

  const returnValues: GlobalSettingProps = {
    socialSettings: kfhSocialSettings,
    siteSettings: kfhClientSettings,
    siteMeta: siteMetadata,
  };

  return returnValues;
};
