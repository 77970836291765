import { ThemeBordersKeys } from './borders';
import { ThemeColorsKeys } from './colors';
import { ThemeFontsKeys } from './typography';

const buttons = {
  solid: {
    type: 'bodyLarge',
    border: 'brand',
    background: 'brand',
    color: 'white',
    hover: {
      border: 'brandDark',
      background: 'brandDark',
    },
    disabled: {
      border: 'default',
      background: 'grey3',
    },
  },
  solidReversed: {
    type: 'bodyLarge',
    border: 'white',
    background: 'white',
    color: 'brand',
    hover: {
      border: 'brandDark',
      background: 'brandDark',
      color: 'white',
    },
    disabled: {
      border: 'white',
      background: 'white',
      color: 'grey3',
    },
  },
  link: {
    type: 'bodyLarge',
    border: 'transparent',
    background: 'transparent',
    color: 'black',
    underline: true,
    hover: {
      border: 'transparent',
      background: 'transparent',
      underline: false,
    },
    disabled: {
      color: 'grey3',
    },
  },
  outline: {
    type: 'bodyLarge',
    border: 'brand',
    background: 'transparent',
    color: 'brand',
    hover: {
      border: 'brandDark',
      background: 'brandDark',
      color: 'white',
    },
    disabled: {
      border: 'default',
      background: 'white',
      color: 'grey3',
    },
  },
  outlineReversed: {
    type: 'bodyLarge',
    border: 'white',
    background: 'transparent',
    color: 'white',
    hover: {
      border: 'white',
      background: 'white',
      color: 'brand',
    },
    disabled: {
      border: 'default',
      background: 'white',
      color: 'grey3',
    },
  },
  outlineValid: {
    type: 'bodyLarge',
    border: 'white',
    background: 'transparent',
    color: 'white',
    hover: {
      border: 'white',
      background: 'white',
      color: 'valid',
    },
    disabled: {
      border: 'default',
      background: 'white',
      color: 'grey3',
    },
  },
  pill: {
    type: 'bodySmall',
    border: 'default',
    background: 'white',
    color: 'black',
    hover: {
      border: 'brand',
      background: 'white',
      color: 'brand',
    },
    disabled: {
      border: 'default',
      background: 'white',
      color: 'grey3',
    },
    selected: {
      border: 'brand',
      background: 'brand',
      color: 'white',
    },
  },
};

export default buttons;

interface ThemeButtonBase {
  color: ThemeColorsKeys;
  background: ThemeColorsKeys;
  border: ThemeBordersKeys;
  underline?: boolean;
}
interface ThemeButton extends ThemeButtonBase {
  type: ThemeFontsKeys;
  hover: ThemeButtonBase;
  disabled: ThemeButtonBase;
  selected?: ThemeButtonBase;
}
export type ThemeButtonKeys = keyof typeof buttons;
export type ThemeButtons = Record<ThemeButtonKeys, ThemeButton>;
