import React, { FC } from 'react';
import { Button } from '../Button';
import { Panel } from '../Panel';
import { Spacer } from '../Spacer';
import { Typography } from '../Typography';

interface RecaptchaActionProps {
  handleReCaptchaVerify: any;
  show: boolean;
}

export const RecaptchaAction: FC<RecaptchaActionProps> = ({ handleReCaptchaVerify, show }) => {
  if (!show) {
    return null;
  }

  return (
    <Panel as="fieldset" border>
      <Spacer spacing="half">
        <Typography variant="titleSmall" component="p">
          Spam protection
        </Typography>
        <Button type="button" onClick={handleReCaptchaVerify}>
          Click here
        </Button>
      </Spacer>
    </Panel>
  );
};
